import React, { useEffect } from "react";
import API from "../../utils/api";

import { SearchFilterArea } from "./styled";

let timer;
let delay = 0;
const DELAY_TIME = 800;

const SearchFilter = ({
  setSearchMethod,
  activeDeleteOrRelease,
  pagination,
}) => {
  useEffect(() => {
    delay = 0;
  }, [activeDeleteOrRelease]);

  useEffect(() => {
    if (timer) clearTimeout(timer);

    const queryParams = {};

    if (pagination.skip) queryParams.skip = pagination.skip;
    if (pagination.limit) queryParams.limit = pagination.limit;

    timer = setTimeout(() => {
      setSearchMethod(() => async () => {
        const response = await API.getPapoomClubs();

        const data = response.data.body.data;
        return data;
      });
    }, delay);

    delay = DELAY_TIME;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDeleteOrRelease, pagination.skip]);

  return <SearchFilterArea></SearchFilterArea>;
};

export default SearchFilter;
