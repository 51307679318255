import React from "react";
import { Route } from "react-router-dom";
import AnalyticsTemplate from '../template/analyticsTemplate';
import ExportsPage from '../pages/analytics-pages/exports';
import AnalyticsOrdersPage from '../pages/analytics-pages/orders';
import AnalyticsSellersPage from '../pages/analytics-pages/sellers';
import AnalyticsAnnouncePage from '../pages/analytics-pages/announces';
import FrequencyAnalysisPage from '../pages/analytics-pages/frequencyAnalysis';
import AnalyticsPaymentMethodsPage from '../pages/analytics-pages/paymentMethods';
import AnalyticsWaysOfDeliveringPage from '../pages/analytics-pages/ways-of-delivering';
import AnalyticsCategoryPage from '../pages/analytics-pages/categories';

const AnalyticsRoutes = ({ auth }) => {
    return (
        <>
            <Route exact path="/analytics/exports">
                <AnalyticsTemplate component={ ExportsPage } auth={ auth } />
            </Route>
            <Route exact path="/analytics/orders">
                <AnalyticsTemplate component={ AnalyticsOrdersPage } auth={ auth } />
            </Route>
            <Route exact path="/analytics/sellers">
                <AnalyticsTemplate component={ AnalyticsSellersPage } auth={ auth } />
            </Route>
            <Route exact path="/analytics/announces">
                <AnalyticsTemplate component={ AnalyticsAnnouncePage } auth={ auth } />
            </Route>
            <Route exact path="/analytics/frequeny-analysis">
                <AnalyticsTemplate component={ FrequencyAnalysisPage } auth={ auth } />
            </Route>
            <Route exact path="/analytics/payment-methods">
                <AnalyticsTemplate component={ AnalyticsPaymentMethodsPage } auth={ auth } />
            </Route>
            <Route exact path="/analytics/ways-of-delivering">
                <AnalyticsTemplate component={ AnalyticsWaysOfDeliveringPage } auth={ auth } />
            </Route>
            <Route exact path="/analytics/categories">
                <AnalyticsTemplate component={ AnalyticsCategoryPage } auth={ auth } />
            </Route>

        </>
    );
};

export default AnalyticsRoutes;