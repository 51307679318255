import styled from "styled-components";

export const CardArea = styled.div`
    width: 200px;
    margin: 0 15px 15px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
    flex-wrap: wrap
    border: 1px solid red;
    font-family: 'Mulish', sans-serif;
    font-weight: bold;
    background: white;
    border: 1px solid #DFE0EB;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 10px 32px;
    animation: pulse 5s infinite;

    .card-area {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .label {
        font-size: 19;
        color: #9FA2B4;
        text-align: center;
        margin-bottom: 5px;
    }

    .loader,
    .loader:after {
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
    }

    .loader {
        margin: 15px auto;
        font-size: 10px;
        position: relative;
        text-indent: -9999em;
        border-top: 0.5em solid #ef4937;
        border-right: 0.5em solid #ef4937;
        border-bottom: 0.5em solid #ef4937;
        border-left: 0.5em solid #cecece;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation: load8 1.1s infinite linear;
        animation: load8 1.4s infinite linear;
    }

    @-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @keyframes load8 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }

    100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
`