import React, { useState, useEffect } from "react";
import { HashRouter as Router } from "react-router-dom";
import "./assets/reset.css";
import "./assets/style.css";
import Routes from "./routes";
import API from "./utils/api";
import swal from "sweetalert";
import Loading from "./pages/components/Loading";
import EventBus from "./utils/eventBus";

const App = () => {
  const [token, setToken] = useState(null);
  const [loggedUser, setLoggedUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchAccount = async (token) => {
    if (token && !loggedUser) {
      const response = await API.getAccountByToken(token);

      const account = response.data?.body?.data || null;

      if (account) {
        localStorage.setItem("user", JSON.stringify(account));
        setLoggedUser(account);
      } else {
        // ADDED

        localStorage.clear();
        setToken(null);
        setLoggedUser(null);
      }
    }
    if (loading) setLoading(false);
  };

  const logout = async () => {
    const response = await swal({
      title: "Tem certeza?",
      text: "Tem certeza de que quer fazer o logout?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (!response) return;

    localStorage.clear();

    setToken(null);
    setLoggedUser(null);
  };

  // attach token expiration event
  useEffect(() => {
    EventBus.on("JWT_EXPIRED", () => {
      localStorage.clear();
      setToken(null);
      setLoggedUser(null);
    });

    return () => {
      EventBus.remove("JWT_EXPIRED");
    };
  }, []);

  useEffect(() => {
    if (token) {
      // Call API
      fetchAccount(token);
    } else {
      const savedToken = JSON.parse(window.localStorage.getItem("accessToken"));
      if (savedToken) {
        setToken(savedToken);
      }
      if ((loading && loggedUser) || (loading && !savedToken)) {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  if (loading) {
    return (
      <div style={{ margin: 40 }}>
        <Loading />
      </div>
    );
  }

  return (
    <div className="App">
      <Router>
        <Routes auth={{ token, setToken, loggedUser, loading, logout }} />
      </Router>
    </div>
  );
};

export default App;
