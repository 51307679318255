import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Snackbar from "@material-ui/core/Snackbar";
import { Button, TextField, SnackbarContent } from "@material-ui/core";
import API from "../../../utils/api";
import styled from "styled-components";
import { CloudUploadOutlined, Close } from "@material-ui/icons";
import noImage from "../../../assets/sem-imagem.jpg";
import SearchSelect from "../../../components/SearchSelect";
import { SpinLoadingIcon } from "../../components/SpinLoading";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import swal from "sweetalert";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const getModalStyle = () => {
  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  };
};

const EditBannerModal = ({ open, setOpen, selectedBanner, bannerGridId, setSelectedBanner, fetchData }) => {
  const classes = useStyles();
  const history = useHistory();

  const [modalStyle] = useState(getModalStyle);
  const [destination, setDestination] = useState(null);
  const [responseMessage, setResponseMessage] = useState("");
  const [destinationType, setDestinationType] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selectedImage, setSelectedImage] = useState(null);
  const [fileUpload, setFileUpload] = useState(null);
  const [allStores, setAllStores] = useState(null);
  const [allAnnounces, setAllAnnounces] = useState(null);
  // inputs
  const [externalValue, setExternalValue] = useState("");

  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedStoreId, setSelectedStoreId] = useState(null);
  const [isAnnouncesLoading, setIsAnnouncesLoading] = useState(false);
  const [noProductsMessageError, setNoProductsMessageError] = useState("");
  const [selectedEnvironment, setSelectedEnvironment] = useState(null);
  const [isQClub, setIsQClub] = useState(false);

  const handleClose = () => {
    setDestination("");
    setNoProductsMessageError("");
    setFileUpload(null);
    setSelectedImage(null);
    setSelectedBanner(null);
    setSelectedStore(null);
    setSelectedStoreId(null);
    setOpen(false);
  };

  useEffect(() => {
    fetchDefaultData();
  }, [selectedBanner, allStores]);

  const fetchDefaultData = async () => {
    if (selectedBanner === null) return;

    setSelectedImage(selectedBanner.imageUrl);
    setDestinationType(selectedBanner.destinationType);

    switch (selectedBanner.destinationType) {
      case "external":
        setExternalValue(selectedBanner.destinationURL);
        if(selectedBanner.destinationURL === "qclub") {
          setIsQClub(true)
        }
        break;

      case "store":
        if (allStores) {
          const storeValue = allStores.find((item) => item.value === selectedBanner.destinationURL);
          storeValue
            ? setDestination({
                label: storeValue.label,
                value: selectedBanner.destinationURL,
              })
            : setDestination(null);
        }
        break;

      case "announce":
        if (selectedBanner.bannerId === null) return;
        const announceId = selectedBanner.destinationURL.split("=")[1];

        const storeResponse = await API.getStoreByAnnounceId(announceId);
        const store = storeResponse.data.body.data.categoryId.storeId;

        const announceResponse = await API.getAllAnnouncesByStoreId(store._id);
        const announces = announceResponse.data.body.data.map((announce) => ({
          value: announce.page,
          label: announce.title,
        }));

        setSelectedStoreId(store._id);
        setSelectedStore({ label: store.title, value: "" });
        setAllAnnounces(announces);

        const announceValue = announces.find((item) => item.value === selectedBanner.destinationURL);

        if (!announceValue) {
          setDestination(null);
          return;
        }

        setDestination({
          label: announceValue.label,
          value: selectedBanner.destinationURL,
        });

        break;

      default:
        break;
    }
  };

  useEffect(() => {
    switch (destinationType) {
      case "store":
        getStores();
        break;

      case "announce":
        getStores();
        break;

      default:
        break;
    }
  }, [destinationType]);

  const getStores = async () => {
    const response = await API.getAllStores();
    const storesMapped =
      response.data.body.data.length > 0 &&
      response.data.body.data.map((store) => ({
        value: store.page,
        label: store.title,
      }));
    setAllStores(storesMapped);
  };

  const getAnnouncesByStoreId = async (id) => {
    setIsAnnouncesLoading(true);
    const response = await API.getAllAnnouncesByStoreId(id);
    setIsAnnouncesLoading(false);

    if (response.data.body.data.length < 1) {
      setNoProductsMessageError("Essa loja não possui produtos cadastrados!");
      return;
    }

    const announcesMapped = response.data.body.data.map((announce) => ({
      value: announce.page,
      label: announce.title,
    }));

    setNoProductsMessageError("");
    setAllAnnounces(announcesMapped);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setFileUpload(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const checkButtonIsDisable = () => {
    if(selectedBanner && selectedBanner.destinationType === "external") {
      if(loading || !externalValue && !isQClub) return true
    }
    else {
      if(loading || !destination) return true
    }
    return false
  }

  const onSubmit = async () => {
    setLoading(true);
    try {
      if (selectedBanner.bannerId) {
        let body;
        if (destinationType === "external") {
          body = { destinationURL: isQClub ? 'qclub' : externalValue, };
        } else {
          body = {
            destinationURL: destination.value,
          };
        }

        await API.updateBanner(selectedBanner.bannerId, body);

        if (fileUpload) {
          const data = new FormData();
          data.append("file", fileUpload);
          await API.uploadImageBanner(selectedBanner.bannerId, data);
        }

        await swal({
          title: "Tudo certo!",
          text: "Banner atualizado com sucesso!",
          icon: "success",
        });
      } else {
        if (fileUpload) {
          let body;

          if (destinationType === "external") {
            body = {
              bannerGridId,
              destinationURL: isQClub ? 'qclub' : externalValue,
            };
          } else {
            body = {
              bannerGridId,
              destinationURL: destination.value
            };
          }

          const response = await API.createBanner(body);
          const bannerResponse = response.data.body.data;

          const data = new FormData();
          data.append("file", fileUpload);
          await API.uploadImageBanner(bannerResponse._id, data);

          await swal({
            title: "Tudo certo!",
            text: "Banner criado com sucesso!",
            icon: "success",
          });
        } else {
          console.log("create...", fileUpload);
          setResponseMessage(`Selecione uma imagem e um destino para o banner!`);
          setOpenSnackBar(true);
          setLoading(false);
          return;
        }
      }
    } catch (error) {
      console.log(error)
      setResponseMessage("Ocorreu um erro!");
      setOpenSnackBar(true);
    }

    await fetchData();
    handleClose();
    setLoading(false);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") return;

    setOpenSnackBar(false);
  };

  const onChange = (selectedOption) => {
    setDestination(selectedOption);
  };

  const InputDestinationType = (destinationType) => {
    switch (destinationType) {
      case "store":
        return allStores ? (
          <SearchSelect value={destination} data={allStores} onChange={onChange} placeholder="Escolha uma loja..." />
        ) : (
          <SpinLoadingIcon />
        );

      case "announce":
        return allStores ? (
          <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
            <SearchSelect
              data={allStores}
              value={selectedStore}
              onChange={(value) => {
                const storeId = value.value.split("=")[1];
                setDestination(null);
                setSelectedStore(value);
                setSelectedStoreId(storeId);
                setAllAnnounces(null);
                getAnnouncesByStoreId(storeId);
              }}
              placeholder="Escolha uma loja..."
            />

            {isAnnouncesLoading ? (
              <SpinLoadingIcon />
            ) : (
              allAnnounces &&
              selectedStoreId && (
                <SearchSelect
                  value={destination}
                  data={allAnnounces}
                  onChange={onChange}
                  placeholder="Escolha um produto..."
                />
              )
            )}
          </div>
        ) : (
          <SpinLoadingIcon />
        );

      case "page":
        return <p style={{ color: "black" }}>page</p>;

      case "external":
        return (
          <div >
            <label style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}>
              <input
                type="checkbox"
                checked={isQClub}
                onChange={() => setIsQClub(!isQClub)}
                style={{ marginRight: '5px', marginBottom: 20 }} // Espaçamento entre o checkbox e o texto
              />
              Qclub
            </label>
           
            <TextField
            label="Link externo do banner"
            onChange={(e) => setExternalValue(e.target.value)}
            value={externalValue}
            variant="outlined"
            style={{ width: "100%" }}
            disabled={isQClub}
          />
          </div>
        );

      default:
        break;
    }
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p
          style={{
            color: "black",
            fontSize: 22,
            fontFamily: "sans-serif",
            marginTop: "1rem",
            fontWeight: 600,
          }}
        >
          Editar Banner
        </p>
        <Close style={{ cursor: "pointer" }} onClick={handleClose} />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 40,
          gap: 8,
        }}
      >
        <Button
          onChange={handleImageChange}
          style={{ width: "60%", background:"#35A69B", textTransform: "none", height: '45px' }}
          component="label"
          color="primary"
          variant="contained"
          
        >
          Adicionar imagem
          <VisuallyHiddenInput type="file" />
        </Button>
        {selectedImage ? (
          <img
            src={selectedImage}
            alt="Imagem selecionada"
            style={{ maxWidth: "100%", maxHeight: "300px", marginTop: "20px" }}
          />
        ) : (
          <img
            style={{
              backgroundColor: "#cecece",
              width: "300px",
              height: "180px",
              objectFit: "contain",
            }}
            alt="sem banner selecionado"
            src={noImage}
          ></img>
        )}
      </div>
      <div style={{ width: "100%", marginTop: 40, gap: 8 }}>
        {destinationType && InputDestinationType(destinationType)}
      </div>
      
      <div>
        {noProductsMessageError && (
          <p style={{ color: "red", fontSize: 14, marginTop: 10 }}>{noProductsMessageError}</p>
        )}
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "end",
          gap: 10,
        }}
      >
        <Button
          style={{ marginTop: "2rem", padding: "8px", textTransform: "none", backgroundColor: "white", color: "#757575", border: "1px solid ##757575"}}
          variant="outlined"
          color="secondary"
          onClick={() => handleClose()}
        >
          Cancelar
        </Button>
        <Button
        style={
          checkButtonIsDisable() ? { marginTop: "2rem", padding: "8px", textTransform: "none", backgroundColor: "#8F8F8F", color: "white", border: "none"} :
          { marginTop: "2rem", padding: "8px", textTransform: "none", backgroundColor: "#35A69B", color: "white"}}
        variant="contained"
        color="primary"
        disabled={checkButtonIsDisable()}
        onClick={onSubmit}
      >
        
        {loading ? "Salving..." : "Salvar"}
      </Button>
      </div>
    </div>
  );

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackBar}
        autoHideDuration={2000}
        onClose={handleCloseSnackBar}
      >
        <SnackbarContent style={{ backgroundColor: "purple" }} message={responseMessage} />
      </Snackbar>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
        {body}
      </Modal>
    </div>
  );
};

export default EditBannerModal;
