import React from "react";

import { PageArea } from "./styled";

import Component from "../components/ActiveDeleted";

import ActiveTable from "./ActiveTable";

const Page = () => {
  return (
    <PageArea>
      <div className="page--area">
        <Component
          activeTable={ActiveTable}
          displayTabs={false}
          displayAddButton={false}
          paginate={false}
          route="/banners"
        />
      </div>
    </PageArea>
  );
};

export default Page;
