import React from "react";
import Page from "./page";

const PageTable = ({ data }) => {
  return (
    <div className="table-responsive">
      <Page data={data} />
    </div>
  );
};

export default PageTable;
