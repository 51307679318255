import React from "react";

import { PageArea } from "./styled";

import ActiveDeleted from "../components/ActiveDeleted";

import SearchFilter from "./SearchFilter";
import ActiveTable from "./ActiveTable";
import DeletedTable from "./DeletedTable";

const Page = () => {
  return (
    <PageArea>
      <div className="page--area">
        <ActiveDeleted
          searchFilter={SearchFilter}
          activeTable={ActiveTable}
          deletedTable={DeletedTable}
          route="/type-store"
        />
      </div>
    </PageArea>
  );
};

export default Page;
