import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import Snackbar from "@material-ui/core/Snackbar";
import { Button, SnackbarContent } from "@material-ui/core";

import API from "../../../utils/api";
import swal from "sweetalert";
import { SearchFilterArea } from "../styled";
import { getPositionBySectionName } from "../editSectionModal";

const CloneSectionModal = ({ open, setOpen, bannerGridId, allEnvironments, 
  currentEnvironment, allClassifications, currentClassification, 
  sectionName, fetchData}) => {
  const [selectedEnvironment, setSelectedEnvironment] = useState(null);
  const [selectedClassification, setSelectedClassification] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState(1);
  const [responseMessage, setResponseMessage] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const positionOptions = [
    { value: 1, label: "Topo 1" },
    { value: 2, label: "Topo 2" },
    { value: 3, label: "Topo 3" },
    { value: 4, label: "Meio 1" },
    { value: 5, label: "Meio 2" },
    { value: 6, label: "Meio 3" },
  ]

  const submit = async () => {
    setIsLoading(true)

    const environmentIdDestiny =  selectedEnvironment ?? allEnvironments[0].value
    const classificationIdDestiny = selectedClassification ?? allClassifications[0].value

    if(environmentIdDestiny === currentEnvironment && classificationIdDestiny === currentClassification && selectedPosition === getPositionBySectionName(sectionName) ) {
      setResponseMessage("Você está tentando clonar um banner para a mesma seção");
      setOpenSnackBar(true);
      setIsLoading(false)
      return;
    }

    const checkExistsResponse = await API.checkBannerExists(selectedPosition, environmentIdDestiny, classificationIdDestiny)
    
    if (checkExistsResponse.data.body.data.exists) {
      swal({
        title: "Atenção",
        text: "Já existem banners nessa posição, deseja substituir?",
        icon: "warning",
        buttons: ["Cancelar", "Sim"],
      }).then(async (willClone) => {
        if (willClone) {
          cloneBanner(environmentIdDestiny, classificationIdDestiny, selectedPosition)
        } else {
          setIsLoading(false)
        }
      });
      return;
    }
    cloneBanner(environmentIdDestiny, classificationIdDestiny, selectedPosition)
  }

   const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") return;

    setOpenSnackBar(false);
  };

  const cloneBanner = async (environmentIdDestiny, classificationIdDestiny, positionDestiny) => {
    try {
      const body = {
        bannerGridId,
        environmentIdDestiny,
        classificationIdDestiny,
        positionDestiny: parseInt(positionDestiny)
      }

      const response = await API.cloneBannerGrid(body)
     
      if (response.status != 201) {
        setResponseMessage("Ocorreu um erro!");
        setOpenSnackBar(true);
        setIsLoading(false)
        return;
      }

      setOpen(false);
      setIsLoading(false)
      setSelectedEnvironment(null)
      setSelectedClassification(null)
      setSelectedPosition(1)
      fetchData()

      await swal({
        title: "Tudo certo!",
        text: "Seção clonada com sucesso!",
        icon: "success",
      });
    } catch(e) {
      setResponseMessage("Ocorreu um erro!");
      setOpenSnackBar(true);
      setIsLoading(false)
    }
  }

  useEffect(() => {
    console.log("selectedClassification", selectedClassification)
  }, [selectedClassification])

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackBar}
        autoHideDuration={2000}
        onClose={handleCloseSnackBar}
      >
        <SnackbarContent style={{ backgroundColor: "purple" }} message={responseMessage} />
      </Snackbar>
      <Modal open={open} onClose={() => setOpen(false)} aria-labelledby="modal-title" aria-describedby="modal-description">
        <div
          style={{
            position: "absolute",
            backgroundColor: "white",
            padding: "20px 40px",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: '5px',
          }}
        >
          <p
            style={{
              color: "black",
              fontSize: 22,
              fontFamily: "sans-serif",
              marginTop: "1rem",
              fontWeight: 600,
              marginBottom: 20,
              display: "flex",
              alignSelf: "flex-start"
            }}
          >
            Clonar Seção  {sectionName}
          </p>
          <p>Escolha um ambiente para onde deseja clonar essa seção</p>
          <div style={{ height: 50 }} />

          <div style={{ width: "100%" }}>
            <SearchFilterArea>
              <div className="search-filter">
                <div className="search-filter__select">
                  <select
                    onChange={(e) => {
                      setSelectedEnvironment(e.target.value);
                    }}
                  >
                    {
                      allEnvironments && allEnvironments.map((env, index) => (
                        <option key={index} value={env.value}>{env.label}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
            </SearchFilterArea>
          </div>
          
          <div style={{ width: "100%" }}>
            <SearchFilterArea>
              <div className="search-filter">
                <div className="search-filter__select">
                  <select
                    onChange={(e) => {
                      setSelectedClassification(e.target.value);
                    }}
                  >
                    {
                      allClassifications && allClassifications.map((clasf, index) => (
                      <option key={index} value={clasf.value}>{clasf.label}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
            </SearchFilterArea>
          </div>

          <div style={{ width: "100%" }}>
            <SearchFilterArea>
              <div className="search-filter">
                <div className="search-filter__select">
                  <select
                    onChange={(e) => {
                      setSelectedPosition(e.target.value);
                    }}
                  >
                    {
                      positionOptions.map((env, index) => (
                        <option key={index} value={env.value}>{env.label}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
            </SearchFilterArea>
          </div>

          <div
            style={{
              display: "flex",
            }}
          >
            <Button
          style={{textTransform: "none", backgroundColor: "white", color: "#757575", border: "1px solid ##757575"}}
          variant="outlined"
          color="secondary"
          onClick={() => setOpen(false)}
        >
          Cancelar
        </Button>
            <Button
              onClick={() => submit()}
              style={{ backgroundColor: "#35A69B", color: "white", textTransform: 'none', marginLeft: 20 }}
              component="label"
              color="primary"
              variant="contained"
            >
              {isLoading ? "Clonando..." : "Clonar"}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CloneSectionModal;
