import React from "react";
import { useHistory } from "react-router";
import swal from "sweetalert";
import API from "../../utils/api";
import { formatText } from "../../utils/formatText";
import { formatDateAndTime } from "../../utils/date";

const OnReleaseTable = ({ data, refreshTable }) => {
  const history = useHistory();

  const handleEditAction = (id) => {
    history.push(`environment/edit/${id}`);
  };

  const handleActivate = async (id) => {
    if (await confirmModal("Tem certeza que deseja ativar esse Ambiente?")) {
      const requestBody = {
        status: 2,
      };
      try {
        const response = await API.updateOneEnvironment(id, requestBody);

        if (response.status === 200) {
          await swal({
            title: "Tudo certo!",
            text: "Ambiente em operação!",
            icon: "success",
          });

          refreshTable();
        } else {
          await swal({
            title: "Ocorreu algo de errado!",
            text: "Ocorreu algum erro ao colocar o ambiente em operação",
            icon: "error",
          });
        }
      } catch (error) {
        if (!error.response) {
          console.log(error);
          await swal({
            title: "Algo inesperado aconteceu!",
            text: "Por favor tente novamente mais tarde.",
            icon: "error",
          });
          return;
        }
        console.log(error.response.data.message);
        await swal({
          title: "Ocorreu algo de errado!",
          text: error.response.data.message,
          icon: "error",
        });
      }
    }
    return;
  };

  const handleDelete = async (id) => {
    const answer = await swal({
      title: "Tem certeza?",
      text: "Esta ação irá excluir PERMANENTEMENTE este ambiente. Prosseguir mesmo assim?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (!answer) return;

    try {
      const response = await API.deleteEnvironment(id);

      if (response.status === 200) {
        await swal({
          title: "Tudo certo!",
          text: "Ambiente em operação!",
          icon: "success",
        });

        refreshTable();
      } else {
        await swal({
          title: "Ocorreu algo de errado!",
          text: "Ocorreu algum erro ao colocar o ambiente em operação",
          icon: "error",
        });
      }
    } catch (error) {
      if (!error.response) {
        console.log(error);
        await swal({
          title: "Algo inesperado aconteceu!",
          text: "Por favor tente novamente mais tarde.",
          icon: "error",
        });
        return;
      }
      console.log(error.response.data.message);
      await swal({
        title: "Ocorreu algo de errado!",
        text: error.response.data.message,
        icon: "error",
      });
    }
  };

  const confirmModal = () => {
    const confirm = swal(
      "Tem certeza que deseja colocar esse ambiente em operação?",
      {
        buttons: ["Cancelar", "Confirmar"],
      }
    ).then((value) => (value ? true : false));

    return confirm;
  };

  return (
    <div className="table-responsive">
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Data de Registro</th>
            <th>Descrição</th>
            <th>Resumo</th>
            <th>Cidade</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{formatDateAndTime(item.createdAt)}</td>
                <td>{formatText(item.description, 30)}</td>
                <td>{formatText(item.resume, 30)}</td>
                <td>{item.cityId.city}</td>
                <td>
                  <button
                    className="edit--button"
                    onClick={() => {
                      handleEditAction(item._id);
                    }}
                  >
                    Editar
                  </button>
                  <button
                    className="activate--button"
                    onClick={() => {
                      handleActivate(item._id);
                    }}
                  >
                    Operação
                  </button>
                  <button
                    className="delete--button"
                    onClick={() => {
                      handleDelete(item._id);
                    }}
                  >
                    Excluir
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default OnReleaseTable;
