import React, { useEffect, useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";

import { FormArea } from "./styled";
import Api from "../../utils/api";
import swal from "sweetalert";
import { isEmpty } from "lodash";

import backButton from "../../assets/arrow_back.svg";
import API from "../../utils/api";

import LengthCounter from "../components/LengthCounter";

const Edit = () => {
  const history = useHistory();
  const { action, typeId } = useParams();

  const [status, setStatus] = useState(1);
  const [name, setName] = useState("");
  const [resume, setResume] = useState("");
  const [RequiresSalesTransaction, setRequiresSalesTransaction] =
    useState(true);
  const [
    RequiresRegistrationOfTransaction,
    setRequiresRegistrationOfTransaction,
  ] = useState(true);
  const [
    RequiresRegistrationOfConfiguration,
    setRequiresRegistrationOfConfiguration,
  ] = useState(true);
  const [RequiresRegistrationOfPayment, setRequiresRegistrationOfPayment] =
    useState(true);
  const [RequiresRegistrationOfDelivery, setRequiresRegistrationOfDelivery] =
    useState(true);
  const [
    RequiresRegistrationOfRealization,
    setRequiresRegistrationOfRealization,
  ] = useState(true);
  const [RequiresAppointment, setRequiresAppointment] = useState(true);
  const [RequiresOrder, setRequiresOrder] = useState(true);
  const [RequiresPurchaseForPublication, setRequiresPurchaseForPublication] =
    useState(true);
  const [AllowsPromotionRegistration, setAllowsPromotionRegistration] =
    useState(true);
  const [AllowsChat, setAllowsChat] = useState(true);
  const [AllowsEvaluation, setAllowsEvaluation] = useState(true);
  const [AllowsSingleAdvertisement, setAllowsSingleAdvertisement] =
    useState(true);
  const [AllowsChangeOfEnvironment, setAllowsChangeOfEnvironment] =
    useState(true);
  const [limitImages, setLimitImages] = useState("");
  const [limitVideos, setLimitVideos] = useState("");
  const [limitComplementaryFeatures, setLimitComplementaryFeatures] =
    useState("");
  const [limitEnvironmentLinked, setLimitEnvironmentLinked] = useState("");
  const [limitPostByDay, setLimitPostByDay] = useState("");
  const [limitStoriesByDay, setLimitStoriesByDay] = useState("");
  const [limitNormalPromotionsbyMonth, setLimitNormalPromotionsbyMonth] =
    useState("");
  const [limitLightningPromotionbyMonth, setLimitLightningPromotionbyMonth] =
    useState("");
  const [limitlinkedAnnouncesAccount, setLimitlinkedAnnouncesAccount] =
    useState("");
  const [limitAnnouncebyAnnounceType, setLimitAnnouncebyAnnounceType] =
    useState("");
  const [limitAnnounceLoose, setLimitAnnounceLoose] = useState("");
  const [errors, setErrors] = useState({
    name: "",
    status: "",
    resume: "",
    RequiresSalesTransaction: "",
    RequiresRegistrationOfTransaction: "",
    RequiresRegistrationOfConfiguration: "",
    RequiresRegistrationOfPayment: "",
    RequiresRegistrationOfDelivery: "",
    RequiresRegistrationOfRealization: "",

    RequiresAppointment: "",
    RequiresOrder: "",
    RequiresPurchaseForPublication: "",
    AllowsChat: "",
    AllowsEvaluation: "",
    AllowsSingleAdvertisement: "",
    AllowsChangeOfEnvironment: "",
    AllowsPromotionRegistration: "",

    limitImages: "",
    limitVideos: "",
    limitComplementaryFeatures: "",
    limitEnvironmentLinked: "",
    limitPostByDay: "",
    limitStoriesByDay: "",
    limitNormalPromotionsbyMonth: "",

    limitLightningPromotionbyMonth: "",
    limitlinkedAnnouncesAccount: "",
    limitAnnouncebyAnnounceType: "",
    limitAnnounceLoose: "",
  });

  useEffect(() => {
    setErrors({ ...errors, name: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  useEffect(() => {
    setErrors({ ...errors, status: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    setErrors({ ...errors, resume: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resume]);

  useEffect(() => {
    setErrors({ ...errors, RequiresSalesTransaction: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [RequiresSalesTransaction]);

  useEffect(() => {
    setErrors({ ...errors, RequiresRegistrationOfTransaction: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [RequiresRegistrationOfTransaction]);

  useEffect(() => {
    setErrors({ ...errors, RequiresRegistrationOfConfiguration: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [RequiresRegistrationOfConfiguration]);

  useEffect(() => {
    setErrors({ ...errors, RequiresRegistrationOfPayment: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [RequiresRegistrationOfPayment]);

  useEffect(() => {
    setErrors({ ...errors, RequiresRegistrationOfDelivery: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [RequiresRegistrationOfDelivery]);

  useEffect(() => {
    setErrors({ ...errors, RequiresRegistrationOfRealization: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [RequiresRegistrationOfRealization]);

  useEffect(() => {
    setErrors({ ...errors, RequiresAppointment: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [RequiresAppointment]);

  useEffect(() => {
    setErrors({ ...errors, RequiresOrder: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [RequiresOrder]);

  useEffect(() => {
    setErrors({ ...errors, RequiresPurchaseForPublication: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [RequiresPurchaseForPublication]);

  useEffect(() => {
    setErrors({ ...errors, AllowsChat: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AllowsChat]);

  useEffect(() => {
    setErrors({ ...errors, AllowsEvaluation: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AllowsEvaluation]);

  useEffect(() => {
    setErrors({ ...errors, AllowsSingleAdvertisement: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AllowsSingleAdvertisement]);

  useEffect(() => {
    setErrors({ ...errors, AllowsChangeOfEnvironment: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AllowsChangeOfEnvironment]);

  useEffect(() => {
    setErrors({ ...errors, AllowsPromotionRegistration: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AllowsPromotionRegistration]);

  useEffect(() => {
    setErrors({ ...errors, limitImages: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitImages]);

  useEffect(() => {
    setErrors({ ...errors, limitVideos: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitVideos]);

  useEffect(() => {
    setErrors({ ...errors, limitComplementaryFeatures: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitComplementaryFeatures]);

  useEffect(() => {
    setErrors({ ...errors, limitEnvironmentLinked: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitEnvironmentLinked]);

  useEffect(() => {
    setErrors({ ...errors, limitPostByDay: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitPostByDay]);

  useEffect(() => {
    setErrors({ ...errors, limitStoriesByDay: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitStoriesByDay]);

  useEffect(() => {
    setErrors({ ...errors, limitNormalPromotionsbyMonth: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitNormalPromotionsbyMonth]);

  useEffect(() => {
    setErrors({ ...errors, limitLightningPromotionbyMonth: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitLightningPromotionbyMonth]);

  useEffect(() => {
    setErrors({ ...errors, limitlinkedAnnouncesAccount: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitlinkedAnnouncesAccount]);

  useEffect(() => {
    setErrors({ ...errors, limitAnnouncebyAnnounceType: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitAnnouncebyAnnounceType]);

  useEffect(() => {
    setErrors({ ...errors, limitAnnounceLoose: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitAnnounceLoose]);

  useEffect(() => {
    const availableActions = ["edit", "create"];

    if (
      !availableActions.includes(action) ||
      (action === "edit" && !typeId) ||
      (action === "create" && typeId)
    ) {
      history.push("/announce-type");
      return null;
    }

    if (action === "edit") {
      getTypeId(typeId);
    }

    //getParentOptions();
  }, [action, typeId, history]);

  const getTypeId = async (id) => {
    try {
      const response = await Api.getAdTypeById(id);

      if (response.status === 200) {
        const {
          name,
          status,
          resume,
          RequiresSalesTransaction,
          RequiresRegistrationOfTransaction,
          RequiresRegistrationOfConfiguration,
          RequiresRegistrationOfPayment,
          RequiresRegistrationOfDelivery,
          RequiresRegistrationOfRealization,
          RequiresAppointment,
          RequiresOrder,
          RequiresPurchaseForPublication,
          AllowsPromotionRegistration,
          AllowsChat,
          AllowsEvaluation,
          AllowsSingleAdvertisement,
          AllowsChangeOfEnvironment,

          limitImages,
          limitVideos,
          limitComplementaryFeatures,
          limitEnvironmentLinked,
          limitPostByDay,
          limitStoriesByDay,
          limitNormalPromotionsbyMonth,

          limitLightningPromotionbyMonth,
          limitlinkedAnnouncesAccount,
          limitAnnouncebyAnnounceType,
          limitAnnounceLoose,
        } = response.data.body.data;

        setName(name);
        setStatus(status);
        setResume(resume);

        /* eslint-disable no-unused-expressions */
        setRequiresRegistrationOfTransaction(RequiresRegistrationOfTransaction);
        setRequiresSalesTransaction(RequiresSalesTransaction);
        setRequiresRegistrationOfConfiguration(
          RequiresRegistrationOfConfiguration
        );
        setRequiresRegistrationOfPayment(RequiresRegistrationOfPayment);
        setRequiresRegistrationOfDelivery(RequiresRegistrationOfDelivery);
        setRequiresRegistrationOfRealization(RequiresRegistrationOfRealization);
        setRequiresAppointment(RequiresAppointment);
        setRequiresOrder(RequiresOrder);
        setRequiresPurchaseForPublication(RequiresPurchaseForPublication);
        setAllowsChat(AllowsChat);
        setAllowsEvaluation(AllowsEvaluation);
        setAllowsSingleAdvertisement(AllowsSingleAdvertisement);
        setAllowsChangeOfEnvironment(AllowsChangeOfEnvironment);
        setAllowsPromotionRegistration(AllowsPromotionRegistration);

        setLimitImages(limitImages);
        setLimitVideos(limitVideos);
        setLimitComplementaryFeatures(limitComplementaryFeatures);
        setLimitEnvironmentLinked(limitEnvironmentLinked);
        setLimitPostByDay(limitPostByDay);
        setLimitStoriesByDay(limitStoriesByDay);
        setLimitNormalPromotionsbyMonth(limitNormalPromotionsbyMonth);
        setLimitLightningPromotionbyMonth(limitLightningPromotionbyMonth);
        setLimitlinkedAnnouncesAccount(limitlinkedAnnouncesAccount);
        setLimitAnnouncebyAnnounceType(limitAnnouncebyAnnounceType);
        setLimitAnnounceLoose(limitAnnounceLoose);
      }
    } catch (error) {
      await swal({
        title: "Algo inesperado aconteceu!",
        text: "Por favor tente novamente mais tarde.",
        icon: "error",
      });
    }
  };

  const validateForm = () => {
    const err = {};

    if (name < 3) err.name = "O campo deve possuir pelo menos 3 dígitos";

    if (!isEmpty(err)) {
      setErrors({ ...errors, ...err });
      return false;
    }

    setErrors({
      name: "",
      status: "",
      resume: "",

      RequiresSalesTransaction: "",
      RequiresRegistrationOfTransaction: "",
      RequiresRegistrationOfConfiguration: "",
      RequiresRegistrationOfPayment: "",
      RequiresRegistrationOfDelivery: "",
      RequiresRegistrationOfRealization: "",
      RequiresAppointment: "",
      RequiresOrder: "",
      RequiresPurchaseForPublication: "",
      AllowsChat: "",
      AllowsEvaluation: "",
      AllowsSingleAdvertisement: "",
      AllowsChangeOfEnvironment: "",
      AllowsPromotionRegistration: "",

      limitImages: "",
      limitVideos: "",
      limitComplementaryFeatures: "",
      limitEnvironmentLinked: "",
      limitPostByDay: "",
      limitStoriesByDay: "",
      limitNormalPromotionsbyMonth: "",
      limitLightningPromotionbyMonth: "",
      limitlinkedAnnouncesAccount: "",
      limitAnnouncebyAnnounceType: "",
      limitAnnounceLoose: "",
    });
    return true;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const requestBody = {
      status: Number.parseInt(status),
      name,
      resume,

      RequiresSalesTransaction,
      RequiresRegistrationOfTransaction,
      RequiresRegistrationOfConfiguration,
      RequiresRegistrationOfPayment,
      RequiresRegistrationOfDelivery,
      RequiresRegistrationOfRealization,
      RequiresAppointment,
      RequiresOrder,
      RequiresPurchaseForPublication,
      AllowsChat,
      AllowsEvaluation,
      AllowsSingleAdvertisement,
      AllowsChangeOfEnvironment,
      AllowsPromotionRegistration,

      limitImages: Number.parseInt(limitImages),
      limitVideos: Number.parseInt(limitVideos),
      limitComplementaryFeatures: Number.parseInt(limitComplementaryFeatures),
      limitEnvironmentLinked: Number.parseInt(limitEnvironmentLinked),
      limitPostByDay: Number.parseInt(limitPostByDay),
      limitStoriesByDay: Number.parseInt(limitStoriesByDay),
      limitNormalPromotionsbyMonth: Number.parseInt(
        limitNormalPromotionsbyMonth
      ),
      limitLightningPromotionbyMonth: Number.parseInt(
        limitLightningPromotionbyMonth
      ),
      limitlinkedAnnouncesAccount: Number.parseInt(limitlinkedAnnouncesAccount),
      limitAnnouncebyAnnounceType: Number.parseInt(limitAnnouncebyAnnounceType),
      limitAnnounceLoose: Number.parseInt(limitAnnounceLoose),
    };

    try {
      if (action === "edit") {
        await API.updateAdType(typeId, requestBody);
        await swal({
          title: "Tudo certo!",
          text: "Tipo de Anúncio foi atualizado com sucesso!",
          icon: "success",
        });
      } else {
        await Api.createAdType(requestBody);
        await swal({
          title: "Tudo certo!",
          text: "Tipo de Anúncio foi criado com sucesso!",
          icon: "success",
        });
      }

      history.push("/announce-type");
    } catch (e) {
      if ((e.response.status = 406)) {
        await swal({
          title: "Tipo de Anúncio já registrado na plataforma!",
          text: "Por favor, tente novamente com outra descrição.",
          icon: "error",
        });
      } else {
        await swal({
          title: "Algo inesperado aconteceu!",
          text: "Por favor tente novamente mais tarde.",
          icon: "error",
        });
      }
    }
  };

  return (
    <FormArea>
      <div className="form--area">
        <div className="form--header">
          <Link to="/announce-type">
            <img src={backButton} alt="Voltar" />
          </Link>

          <h1>
            {action === "edit"
              ? "Editar Tipo de Anúncio"
              : "Adicionar Tipo de Anúncio"}
          </h1>
        </div>
        <form onSubmit={handleFormSubmit}>
          {/* <label htmlFor="type">Status:</label>
          <select
            id="type"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value="1">Ativo</option>
            <option value="0">Inativo</option>
          </select>
          {errors.status !== "" && (
            <div className="form--error">{errors.status}</div>
          )} */}

          <label htmlFor="description">Descrição:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            required={true}
            maxLength="50"
          />
          <LengthCounter
            props={{
              field: name,
              maxLength: 50,
            }}
          />
          {errors.name !== "" && (
            <div className="form--error">{errors.name}</div>
          )}

          <label htmlFor="resumo">Resumo:</label>
          <textarea
            id="resumo"
            value={resume}
            onChange={(e) => {
              setResume(e.target.value);
            }}
            required={true}
            maxLength="300"
          />
          <LengthCounter
            props={{
              field: resume,
              maxLength: 300,
            }}
          />
          <label htmlFor="type">Requer transação de venda:</label>
          <select
            id="type"
            value={RequiresSalesTransaction}
            onChange={(e) =>
              setRequiresSalesTransaction(e.target.value === "true")
            }
          >
            <option value="true">Sim</option>
            <option value="false">Não</option>
          </select>
          {errors.RequiresSalesTransaction !== "" && (
            <div className="form--error">{errors.RequiresSalesTransaction}</div>
          )}

          <label htmlFor="type">
            Requer registro de adicionais no momento da transação:
          </label>
          <select
            id="type"
            value={RequiresRegistrationOfTransaction}
            onChange={(e) =>
              setRequiresRegistrationOfTransaction(e.target.value === "true")
            }
          >
            <option value="true">Sim</option>
            <option value="false">Não</option>
          </select>
          {errors.RequiresRegistrationOfTransaction !== "" && (
            <div className="form--error">
              {errors.RequiresRegistrationOfTransaction}
            </div>
          )}

          <label htmlFor="type">
            Requer registro de configurações especiais no momento da transação:
          </label>
          <select
            id="type"
            value={RequiresRegistrationOfConfiguration}
            onChange={(e) =>
              setRequiresRegistrationOfConfiguration(e.target.value === "true")
            }
          >
            <option value="true">Sim</option>
            <option value="false">Não</option>
          </select>
          {errors.RequiresRegistrationOfConfiguration !== "" && (
            <div className="form--error">
              {errors.RequiresRegistrationOfConfiguration}
            </div>
          )}

          <label htmlFor="type">Requer registro de forma de pagamento:</label>
          <select
            id="type"
            value={RequiresRegistrationOfPayment}
            onChange={(e) =>
              setRequiresRegistrationOfPayment(e.target.value === "true")
            }
          >
            <option value="true">Sim</option>
            <option value="false">Não</option>
          </select>
          {errors.RequiresRegistrationOfPayment !== "" && (
            <div className="form--error">
              {errors.RequiresRegistrationOfPayment}
            </div>
          )}

          <label htmlFor="type">Requer registro de entrega:</label>
          <select
            id="type"
            value={RequiresRegistrationOfDelivery}
            onChange={(e) =>
              setRequiresRegistrationOfDelivery(e.target.value === "true")
            }
          >
            <option value="true">Sim</option>
            <option value="false">Não</option>
          </select>
          {errors.RequiresRegistrationOfDelivery !== "" && (
            <div className="form--error">
              {errors.RequiresRegistrationOfDelivery}
            </div>
          )}

          <label htmlFor="type">Requer registro e forma de realização:</label>
          <select
            id="type"
            value={RequiresRegistrationOfRealization}
            onChange={(e) =>
              setRequiresRegistrationOfRealization(e.target.value === "true")
            }
          >
            <option value="true">Sim</option>
            <option value="false">Não</option>
          </select>
          {errors.RequiresRegistrationOfRealization !== "" && (
            <div className="form--error">
              {errors.RequiresRegistrationOfRealization}
            </div>
          )}

          <label htmlFor="type">Requer agendamento:</label>
          <select
            id="type"
            value={RequiresAppointment}
            onChange={(e) => setRequiresAppointment(e.target.value === "true")}
          >
            <option value="true">Sim</option>
            <option value="false">Não</option>
          </select>
          {errors.RequiresAppointment !== "" && (
            <div className="form--error">{errors.RequiresAppointment}</div>
          )}

          <label htmlFor="type">Requer encomenda:</label>
          <select
            id="type"
            value={RequiresOrder}
            onChange={(e) => setRequiresOrder(e.target.value === "true")}
          >
            <option value="true">Sim</option>
            <option value="false">Não</option>
          </select>
          {errors.RequiresOrder !== "" && (
            <div className="form--error">{errors.RequiresOrder}</div>
          )}

          <label htmlFor="type">
            Requer compra na plataforma para publicação:
          </label>
          <select
            id="type"
            value={RequiresPurchaseForPublication}
            onChange={(e) =>
              setRequiresPurchaseForPublication(e.target.value === "true")
            }
          >
            <option value="true">Sim</option>
            <option value="false">Não</option>
          </select>
          {errors.RequiresPurchaseForPublication !== "" && (
            <div className="form--error">
              {errors.RequiresPurchaseForPublication}
            </div>
          )}

          <label htmlFor="type">Pemite registro de promoção:</label>
          <select
            id="type"
            value={AllowsPromotionRegistration}
            onChange={(e) =>
              setAllowsPromotionRegistration(e.target.value === "true")
            }
          >
            <option value="true">Sim</option>
            <option value="false">Não</option>
          </select>
          {errors.AllowsPromotionRegistration !== "" && (
            <div className="form--error">
              {errors.AllowsPromotionRegistration}
            </div>
          )}

          <label htmlFor="type">
            Permite chat de comunicação entre vendedor e consumidor:
          </label>
          <select
            id="type"
            value={AllowsChat}
            onChange={(e) => setAllowsChat(e.target.value === "true")}
          >
            <option value="true">Sim</option>
            <option value="false">Não</option>
          </select>
          {errors.AllowsChat !== "" && (
            <div className="form--error">{errors.AllowsChat}</div>
          )}

          <label htmlFor="type">Permite avaliação:</label>
          <select
            id="type"
            value={AllowsEvaluation}
            onChange={(e) => setAllowsEvaluation(e.target.value === "true")}
          >
            <option value="true">Sim</option>
            <option value="false">Não</option>
          </select>
          {errors.AllowsEvaluation !== "" && (
            <div className="form--error">{errors.AllowsEvaluation}</div>
          )}

          <label htmlFor="type">Permite anúncio avulso:</label>
          <select
            id="type"
            value={AllowsSingleAdvertisement}
            onChange={(e) =>
              setAllowsSingleAdvertisement(e.target.value === "true")
            }
          >
            <option value="true">Sim</option>
            <option value="false">Não</option>
          </select>
          {errors.AllowsSingleAdvertisement !== "" && (
            <div className="form--error">
              {errors.AllowsSingleAdvertisement}
            </div>
          )}

          <label htmlFor="type">Permite mudança de ambiente:</label>
          <select
            id="type"
            value={AllowsChangeOfEnvironment}
            onChange={(e) =>
              setAllowsChangeOfEnvironment(e.target.value === "true")
            }
          >
            <option value="true">Sim</option>
            <option value="false">Não</option>
          </select>
          {errors.AllowsChangeOfEnvironment !== "" && (
            <div className="form--error">
              {errors.AllowsChangeOfEnvironment}
            </div>
          )}

          <label htmlFor="number">Limite de números de imagens:</label>
          <input
            type="number"
            id="limite"
            value={limitImages}
            onChange={(e) => {
              setLimitImages(e.target.value);
            }}
            required={true}
            maxLength="6"
          />
          {errors.limitImages !== "" && (
            <div className="form--error">{errors.limitImages}</div>
          )}

          <label htmlFor="number">Limite de número de vídeos:</label>
          <input
            type="number"
            id="limite"
            value={limitVideos}
            onChange={(e) => {
              setLimitVideos(e.target.value);
            }}
            required={true}
            maxLength="6"
          />
          {errors.limitVideos !== "" && (
            <div className="form--error">{errors.limitVideos}</div>
          )}

          <label htmlFor="number">
            Limite de número de características complementares:
          </label>
          <input
            type="number"
            id="limite"
            value={limitComplementaryFeatures}
            onChange={(e) => {
              setLimitComplementaryFeatures(e.target.value);
            }}
            required={true}
            maxLength="6"
          />
          {errors.limitComplementaryFeatures !== "" && (
            <div className="form--error">
              {errors.limitComplementaryFeatures}
            </div>
          )}

          <label htmlFor="number">
            Limite de número de ambientes vinculados:
          </label>
          <input
            type="number"
            id="limite"
            value={limitEnvironmentLinked}
            onChange={(e) => {
              setLimitEnvironmentLinked(e.target.value);
            }}
            required={true}
            maxLength="6"
          />
          {errors.limitEnvironmentLinked !== "" && (
            <div className="form--error">{errors.limitEnvironmentLinked}</div>
          )}

          <label htmlFor="number">Limite de número de posts por dia:</label>
          <input
            type="number"
            id="limite"
            value={limitPostByDay}
            onChange={(e) => {
              setLimitPostByDay(e.target.value);
            }}
            required={true}
            maxLength="6"
          />
          {errors.limitPostByDay !== "" && (
            <div className="form--error">{errors.limitPostByDay}</div>
          )}

          <label htmlFor="number">Limite de número de stories por dia:</label>
          <input
            type="number"
            id="limite"
            value={limitStoriesByDay}
            onChange={(e) => {
              setLimitStoriesByDay(e.target.value);
            }}
            required={true}
            maxLength="6"
          />
          {errors.limitStoriesByDay !== "" && (
            <div className="form--error">{errors.limitStoriesByDay}</div>
          )}

          <label htmlFor="number">
            Limite de número de promoçoẽs normais por mês:
          </label>
          <input
            type="number"
            id="limite"
            value={limitNormalPromotionsbyMonth}
            onChange={(e) => {
              setLimitNormalPromotionsbyMonth(e.target.value);
            }}
            required={true}
            maxLength="6"
          />
          {errors.limitNormalPromotionsbyMonth !== "" && (
            <div className="form--error">
              {errors.limitNormalPromotionsbyMonth}
            </div>
          )}

          <label htmlFor="number">
            Limite de número de promoçoẽs relampago por mês:
          </label>
          <input
            type="number"
            id="limite"
            value={limitLightningPromotionbyMonth}
            onChange={(e) => {
              setLimitLightningPromotionbyMonth(e.target.value);
            }}
            required={true}
            maxLength="6"
          />
          {errors.limitLightningPromotionbyMonth !== "" && (
            <div className="form--error">
              {errors.limitLightningPromotionbyMonth}
            </div>
          )}

          <label htmlFor="number">
            Limite de número de vinculo com outros anúncios da conta:
          </label>
          <input
            type="number"
            id="limite"
            value={limitlinkedAnnouncesAccount}
            onChange={(e) => {
              setLimitlinkedAnnouncesAccount(e.target.value);
            }}
            required={true}
            maxLength="6"
          />
          {errors.limitlinkedAnnouncesAccount !== "" && (
            <div className="form--error">
              {errors.limitlinkedAnnouncesAccount}
            </div>
          )}

          <label htmlFor="number">
            Limite de número de anúncios por Tipo de Anúncio:
          </label>
          <input
            type="number"
            id="limite"
            value={limitAnnouncebyAnnounceType}
            onChange={(e) => {
              setLimitAnnouncebyAnnounceType(e.target.value);
            }}
            required={true}
            maxLength="6"
          />
          {errors.limitAnnouncebyAnnounceType !== "" && (
            <div className="form--error">
              {errors.limitAnnouncebyAnnounceType}
            </div>
          )}

          <label htmlFor="number">
            Limite de número de anúncios avulsos (Sem Loja Vínculada):
          </label>
          <input
            type="number"
            id="limite"
            value={limitAnnounceLoose}
            onChange={(e) => {
              setLimitAnnounceLoose(e.target.value);
            }}
            required={true}
            maxLength="6"
          />
          {errors.limitAnnounceLoose !== "" && (
            <div className="form--error">{errors.limitAnnounceLoose}</div>
          )}

          <button type="submit">Salvar</button>
        </form>
      </div>
    </FormArea>
  );
};
export default Edit;
