import styled from "styled-components";

export const CardArea = styled.div`
    width: 25% - 45px;
    margin: 0 15px 15px 0;

    .card-area {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        align-items: center;
        flex-wrap: wrap
        height: 180px;
        border: 1px solid red;
        font-family: 'Mulish', sans-serif;
        font-weight: bold;
        background: white;
        border: 1px solid #DFE0EB;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 22px 32px;
    }
    .label {
        font-size: 19;
        color: #9FA2B4;
        text-align: center;
        margin-bottom: 5px;
    }
    .qtt {
        margin-top: 10px;
        font-size: 40px;
        color: #252733;
    }
`