import React, { useState, useEffect } from "react";
import API from "../../utils/api";

import { SearchFilterArea } from "./styled";

let timer;
let delay = 0;
const DELAY_TIME = 800;

const SearchFilter = ({ setSearchMethod, activeOrDelete }) => {
  const [description, setDescription] = useState("");

  useEffect(() => {
    delay = 0;
  }, [activeOrDelete]);

  useEffect(() => {
    const info = {
      description,
    };

    if (timer) clearTimeout(timer);

    timer = setTimeout(() => {
      setSearchMethod(() => async () => {
        const response =
          activeOrDelete === "active"
            ? await API.getWaysOfDoingActive(info)
            : await API.getWaysOfDoingInactive(info);

        const data = response.data.body.data;
        return data;
      });
    }, delay);

    delay = DELAY_TIME;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description, activeOrDelete]);

  return (
    <SearchFilterArea>
      Filtrar:
      <div className="search-filter">
        <div className="search-filter__input">
          <input
            type="text"
            placeholder="Pesquisar por descrição"
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
        </div>
      </div>
    </SearchFilterArea>
  );
};

export default SearchFilter;
