import React from "react";
import { FormArea } from "../styled";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import API from "../../../utils/api";

const AssociationTable = ({ action }) => {
  const { complementaryFeatureId } = useParams();
  const [classifications, setClassifications] = useState([]);
  const [classificationId, setClassificationId] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const response = await API.getClassificationStructureActive();
      const data = response.data.body.data
      const result  = data.sort((a, b) =>
      a.description > b.description ? 1 : -1,
    );
      setClassifications(result);
      return response;
    }
    fetchData();
  }, []);

  const validateForm = () => {
    if (!classificationId) return false;
    return true;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const requestBody = {
      complementaryFeatureId: complementaryFeatureId,
      classificationId: classificationId,
    };

    try {
      const response = await API.simpleCreateAdditionalClassificationFeature(
        requestBody
      );

      if (response.data.statusCode === 200) {
        await swal({
          title: "Tudo certo!",
          text: "Característica Complementar foi associada com sucesso!",
          icon: "success",
        });
        action("view");
        return;
      }

      await swal({
        title: "Algo inesperado aconteceu!",
        text: "Por favor tente novamente mais tarde.",
        icon: "error",
      });
    } catch (error) {
      await swal({
        title: "Algo inesperado aconteceu!",
        text: "Por favor tente novamente mais tarde.",
        icon: "error",
      });
    }
  };

  return (
    <FormArea>
      <div className="form--area">
        <form onSubmit={handleFormSubmit}>
          <label htmlFor="type">Associar uma classificação:</label>
          <select
            id="type"
            onChange={(e) => {
              setClassificationId(e.target.value);
            }}
          >
            <option value={null}>Selecione uma classificação</option>
            {classifications.map((classification) => {
              return (
                <option key={classification._id} value={classification._id}>
                  {classification.description}
                </option>
              );
            })}
          </select>

          <button type="submit">Salvar</button>
        </form>
      </div>
    </FormArea>
  );
};

export default AssociationTable;
