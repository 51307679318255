import React, { useEffect, useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import { FormArea } from "./styled";
import Api from "../../utils/api";
import swal from "sweetalert";
import { isEmpty } from "lodash";
import backButton from "../../assets/arrow_back.svg";
import API from "../../utils/api";
import LengthCounter from "../components/LengthCounter";

const Edit = () => {
  const history = useHistory();
  const { action, groupId } = useParams();

  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState({
    description: "",
  });

  useEffect(() => {
    setErrors({ ...errors, name: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description]);

  useEffect(() => {
    const availableActions = ["edit", "create"];

    if (
      !availableActions.includes(action) ||
      (action === "edit" && !groupId) ||
      (action === "create" && groupId)
    ) {
      history.push("/feature-group");
      return null;
    }

    if (action === "edit") {
      getGroupId(groupId);
    }
  }, [action, groupId, history]);

  const getGroupId = async (id) => {
    try {
      const response = await Api.getFeatureGroupById(id);

      if (response.status === 200) {
        const { description } = response.data.body.data;

        setDescription(description);
      }
    } catch (error) {
      console.log(error);
      await swal({
        title: "Algo inesperado aconteceu!",
        text: "Por favor tente novamente mais tarde.",
        icon: "error",
      });
    }
  };

  const validateForm = () => {
    const err = {};

    if (description < 3)
      err.description = "O campo deve possuir pelo menos 3 dígitos";

    if (!isEmpty(err)) {
      console.log(err);
      setErrors({ ...errors, ...err });
      return false;
    }

    setErrors({
      description: "",
    });
    return true;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const requestBody = {
      description: description,
    };

    try {
      if (action === "edit") {
        await API.updateFeatureGroup(groupId, requestBody);

        await swal({
          title: "Tudo certo!",
          text: "Grupo de Características foi atualizado com sucesso!",
          icon: "success",
        });
      } else {
        await Api.createFeatureGroup(requestBody);

        await swal({
          title: "Tudo certo!",
          text: "Grupo de Características foi criado com sucesso!",
          icon: "success",
        });
      }

      history.push("/feature-group");
    } catch (error) {
      console.log(error);
      await swal({
        title: "Algo inesperado aconteceu!",
        text: "Por favor tente novamente mais tarde.",
        icon: "error",
      });
    }
  };

  return (
    <FormArea>
      <div className="form--area">
        <div className="form--header">
          <Link to="/feature-group">
            <img src={backButton} alt="Voltar" />
          </Link>

          <h1>
            {action === "edit"
              ? "Editar Grupo de Características"
              : "Adicionar Grupo de Características"}
          </h1>
        </div>
        <form onSubmit={handleFormSubmit}>
          <label htmlFor="description">Descrição:</label>
          <input
            type="text"
            id="description"
            value={description}
            maxLength={50}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            required={true}
          />
          <LengthCounter
            props={{
              field: description,
              maxLength: 50,
            }}
          />
          {errors.description !== "" && (
            <div className="form--error">{errors.description}</div>
          )}

          <button type="submit">Salvar</button>
        </form>
      </div>
    </FormArea>
  );
};

export default Edit;
