import React, { useState } from "react";
import swal from "sweetalert";
import API from "../../utils/api";
import { formatDateAndTime } from "../../utils/date";
import { formatText } from "../../utils/formatText";
import { Switch } from "@material-ui/core";

import styled from "styled-components";

export const OrderContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: -9px;
  width: 100%;
`;

const ActiveTable = ({ data, refreshTable }) => {
  const [hiddenActive, setHiddenActive] = useState(false);

  const handleEditAction = async (id) => {
    const confirm = await confirmModalEmphasis();

    if (confirm) {
      try {
        const responseAnnounce = await API.getAnnounceById(id);
        const announce = responseAnnounce.data.body.data;

        const responseEnvironment = await API.getEnvironmentByAnnounce(id);
        const environment = responseEnvironment.data.body.data;

        const requestBody = {
          emphasisType: 1,
          emphasisId: id,
          environmentId: environment.environmentId,
          classificationId: announce.classification[0].classificationId._id,
        };

        await API.createHomeEmphasis(requestBody);

        await swal({
          title: "Tudo certo!",
          text: "Anúncio marcado como destaque!",
          icon: "success",
        });
      } catch (error) {
        // console.log("body:", error);
        await swal({
          title: "Algo inesperado aconteceu!",
          text: "Por favor tente novamente mais tarde.",
          icon: "error",
        });
      }
    }
    refreshTable();
  };

  const confirmModalEmphasis = () => {
    const confirm = swal("Tem certeza que deseja destacar esse Anúncio?", {
      buttons: ["Cancelar", "Confirmar"],
    }).then((value) => (value ? true : false));

    return confirm;
  };

  return (
    <div className="table-responsive">
      <OrderContainer>
        <span style={{ color: "gray" }}>Ocultar Destacados</span>
        <Switch
          checked={hiddenActive}
          onChange={() => setHiddenActive(!hiddenActive)}
        />
      </OrderContainer>
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Data de Registro</th>
            <th>Nome</th>
            <th>Descrição</th>
            <th>Valor</th>
            <th>Nome do vendedor</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item, index) => {
              // --------------------------------------------------------------- //
              const isDestaque = item.emphasis && item.emphasis.length > 0;
              // --------------------------------------------------------------- //

              return (
                <tr key={index}>
                  {hiddenActive && isDestaque ? null : (
                    <>
                      <td>{index + 1}</td>
                      <td>{formatDateAndTime(item.createdAt)}</td>
                      <td>{formatText(item.title, 30)}</td>
                      <td>
                        {item.description
                          ? formatText(item.description, 20)
                          : "---"}
                      </td>
                      <td>
                        {(item.value / 100).toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </td>
                      <td>
                        {item.accountId
                          ? formatText(item.accountId.accountName, 35)
                          : "Sem loja"}
                      </td>

                      <td>
                        <button
                          className={
                            isDestaque ? "button--disabled" : "activate--button"
                          }
                          onClick={() => {
                            if (isDestaque) return;
                            handleEditAction(item._id);
                          }}
                        >
                          {isDestaque ? "Destacado" : "Destacar"}
                        </button>
                      </td>
                    </>
                  )}
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default ActiveTable;
