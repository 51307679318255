import React, { useEffect } from "react";
import NavBar from "../partials/Navbar";

const Template = ({ component = null, auth }) => {
  const Component = component;

  useEffect(() => {
    document.title = "PAPOOM - Admin";
  }, []);

  return (
    <>
      <NavBar />
      <main className="dashboard--main">
        {component && <Component auth={auth} />}
      </main>
    </>
  );
};

export default Template;
