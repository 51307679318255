import React from "react";
import { FormArea } from "../styled";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react/cjs/react.development";
import swal from "sweetalert";
import API from "../../../utils/api";

const AssociationTable = ({ action }) => {
  const { announceTypeId } = useParams();
  const [classifications, setClassifications] = useState([]);
  const [classificationId, setClassificationId] = useState(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const response = await API.getClassificationStructureActive();
    const data = response.data.body.data
    const result  = data.sort((a, b) =>
    a.description > b.description ? 1 : -1,
  );
    setClassifications(result);
  }, []);

  const validateForm = () => {
    if (!classificationId) return false;
    return true;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const requestBody = {
      announceTypeId: announceTypeId.toString(),
      classificationId: classificationId,
    };
    try {
      const response = await API.createAnnounceTypeClassification(requestBody);
      if (response.data.statusCode === 200) {
        await swal({
          title: "Tudo certo!",
          text: "Tipo de Anúncio foi vínculado com sucesso!",
          icon: "success",
        });
        action("view");
        return;
      }

      await swal({
        title: "Algo inesperado aconteceu!",
        text: "Por favor tente novamente mais tarde.",
        icon: "error",
      });
    } catch (error) {
      if ((error.response.status = 406)) {
        await swal({
          title: "Classificação já vinculada!",
          text: "Já existe uma Classificação registrada na plataforma para o Tipo de Anúncio.",
          icon: "error",
        });
      } else {
        await swal({
          title: "Algo inesperado aconteceu!",
          text: "Por favor tente novamente mais tarde.",
          icon: "error",
        });
      }
    }
  };

  return (
    <FormArea>
      <div className="form--area">
        <form onSubmit={handleFormSubmit}>
          <label htmlFor="type">Vincular uma classificação:</label>
          <select
            id="type"
            onChange={(e) => {
              setClassificationId(e.target.value);
            }}
          >
            <option value={null}>Selecione uma classificação</option>
            {classifications.map((classification) => {
              return (
                <option value={classification._id}>
                  {classification.description}
                </option>
              );
            })}
          </select>

          <button type="submit">Salvar</button>
        </form>
      </div>
    </FormArea>
  );
};

export default AssociationTable;
