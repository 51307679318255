import React from "react";
import swal from "sweetalert";
import API from "../../utils/api";
import { formatDateAndTime } from "../../utils/date";

const Page = ({ data, refreshTable }) => {
  const handleAction = async (action, id) => {
    if (await confirmModal(action)) {
      switch (action) {
        case "Ativar":
          try {
            const response = await API.activeStore(id);

            if (response.status === 200) {
              await swal({
                title: "Tudo certo!",
                text: "Loja ativada com sucesso!",
                icon: "success",
              });
              refreshTable();
            } else {
              await swal({
                title: "Ocorreu algo de errado!",
                text: "Ocorreu algum erro ao ativar a loja.",
                icon: "error",
              });
            }
          } catch (error) {
            if (!error.response.data?.body?.errors) {
              await swal({
                title: "Algo inesperado aconteceu!",
                text: "Por favor tente novamente mais tarde.",
                icon: "error",
              });
              return;
            }

            switch (error.response.data.body.errors) {
              case "exceptions:CANNOT_ACTIVATE_STORE_STATUS":
                await swal({
                  title: "Este item não pode ser ativado!",
                  text: "Por favor contate um admin.",
                  icon: "error",
                });
                break;

              case "exceptions:NO_ASSOCIATED_ENVIRONMENT_FOR_STORE":
                await swal({
                  title: "Este item não possui um ambiente vinculado!",
                  text: "Por favor vincule um ambiente a esta loja antes de ativá-la.",
                  icon: "error",
                });
                break;

              case "exceptions:NO_ASSOCIATED_CLASSIFICATION_STRUCTURE_FOR_STORE":
                await swal({
                  title:
                    "Este item não possui uma estrutura de classificação vinculada!",
                  text: "Por favor vincule uma estrutura de classificação a esta loja antes de ativá-la.",
                  icon: "error",
                });
                break;

              case "exceptions:NO_ASSOCIATED_ADDRESS_FOR_STORE":
                await swal({
                  title: "Este item não possui um endereço vinculado!",
                  text: "Por favor vincule um endereço a esta loja antes de ativá-la.",
                  icon: "error",
                });
                break;

              default:
                await swal({
                  title: "Algo inesperado aconteceu!",
                  text: "Por favor tente novamente mais tarde.",
                  icon: "error",
                });
            }
          }
          break;

        case "Pausar":
          try {
            const response = await API.pauseStore(id);

            if (response.status === 200) {
              await swal({
                title: "Tudo certo!",
                text: "Loja pausada com sucesso!",
                icon: "success",
              });
              refreshTable();
            } 
            // else {
            //   await swal({
            //     title: "Ocorreu algo de errado!",
            //     text: "Ocorreu algum erro ao pausar a loja.",
            //     icon: "error",
            //   });
            // }
          } catch (error) {
            if (error.response.data.body.errors === "exceptions:STORE_HAS_ORDERS_IN_PROGRESS") {
              await swal({
                title: "Esta loja possui pedidos em andamento!",
                text: "Por favor contate o vendedor.",
                icon: "error",
              });
              return;
            }

            switch (error.response.data.body.errors) {
              case "exceptions:STORE_ALREADY_PAUSED":
                await swal({
                  title: "Esta loja já está pausada!",
                  text: "Por favor contate um admin.",
                  icon: "error",
                });
                break;

                case "exceptions:STORE_HAS_ORDERS_IN_PROGRESS":
                await swal({
                  title: "Esta loja possui pedidos em andamento!",
                  text: "Por favor contate o vendedor.",
                  icon: "error",
                });
                break;

              default:
                await swal({
                  title: "Algo inesperado aconteceu!",
                  text: "Por favor tente novamente mais tarde.",
                  icon: "error",
                });
                
            }
          }
          break;

        case "Remover":
          try {
            const response = await API.removeStore(id);

            if (response.status === 200) {
              await swal({
                title: "Tudo certo!",
                text: "Loja removida com sucesso!",
                icon: "success",
              });
              refreshTable();
            } else {
              await swal({
                title: "Ocorreu algo de errado!",
                text: "Ocorreu algum erro ao pausar a loja.",
                icon: "error",
              });
            }
          } catch (error) {
            if (!error.response.data?.body?.errors) {
              await swal({
                title: "Algo inesperado aconteceu!",
                text: "Por favor tente novamente mais tarde.",
                icon: "error",
              });
              return;
            }

            switch (error.response.data.body.errors) {
              case "exceptions:STORE_ALREADY_REMOVED":
                await swal({
                  title: "Esta loja já está removida!",
                  text: "Por favor contate um admin.",
                  icon: "error",
                });
                break;

                case "exceptions:STORE_HAS_ORDERS_IN_PROGRESS":
                await swal({
                  title: "Esta loja possui pedidos em andamento!",
                  text: "Por favor contate o vendedor.",
                  icon: "error",
                });
                break;

              default:
                await swal({
                  title: "Algo inesperado aconteceu!",
                  text: "Por favor tente novamente mais tarde.",
                  icon: "error",
                });
            }
          }
          break;

          case "passar para Liberação":
          try {
            const response = await API.onReleaseStore(id);

            if (response.status === 200) {
              await swal({
                title: "Tudo certo!",
                text: "Loja está aguandando a liberação!",
                icon: "success",
              });
              refreshTable();
            } else {
              await swal({
                title: "Ocorreu algo de errado!",
                text: "Ocorreu algum erro ao pausar a loja.",
                icon: "error",
              });
            }
          } catch (error) {
            if (!error.response.data?.body?.errors) {
              await swal({
                title: "Algo inesperado aconteceu!",
                text: "Por favor tente novamente mais tarde.",
                icon: "error",
              });
              return;
            }

            switch (error.response.data.body.errors) {
              case "exceptions:STORE_ALREADY_REMOVED":
                await swal({
                  title: "Esta loja já está em liberação!",
                  text: "Por favor contate um admin.",
                  icon: "error",
                });
                break;

                case "exceptions:STORE_HAS_ORDERS_IN_PROGRESS":
                await swal({
                  title: "Esta loja possui pedidos em andamento!",
                  text: "Por favor contate o vendedor.",
                  icon: "error",
                });
                break;

              default:
                await swal({
                  title: "Algo inesperado aconteceu!",
                  text: "Por favor tente novamente mais tarde.",
                  icon: "error",
                });
            }
          }
          break;

        default:
          break;
      }
    }
  };

  const confirmModal = (action) => {
    const confirm = swal(`Tem certeza que deseja ${action} essa Loja?`, {
      buttons: ["Cancelar", "Confirmar"],
    }).then((value) => (value ? true : false));

    return confirm;
  };

  return (
    <table>
      <thead>
        <tr>
          <th>#</th>
          <th>Registro</th>
          <th>Loja</th>
          <th>Responsável</th>
          <th>Ações</th>
        </tr>
      </thead>
      <tbody>
        {data &&
          data.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{formatDateAndTime(item.createdAt)}</td>
              <td>{item.title}</td>
              <td>
                {" "}
                {item?.responsible && !item?.responsible.fullName
                  ? item?.responsible.corporateName
                  : item?.responsible.fullName}
              </td>
              <td>
              {[5].includes(item.status) ? (
                  <button
                    className="activate--button"
                    onClick={() => {
                      handleAction("passar para Liberação", item._id);
                    }}
                  >
                    Liberação
                  </button>
                ) : null}
                {[1, 4, 3].includes(item.status) ? (
                  <button
                    className="activate--button"
                    onClick={() => {
                      handleAction("Ativar", item._id);
                    }}
                  >
                    Ativar
                  </button>
                ) : null}
                {[2].includes(item.status) ? (
                  <button
                    className="pause--button"
                    onClick={() => {
                      handleAction("Pausar", item._id);
                    }}
                  >
                    Pausar
                  </button>
                ) : null}
                {[1, 4].includes(item.status) ? (
                  <button
                    className="delete--button"
                    onClick={() => {
                      handleAction("Remover", item._id);
                    }}
                  >
                    Remover
                  </button>
                ) : null}
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};
export default Page;
