import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import { NavBarArea } from "./styled";

import backButton from "../../assets/arrow_back.svg";

const NavBar = () => {
  const location = useLocation();

  const [route, setRoute] = useState("/");

  useEffect(() => {
    setRoute(location.pathname);
  }, [location]);

  return (
    <NavBarArea>
        <Link to="/order-management" className="back-button">
            <img src={backButton} alt="Voltar" />
        </Link>
      <h1 className="label">Painel de Estatísticas</h1>
      <ul className="navbar-nav">
      <li
          className={
            route?.startsWith("/analytics/exports")
              ? "nav-item active"
              : "nav-item"
          }
        >
          <Link to="/analytics/exports" className="nav-link">
            <div className="icon">
            </div>
            <div className="title">Exportadores</div>
          </Link>
        </li>
        <li
          className={
            route?.startsWith("/analytics/orders")
              ? "nav-item active"
              : "nav-item"
          }
        >
          <Link to="/analytics/orders" className="nav-link">
            <div className="icon">
            </div>
            <div className="title">Pedidos</div>
          </Link>
        </li>

        <li
          className={
            route?.startsWith("/analytics/sellers") ? "nav-item active" : "nav-item"
          }
        >
          <Link to="/analytics/sellers" className="nav-link">
            <div className="icon">
            </div>
            <div className="title">Vendedores</div>
          </Link>
        </li>

        <li
          className={
            route?.startsWith("/analytics/categories")
              ? "nav-item active"
              : "nav-item"
          }
        >
          <Link to="/analytics/categories" className="nav-link">
            <div className="icon">
            </div>
            <div className="title">Categorias</div>
          </Link>
        </li>

        <li
          className={
            route?.startsWith("/analytics/announces") ? "nav-item active" : "nav-item"
          }
        >
          <Link to="/analytics/announces" className="nav-link">
            <div className="icon">
            </div>
            <div className="title">Produtos</div>
          </Link>
        </li>

        <li
          className={
            route?.startsWith("/analytics/payment-methods")
              ? "nav-item active"
              : "nav-item"
          }
        >
          <Link to="/analytics/payment-methods" className="nav-link">
            <div className="icon">
            </div>
            <div className="title">Formas de Pagamento</div>
          </Link>
        </li>

        <li
          className={
            route?.startsWith("/analytics/ways-of-delivering")
              ? "nav-item active"
              : "nav-item"
          }
        >
          <Link to="/analytics/ways-of-delivering" className="nav-link">
            <div className="icon">
            </div>
            <div className="title">Formas de Entrega</div>
          </Link>
        </li>

        {/* <li
          className={
            route?.startsWith("/environment") &&
            !route?.startsWith("/environment-promotions")
              ? "nav-item active"
              : "nav-item"
          }
        >
          <Link to="/environment" className="nav-link">
            <div className="icon">
            </div>
            <div className="title">Anúncios</div>
          </Link>
        </li> */}

        {/* <li
          className={
            route?.startsWith("/environment-promotions")
              ? "nav-item active"
              : "nav-item"
          }
        >
          <Link to="/environment-promotions" className="nav-link">
            <div className="icon">
            </div>
            <div className="title">Pedidos por faixas de horários </div>
          </Link>
        </li> */}

        <li
          className={
            route?.startsWith("/analytics/frequeny-analysis")
              ? "nav-item active"
              : "nav-item"
          }
        >
          <Link to="/analytics/frequeny-analysis" className="nav-link">
            <div className="icon">
            </div>
            <div className="title">Análise de Frequência</div>
          </Link>
        </li>

        {/* <li
          className={
            route?.startsWith("/complementary-features")
              ? "nav-item active"
              : "nav-item"
          }
        >
          <Link to="/complementary-features" className="nav-link">
            <div className="icon">
            </div>
            <div className="title">Páginas</div>
          </Link>
        </li> */}

        {/* <li
          className={
            route?.startsWith("/type-store") ? "nav-item active" : "nav-item"
          }
        >
          <Link to="/type-store" className="nav-link">
            <div className="icon">
            </div>
            <div className="title">Análise de Valor</div>
          </Link>
        </li> */}

        {/* <li
          className={
            route?.startsWith("/store-management")
              ? "nav-item active"
              : "nav-item"
          }
        >
          <Link to="/store-management" className="nav-link">
            <div className="icon">
            </div>
            <div className="title">Uso</div>
          </Link>
        </li> */}

        {/* <li
          className={
            route?.startsWith("/store-emphasis") &&
            !route?.startsWith("/store-management")
              ? "nav-item active"
              : "nav-item"
          }
        >
          <Link to="/store-emphasis" className="nav-link">
            <div className="icon">
            </div>
            <div className="title">Compartilhamentos</div>
          </Link>
        </li> */}

      </ul>
    </NavBarArea>
  );
};

export default NavBar;
