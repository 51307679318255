import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { formatDateAndTime } from "../../utils/date";
import { FormArea, Table, DivLine } from "./styled";

import backButton from "../../assets/arrow_back.svg";
import API from "../../utils/api";
import swal from "sweetalert";

import Loading from "../components/Loading";

const Details = () => {
  const [data, setData] = useState(null);
  const [dataItems, setDataItems] = useState([]);
  const [dataStore, setDataStore] = useState([]);
  const [loading, setLoading] = useState(true);

  const { orderId } = useParams();

  useEffect(() => {
    const fetchData = async (id) => {
      try {
        const response = await API.getOrderById(id);
        setData(response.data.body.data);
        const responseItems = await API.getSalesOrderItemsByOrder(id); // retorna lista de itens
        setDataItems(responseItems.data.body.data); // Dúvida - Por que só primeiro item?
        const storeId = response.data.body.data.storeId.id;
        const responseStore = await API.getStoreAddressByStore(storeId);
        setDataStore(responseStore.data.body.data[0]);

        setLoading(false);
        console.log('não ocorreu erro no fetch')
      } catch (error) {
        console.log(error)
        await swal({
          title: "Ocorreu algo de errado!",
          icon: "error",
        });
      }
    };

    fetchData(orderId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) <Loading />;

  const orderStatus = {
    1: "Novo Pedido",
    2: "Em Preparo",
    3: "Preparado",
    4: "Concluído",
    5: "Avaliado",
    6: "Devolvido",
    7: "Cancelado",
    8: "Recusado",
  };

  // Taxa De Entrega
  const feeValue = data && data.feeValue / 100;

  const subTotal = dataItems.reduce(
    (total, item) => (total += item.amount * item.value),
    0
  );

  const discount = Number(data?.promotionId?.discountValue) || 0;

  const total = subTotal / 100 + feeValue - discount / 100;

  return (
    <FormArea>
      <div className="form--area">
        <div className="form--header">
          <Link to="/order-management">
            <img src={backButton} alt="Voltar" />
          </Link>
          <h1>Detalhes do Pedido</h1>
        </div>

        <DivLine>
          <div className="container">
            <Table>
              {/* TABELA DO CLIENTE */}
              <table className="styled-table">
                <tbody>
                  <tr>
                    <td className="primary table-title" colSpan="5">
                      <b>Detalhes do Cliente</b>
                    </td>
                  </tr>
                  <tr>
                    <td className="second">Nome</td>
                    <td>{data?.profile && data.profile.fullName !== undefined
                    ? data.profile.fullName 
                    : data?.profile.corporateName
                  }</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td className="primary">Telefone</td>
                    <td>
                      ({data?.buyer && data.buyer.dddPhone}){" "}
                      {data?.buyer && data.buyer.cellPhone}
                    </td>
                  </tr>
                  {
                    data?.wayOfDeliveringId && data?.wayOfDeliveringId.requiresDeliveryLocation === false ? null :
                    
                  <><tr>
                        <td className="second">Endereço</td>
                        <td>
                          Bairro:{" "}
                          {data?.deliveryAddress &&
                            data.deliveryAddress.addressId.district}
                        </td>
                        <td>
                          Rua:{" "}
                          {data?.deliveryAddress &&
                            data.deliveryAddress.addressId.logradouro}
                        </td>
                        <td>
                          Nº{" "}
                          {data?.deliveryAddress &&
                            data.deliveryAddress.addressId.number}
                        </td>
                      </tr><tr>
                          <td className="second"></td>
                          <td>
                            Referência:{" "}
                            {data?.deliveryAddress &&
                              data.deliveryAddress.addressId.referencePoint !== ""
                              ? data.deliveryAddress.addressId.referencePoint
                              : "---"}
                          </td>
                          <td>
                            Complemento:{" "}
                            {data?.deliveryAddress &&
                              data.deliveryAddress.addressId.complement !== ""
                              ? data.deliveryAddress.addressId.complement
                              : '---'}
                          </td>
                        </tr><tr>
                          <td className="second"></td>
                          <td>
                            CEP:{" "}
                            {data?.deliveryAddress &&
                              data.deliveryAddress.addressId.cep !== null
                              ? data.deliveryAddress.addressId.cep
                              : '---'}
                          </td>
                          <td>
                            Cidade:{" "}
                            {data?.deliveryAddress &&
                              data.deliveryAddress.addressId.city}
                          </td>
                          <td>
                            UF:{" "}
                            {data?.deliveryAddress &&
                              data.deliveryAddress.addressId.uf}
                          </td>
                        </tr></>
                  }
                </tbody>
              </table>

              {/* TABELA DA LOJA */}
              <table className="styled-table">
                <tbody>
                  <tr>
                    <td className="primary table-title" colSpan="5">
                      <b>Detalhes da Loja</b>
                    </td>
                  </tr>
                  <tr>
                    <td className="second">Loja</td>
                    <td>{data?.storeId && data.storeId.title}</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td className="primary">Nome do Vendedor</td>
                    <td>
                      {data?.storeId && !data?.storeId.responsible.corporateName
                        ? data?.storeId.responsible.fullName
                        : data?.storeId.responsible.corporateName}
                    </td>
                  </tr>
                  <tr>
                    <td className="second">Número do Vendedor</td>
                    {data?.storeId?.responsible?.accountId?.dddPhone &&
                    data?.storeId?.responsible?.accountId?.cellPhone ? (
                      <td>
                        (
                        {data?.storeId &&
                          data?.storeId.responsible.accountId.dddPhone}
                        ){" "}
                        {data?.storeId &&
                          data.storeId.responsible.accountId.cellPhone}
                      </td>
                    ) : (
                      <td>---</td>
                    )}
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td className="primary">Endereço</td>
                    <td>
                      Bairro:{" "}
                      {dataStore?.accountAddressId &&
                        dataStore.accountAddressId.addressId.district}
                    </td>
                    <td>
                      Rua:{" "}
                      {dataStore?.accountAddressId &&
                        dataStore.accountAddressId.addressId.logradouro}
                    </td>
                    <td>
                      Nº{" "}
                      {dataStore?.accountAddressId &&
                        dataStore.accountAddressId.addressId.number}
                    </td>
                  </tr>
                  <tr>
                    <td className="primary"></td>
                    <td>
                      Referência:{" "}
                      {dataStore?.accountAddressId &&
                        dataStore.accountAddressId.addressId.referencePoint !== ""
                        ? dataStore.accountAddressId.addressId.referencePoint
                        : "---"}
                    </td>
                    <td>
                      Complemento:{" "}
                      {dataStore?.accountAddressId &&
                        dataStore.accountAddressId.addressId.complement !== ""
                        ? dataStore.accountAddressId.addressId.complement 
                        : "---"}
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td className="primary"></td>
                    <td>
                      CEP:{" "}
                      {dataStore?.accountAddressId &&
                        dataStore.accountAddressId.addressId.cep !== null
                        ? dataStore.accountAddressId.addressId.cep
                        : "---"} 
                    </td>
                    <td>
                      Cidade:{" "}
                      {dataStore?.accountAddressId &&
                        dataStore.accountAddressId.addressId.city}
                    </td>
                    <td>
                      UF:{" "}
                      {dataStore?.accountAddressId &&
                        dataStore.accountAddressId.addressId.uf}
                    </td>
                  </tr>
                </tbody>
              </table>

              {/* TABELA DO PEDIDO */}
              <table className="styled-table">
                <tbody>
                  <tr>
                    <td className="primary table-title" colSpan="5">
                      <b>Detalhes do Pedido</b>
                    </td>
                  </tr>
                  <tr>
                    <td className="second">Nº do Pedido</td>
                    <td>{data?.orderNumber && data.orderNumber}</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td className="primary">Data e Hora</td>
                    <td>
                      {data?.createdAt && formatDateAndTime(data.createdAt)}
                    </td>
                  </tr>
                  <tr>
                    <td className="second">Status</td>
                    <td>{data?.status && orderStatus[data.status]}</td>
                    <td></td>
                    <td></td>
                  </tr>
                  {dataItems &&
                    dataItems?.map((prod) => (
                      <React.Fragment key={prod._id}>
                        <tr>
                          <td className="primary">Produto</td>
                          <td>
                            <i>{prod.title}</i>
                          </td>
                          <td>
                            <i>
                              {(prod.value / 100).toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              })}
                            </i>
                          </td>
                          <td>
                            <i>x{prod.amount}</i>
                          </td>
                        </tr>
                        {prod.details === "" ? null : 
                        <tr>
                          <td className="second">Observações</td>
                          <td>{prod.details}</td>
                          <td></td>
                          <td></td>
                        </tr> 
                        }

                        </React.Fragment>
                        ))}
                  <tr>
                    <td className={"second"}>Forma de Entrega</td>
                    <td>
                      {data?.wayOfDeliveringId &&
                        data.wayOfDeliveringId.description}
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td className={"primary"}>Taxa de Entrega</td>
                    <td>
                      {feeValue && feeValue !== 0
                        ? feeValue.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })
                        : "Gratis"}
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td className={"second" }>Forma de Pagamento</td>
                    <td>
                      {data?.paymentMethodId &&
                        data.paymentMethodId.description}
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  {
                    data?.paymentMethodId &&
                    data.paymentMethodId.description !== 'Dinheiro' ? null : 

                    <tr>
                      <td className={
                        data?.paymentMethodId &&
                        data.paymentMethodId.description !== 'Dinheiro' ? "second" : "primary"
                      }>
                        Troco para
                      </td>
                        <td>
                          {data?.valueChange && data?.valueChange !== undefined
                            ? (data?.valueChange / 100).toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              })
                              : "---"}
                        </td>
                      <td></td>
                      <td></td>
                    </tr>
                      
                  }
                    {
                      data?.promotionId && data?.promotionId !== null ? 
                      <tr>
                    <td className="primary">Desconto de Promoção</td>
                    <td className="active-row">
                      {data?.promotionId?.discountValue
                        ? (
                          data?.promotionId?.discountValue / 100
                          ).toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })
                        : "---"}
                    </td>
                  </tr>
                  : null
                        }
                  
                  <tr>
                    <td className=
                    {
                      data?.paymentMethodId &&
                      data.paymentMethodId.description !== 'Dinheiro' ? "primary" : "second"
                    }>
                      Valor Total
                    </td>
                    <td className="active-row">
                      {total.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }) || 0}
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  
                  {
                    data?.paymentMethodId &&
                    data.paymentMethodId.description !== 'Dinheiro' ? null :
                  <tr>
                    <td className="primary">Troco</td>
                    <td>
                      {data?.valueChange && data?.valueChange !== undefined
                        ? (data?.valueChange / 100 - total).toLocaleString(
                            "pt-BR",
                            {
                              style: "currency",
                              currency: "BRL",
                            }
                          )
                        : "---"}
                    </td>

                    <td></td>
                    <td></td>
                  </tr>
            }
                  <tr>
                    <td className="second">
                      Motivo do Cancelamento
                    </td>
                    <td>
                      {data?.reasonRefusalCancellation &&
                      data?.reasonRefusalCancellation !== undefined
                        ? data?.reasonRefusalCancellation
                        : "---"}
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </Table>
          </div>
        </DivLine>
      </div>
    </FormArea>
  );
};

export default Details;
