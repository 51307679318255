import styled from 'styled-components';

export const ActiveDeleted = styled.div`
    font-family: 'Montserrat';
    color: #fff;
    display: flex;
    flex-direction: column;
    position: relative;

    .add-button {
        padding: 0.8rem 1rem;
        border-radius: 5px;
        border: 0;
        background-color: #35A69B;
        color: #fff;
        font-size: 1rem;
        cursor: pointer;
        text-decoration: none;
        margin-left: auto;
        margin-bottom: -20px;
        position: absolute;
        right: 0px;
        border: 2px solid #35A69B;
        font-weight: bold;
        transition: all 0.3s ease-in-out 0s;

        &:hover {
            background-color: transparent;
            color: #35A69B;
            border: 2px solid #35A69B;
        }
    }

    .active-deleted-header {
        display: flex;
        margin-top: 25px;
        border-bottom: 1px solid #ddd;

        .active-deleted-header-title {
            padding: 10px 15px;
            border: 1px solid #757575;
            margin-bottom: -1px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            cursor: pointer;
            transition: all 0.3s ease-in-out 0s;
            color: #757575;
            
            &:hover {
                border-color: #c9c1c1;
            }

            &.active {
                background-color: #EF4937;
                border-color: #ddd;
                border-bottom-color: rgba(221, 221, 221, 0.3);
                color: #fff;
                border-left: none;
                border-right: none;
            }
        }
    }

    .search--bar {
        margin-top: 20px;
    }

    .loading {
        width: 100%;
        position: absolute;
        margin: 0 auto;
        margin-top: 100px;
    }
`;
