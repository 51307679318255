import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { BannerImageContainer, Overlay, ReorderButton, EditButton } from "../styled";

import trashSVG from "../../../assets/trash.svg";
import ConfirmDeleteBannerModal from "../confirmDeleteBannerModal";

const ListBannerSection = ({
  item,
  sectionName,
  handleManageConfigurationAction,
  setBannerGridId,
  handleManageEditAction,
  handleReorder,
  fetchData,
  loading,
  handleCloneSection
}) => {
  const [isConfirmDeleteBannerModalOpen, setIsConfirmDeleteBannerModalOpen] = useState(null);
  const [selectedBannerId, setSelectedBannerId] = useState(null);

  const orderFunction = (a, b) => {
    return a.carrouselPosition - b.carrouselPosition;
  };
  const handleMouseEnter = (bannerGridId) => {
    setBannerGridId(bannerGridId);
  };

  const bannerList = item.banners.length > 0 && item.banners.sort(orderFunction);

  return (
    <>
      {selectedBannerId && (
        <ConfirmDeleteBannerModal
          bannerId={selectedBannerId}
          isOpen={isConfirmDeleteBannerModalOpen}
          onClose={() => setIsConfirmDeleteBannerModalOpen(false)}
          fetchData={fetchData}
        />
      )}
      <div style={{ marginLeft: 10 }} key={item._id}>
        <div style={{ display: "flex", gap: 10, alignItems: "center", justifyContent: "space-between" }}>
          <p style={{ color: "#757575", fontSize: 18, fontWeight: 600 }}>{sectionName}</p>
          <div>
            
            <Button
                style={{ padding: "10px", marginRight: 10, marginLeft: 15, backgroundColor: "#35A69B", color: "white", border: 'none', textTransform: 'none'}}
                variant="outlined"
                onClick={() => handleManageConfigurationAction(sectionName, item)}
              >
                Editar seção
              </Button>
              <Button
                style={{ padding: "10px", marginRight: 10, marginLeft: 15, backgroundColor: "#35A69B", color: "white", border: 'none', textTransform: 'none' }}
                variant="outlined"
                onClick={() => {
                  handleCloneSection(item._id, sectionName);
                }}
              >
                Clonar Seção
              </Button>
              <Button
                style={{ padding: "10px", marginRight: 10, marginLeft: 15, backgroundColor: "#35A69B", color: "white", border: 'none', textTransform: 'none' }}
                variant="outlined"
                onClick={() => {
                  handleManageEditAction(null, item.destinationType);
                  handleMouseEnter(item._id);
                }}
              >
                Adicionar Banner
              </Button>
              
          </div>
        </div>
        <div
          style={{
            display: "flex",
            gap: 4,
            marginTop: 20,
            marginBottom: 20,
            alignItems: "center",
          }}
        >
          {bannerList.length > 0 &&
            bannerList.map((banner, index) => {
              return (
                <BannerImageContainer
                  style={{
                    height: 80,
                    width: 180,
                  }}
                  key={banner._id}
                >
                  <img
                    alt="#"
                    height={80}
                    width={180}
                    src={banner.imageUrl}
                    style={{ objectFit: "cover", borderRadius: "8px" }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      background: "#35A69B",
                      width: '22px',
                      height: '22px',
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >{index + 1}</div>
                  <Overlay
                    style={{
                      flexDirection: "column",
                    }}
                    className="overlay"
                    onMouseEnter={() => handleMouseEnter(item._id)}
                  >
                    <div
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "0 15px",
                        width: "100%",
                      }}
                    >
                      {index !== 0 ? (
                        <ReorderButton onClick={async () => await handleReorder(banner._id, "left")}>
                          {"<"}
                        </ReorderButton>
                      ) : <div style={{ width: 20 }}/>}    

                      {/* <div onClick={()=> handleManageEditAction(banner)}> */}
                      <EditButton
                        style={{ fontSize: 12 }}
                        onClick={() => handleManageEditAction(banner, item.destinationType)}
                      >
                        Editar
                      </EditButton>
                      {/* <EditOutlined /> */}
                      {/* </div> */}

                      {index + 1 !== bannerList.length ? (
                        <ReorderButton onClick={async () => await handleReorder(banner._id, "right")} style={{}}>
                          {">"}
                        </ReorderButton>
                      ): <div style={{ width: 20 }}/>}
                    </div>
                    <img
                      src={trashSVG}
                      alt="icone de deletar"
                      width={16}
                      color="#FFF"
                      style={{ color: "#FFF", cursor: "pointer", padding: "5px" }}
                      onClick={() => {
                        setSelectedBannerId(banner._id);
                        setIsConfirmDeleteBannerModalOpen(true);
                      }}
                    />
                  </Overlay>
                </BannerImageContainer>
              );
            })}
        </div>
        <hr />
      </div>
    </>
  );
};

export default ListBannerSection;
