import axios from "axios";
import qs from "qs";
import _ from "lodash";
import EventBus from "./eventBus";

const BASE_API = process.env.REACT_APP_BASE_API_URL;
const REQUEST_TIMEOUT = 30000;

const getToken = () => {
  const token = localStorage.getItem("accessToken");
  if (!token) {
    return null;
  }
  return token;
};

const axiosGET = async (endpoint, body = {}) => {
  const token = getToken();

  try {
    const instance = await axios({
      method: "get",
      url: `${BASE_API + endpoint}${_.isEmpty(body) ? "" : "?"}${qs.stringify(
        body
      )}`,
      headers: {
        Accept: "application/json",
        Authorization: token ? `Bearer ${JSON.parse(token)}` : null,
      },
      timeout: REQUEST_TIMEOUT,
    });

    return instance;
  } catch (error) {
    if (error.response.status === 401) {
      if (error.response.data.body.errors === "jwt expired") {
        EventBus.dispatch("JWT_EXPIRED");
      }
    }
    return Promise.reject(error);
  }
};

const axiosPOST = (endpoint, body) => {
  const token = getToken();
  return axios({
    method: "post",
    url: BASE_API + endpoint,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${JSON.parse(token)}` : null,
    },
    data: {
      ...body,
    },
    timeout: REQUEST_TIMEOUT,
  });
};

const axiosPUT = (endpoint, body) => {
  const token = getToken();
  return axios({
    method: "put",
    url: BASE_API + endpoint,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${JSON.parse(token)}` : null,
    },
    data: {
      ...body,
    },
    timeout: REQUEST_TIMEOUT,
  });
};

const axiosPATCH = (endpoint, body) => {
  const token = getToken();
  return axios({
    method: "patch",
    url: BASE_API + endpoint,
    headers: {
      Accept: "application/json",
      Authorization: token ? `Bearer ${JSON.parse(token)}` : null,
    },
    data: body,
    timeout: REQUEST_TIMEOUT,
  });
};

const axiosDELETE = (endpoint) => {
  const token = getToken();
  return axios({
    method: "delete",
    url: BASE_API + endpoint,
    headers: {
      Accept: "application/json",
      Authorization: token ? `Bearer ${JSON.parse(token)}` : null,
    },
    timeout: REQUEST_TIMEOUT,
  });
};

const API = {
  // Auth
  login: (body) => {
    return axiosPOST(`/auth/admin-signin`, body);
  },
  getAccountByToken: (token) => {
    return axiosGET(`/auth/admin/decode-token/${token}`);
  },

  // Table AccountProfiles
  getAccountProfiles: () => {
    return axiosGET("/account-profile");
  },
  getFilteredProfileAccounts: (filter, type) => {
    return axiosGET(`/account-profile/type/${type}/filter`, filter);
  },

  // Table Formas de Realização
  getWaysOfDoingActive: (filter) => {
    return axiosGET("/way-of-doing/actives", filter);
  },
  getWaysOfDoingInactive: (filter) => {
    return axiosGET("/way-of-doing/inactives", filter);
  },
  getWaysOfDoingById: (id) => {
    return axiosGET(`/way-of-doing/${id}`);
  },
  createWaysOfDoing: (body) => {
    return axiosPOST("/way-of-doing", body);
  },
  toggleWaysOfDoing: (id, body) => {
    return axiosPATCH(`/way-of-doing/${id}`, body);
  },
  updateWaysOfDoing: (id, body) => {
    return axiosPUT(`/way-of-doing/${id}`, body);
  },
  deleteWaysOfDoing: (id) => {
    return axiosDELETE(`/way-of-doing/${id}`);
  },

  // Table Formas de Entrega
  getWaysOfDeliveryActive: (filter) => {
    return axiosGET("/way-delivering/actives", filter);
  },
  getWaysOfDeliveryInactive: (filter) => {
    return axiosGET("/way-delivering/inactives", filter);
  },
  getWaysOfDeliveryById: (id) => {
    return axiosGET(`/way-delivering/${id}`);
  },
  createWaysOfDelivery: (body) => {
    return axiosPOST("/way-delivering", body);
  },
  toggleWaysOfDelivery: (id) => {
    return axiosPATCH(`/way-delivering/${id}`);
  },
  updateWaysOfDelivery: (id, body) => {
    return axiosPUT(`/way-delivering/${id}`, body);
  },
  deleteWaysOfDelivery: (id) => {
    return axiosDELETE(`/way-delivering/${id}`);
  },

  // Table Grupo de Características
  getFeatureGroupActive: (filter) => {
    return axiosGET("/feature-group/actives", filter);
  },

  getFeatureGroupById: (id) => {
    return axiosGET(`/feature-group/${id}`);
  },
  createFeatureGroup: (body) => {
    return axiosPOST("/feature-group", body);
  },

  updateFeatureGroup: (id, body) => {
    return axiosPUT(`/feature-group/${id}`, body);
  },
  deleteFeatureGroup: (id) => {
    return axiosDELETE(`/feature-group/${id}`);
  },

  // Table Formas de Pagamento
  getPaymentWays: () => {
    return axiosGET(`/payment-methods`);
  },
  getPaymentWaysActive: (filter) => {
    return axiosGET("/payment-methods/actives", filter);
  },
  getPaymentWaysInactive: (filter) => {
    return axiosGET("/payment-methods/inactives", filter);
  },
  getPaymentWaysById: (id) => {
    return axiosGET(`/payment-methods/${id}`);
  },
  createPaymentWays: (body) => {
    return axiosPOST("/payment-methods", body);
  },
  togglePaymentWays: (id) => {
    return axiosPATCH(`/payment-methods/${id}`);
  },
  updatePaymentWays: (id, body) => {
    return axiosPUT(`/payment-methods/${id}`, body);
  },
  deletePaymentWays: (id) => {
    return axiosDELETE(`/payment-methods/${id}`);
  },

  // Table Ambiente

  getEnvironmentById: (id) => {
    return axiosGET(`/environment/${id}`);
  },
  getEnvironmentOnReleaseAndFilter: (filter) => {
    return axiosGET("/environment/on-release", filter);
  },
  getEnvironmentActiveAndFilter: (filter) => {
    return axiosGET("/environment/actives", filter);
  },
  getEnvironmentInactiveAndFilter: (filter) => {
    return axiosGET("/environment/inactives", filter);
  },
  getEnvironmentByStore: (id) => {
    return axiosGET(`/store-environment/store/${id}`, id);
  },
  getEnvironmentByAnnounce: (id) => {
    return axiosGET(`/announce-environment/announce/${id}`, id);
  },
  createEnvironment: (body) => {
    return axiosPOST("/environment", body);
  },
  deleteEnvironment: (id) => {
    return axiosDELETE(`/environment/${id}`);
  },
  updateEnvironment: async (id, body) => {
    return await axiosPATCH(`/environment/${id}`, body);
  },
  updateOneEnvironment: async (id, body) => {
    return await axiosPUT(`/environment/status/${id}`, body);
  },

  //Cities
  getCities: () => {
    return axiosGET("/cities");
  },
  getCitiesByState: (stateId, filter) => {
    return axiosGET(`/cities/find-by-state/${stateId}`, filter);
  },

  //Cities
  getStates: () => {
    return axiosGET("/states/states");
  },

  // Table Estrutura de Classificação
  getClassificationStructure: () => {
    return axiosGET("/classification-structure");
  },
  getClassificationStructureActive: (filter) => {
    return axiosGET("/classification-structure/actives", filter);
  },
  getClassificationStructureInactive: (filter) => {
    return axiosGET("/classification-structure/inactives", filter);
  },
  getClassificationStructureGroupers: () => {
    return axiosGET("/classification-structure/find-groupers");
  },
  getClassificationStructureById: (id) => {
    return axiosGET(`/classification-structure/${id}`);
  },
  getStoreClassificationByStore: (id) => {
    return axiosGET(`/store-classification/store/${id}`);
  },
  getAnnounceClassificationByAnnounce: (id) => {
    return axiosGET(`/announce-classification/announce/${id}`);
  },
  getClassificationStructureByLevel: (level) => {
    return axiosGET(`/classification-structure/find-by-level/${level}`);
  },
  createClassificationStructure: (body) => {
    return axiosPOST("/classification-structure", body);
  },
  toggleClassificationStructure: (id) => {
    return axiosPATCH(`/classification-structure/${id}`);
  },
  activateClassificationStructure: (id) => {
    return axiosPATCH(`/classification-structure/activate/${id}`);
  },
  inactivateClassificationStructure: (id) => {
    return axiosPATCH(`/classification-structure/inactivate/${id}`);
  },
  updateClassificationStructure: (id, body) => {
    return axiosPUT(`/classification-structure/${id}`, body);
  },
  uploadImageClassificationStructure: (id, formData) => {
    return axiosPATCH(`/classification-structure/${id}/image`, formData);
  },
  deleteClassificationStructure: (id) => {
    return axiosDELETE(`/classification-structure/${id}`);
  },
  moveClassification: (classificationId, targetId) => {
    return axiosPUT(
      `/classification-structure/${classificationId}/move/${targetId}`
    );
  },
  shuffleClassificationIndex: (parentId) => {
    return axiosPUT(`/classification-structure/shuffle-by-parent`, {
      parentId,
    });
  },

  // Table Características Complementares
  getComplementaryFeatures: () => {
    return axiosGET("/complementary-feature");
  },
  getComplementaryFeaturesActive: (filter) => {
    return axiosGET("/complementary-feature/actives", filter);
  },
  getComplementaryFeaturesInactive: (filter) => {
    return axiosGET("/complementary-feature/inactives", filter);
  },
  getComplementaryFeaturesById: (id) => {
    return axiosGET(`/complementary-feature/${id}`);
  },
  createComplementaryFeatures: (body) => {
    return axiosPOST("/complementary-feature", body);
  },
  toggleComplementaryFeatures: (id) => {
    return axiosPATCH(`/complementary-feature/${id}`);
  },
  updateComplementaryFeatures: (id, body) => {
    return axiosPUT(`/complementary-feature/${id}`, body);
  },
  deleteComplementaryFeatures: (id) => {
    return axiosDELETE(`/complementary-feature/${id}`);
  },

  // Table Tipo de Loja
  getTypeOfStore: () => {
    return axiosGET("/store-type");
  },
  getTypeOfStoreActive: (filter) => {
    return axiosGET("/store-type/actives", filter);
  },
  getTypeOfStoreInactive: (filter) => {
    return axiosGET("/store-type/inactives", filter);
  },
  getTypeOfStoreById: (id) => {
    return axiosGET(`/store-type/${id}`);
  },
  createTypeOfStore: (body) => {
    return axiosPOST("/store-type", body);
  },
  toggleTypeOfStore: (id) => {
    return axiosPATCH(`/store-type/${id}/inactivate`);
  },
  activeToggleTypeOfStore: (id) => {
    return axiosPATCH(`/store-type/${id}/activate`);
  },
  updateTypeOfStore: (id, body) => {
    return axiosPUT(`/store-type/${id}`, body);
  },
  deleteTypeOfStore: (id) => {
    return axiosDELETE(`/store-type/${id}`);
  },

  // Table Loja
  getStoreByAnnounceId: (id) => {
    return axiosGET(`/store/announces/${id}`);
  },
  getAllStores: () => {
    return axiosGET("/store/adm/banner");
  },
  getStore: () => {
    return axiosGET("/store");
  },
  getStoreFilter: (filter) => {
    return axiosGET("/store/filter", filter);
  },
  getStoreByStatusAndFilter: (status, filter) => {
    const body = filter;
    console.log(
      `${BASE_API + `/store/status/${status}`}${
        _.isEmpty(body) ? "" : "?"
      }${qs.stringify(body)}`
    );
    return axiosGET(`/store/status/${status}`, filter);
  },
  getStoreById: (id) => {
    return axiosGET(`/store/${id}`);
  },
  createStore: (body) => {
    return axiosPOST("/store", body);
  },
  updateStore: (id, body) => {
    return axiosPUT(`/store/${id}`, body);
  },
  updateDeliveryToken: (id, body) => {
    return axiosPUT(`/store/update-delivery-token/${id}`, body);
  },
  deleteStore: (id) => {
    return axiosDELETE(`/store/${id}`);
  },
  onReleaseStore: (id) => {
    return axiosPATCH(`/store/${id}/onRealese`);
  },
  activeStore: (id) => {
    return axiosPATCH(`/store/${id}/activate`);
  },
  pauseStore: (id) => {
    return axiosPATCH(`/store/${id}/pause`);
  },
  removeStore: (id) => {
    return axiosPATCH(`/store/${id}/remove`);
  },

  // Table Announce
  getAnnounce: () => {
    return axiosGET("/announce");
  },
  getAllAnnouncesByStoreId: (id) => {
    return axiosGET(`/announce/stores/${id}`);
  },
  getAnnounceFilter: (filter) => {
    return axiosGET("/announce/filter", filter);
  },

  getAnnounceById: (id) => {
    return axiosGET(`/announce/${id}`);
  },

  getCatalogStoreAnnounceFilterAnnounce: (filter) => {
    return axiosGET("/catalog-store-announce/filter-announce", filter);
  },

  getCatalogStoreAnnounceFilterEmphasis: (filter) => {
    return axiosGET("/catalog-store-announce/filter-emphasis", filter);
  },

  getLooseAnnounceFilterAnnounce: (filter) => {
    return axiosGET("/announce/filter-loose-announce", filter);
  },

  getLooseAnnounceFilterEmphasis: (filter) => {
    return axiosGET("/announce/filter-loose-emphasis", filter);
  },

  // Additional Classification Features
  createAdditionalClassificationFeature: (body) => {
    return axiosPOST(`/additional-classification-features`, body);
  },
  simpleCreateAdditionalClassificationFeature: (body) => {
    return axiosPOST(`/additional-classification-features/simple`, body);
  },
  getRelatedClassification: (id) => {
    return axiosGET(
      `/additional-classification-features/related-classification/${id}`
    );
  },
  unRelatedClassification: (id) => {
    return axiosDELETE(`/additional-classification-features/${id}`);
  },

  // Table Tipo de Anúncio
  getAdTypes: () => {
    return axiosGET("/announce-type");
  },
  getActiveAdTypes: (filter) => {
    return axiosGET("/announce-type/actives", filter);
  },
  getInactiveAdTypes: (filter) => {
    return axiosGET("/announce-type/inactives", filter);
  },
  getAdTypeById: (id) => {
    return axiosGET(`/announce-type/${id}`);
  },
  createAdType: (body) => {
    return axiosPOST("/announce-type", body);
  },
  activateAdType: (id) => {
    return axiosPATCH(`/announce-type/${id}/activate`);
  },
  toggleAdType: (id) => {
    return axiosPATCH(`/announce-type/${id}/inactivate`);
  },
  updateAdType: (id, body) => {
    return axiosPUT(`/announce-type/${id}`, body);
  },
  deleteAdType: (id) => {
    return axiosDELETE(`/announce-type/${id}`);
  },

  // Additional Classification Features
  createAnnounceTypeClassification: (body) => {
    return axiosPOST(`/announce-type-classification`, body);
  },
  getRelatedAnnounceClassification: (id) => {
    return axiosGET(
      `/announce-type-classification/related-classification/${id}`
    );
  },
  getByAnnounceType: (id) => {
    return axiosGET(`/announce-type-classification/announce/${id}`);
  },

  // Store Emphasis
  getHomeEmphasisStore: (filter) => {
    return axiosGET(`/home-emphasis/type/2`, filter);
  },
  getHomeEmphasisAnnuncio: (filter) => {
    return axiosGET(`/home-emphasis/type/1`, filter);
  },
  getHomeEmphasisById: (id) => {
    return axiosGET(`/home-emphasis/${id}`);
  },
  getHomeEmphasisByAnnounce: (id) => {
    return axiosGET(`/home-emphasis/announce/${id}`);
  },
  getHomeEmphasisByStore: (id) => {
    return axiosGET(`/home-emphasis/store/${id}`);
  },
  createHomeEmphasis: (body) => {
    return axiosPOST(`/home-emphasis`, body);
  },

  deleteHomeEmphasis: (id) => {
    return axiosDELETE(`/home-emphasis/${id}`, id);
  },
  unRelateAdClassification: (id) => {
    return axiosDELETE(`/announce-type-classification/classification/${id}`);
  },

  // ACCOUNT
  getAccount: () => {
    return axiosGET("/accounts");
  },
  getAccountByCPF: (cpfDocument) => {
    return axiosGET(`/account-profile/find-by-cpf/${cpfDocument}`);
  },
  getAccountByCNPJ: (cnpjDocument) => {
    return axiosGET(`/account-profile/find-by-cnpj/${cnpjDocument}`);
  },

  // ADDRESS
  getStoreAddressByStore: (storeId) => {
    return axiosGET(`/store-address/store/${storeId}`);
  },

  // SALES ORDER
  getOrders: () => {
    return axiosGET(`/sales-order`);
  },
  getOrdersAndFilter: (status, filter) => {
    return axiosGET(`/sales-order/status/${status}`, filter);
  },
  getOrderById: (id) => {
    return axiosGET(`/sales-order/${id}`);
  },
  getSalesOrderItemsInfo: () => {
    return axiosGET("/sales-order-items/new-orders-items-info");
  },
  getSalesOrderItems: () => {
    return axiosGET("/sales-order-items");
  },
  getSalesOrderItemsByOrder: (id) => {
    return axiosGET(`/sales-order-items/sales-order/${id}`);
  },
  getNumberOfSalesOrdersPerStatus: () => {
    return axiosGET(`/sales-order/status/number-of-items-per-status`);
  },
  cancelOrder: (id, status) => {
    return axiosPUT(`/sales-order/${id}/update-status/${status}`);
  },
  cancelAdminOrder: (id) => {
    return axiosPUT(`/sales-order/${id}/admin-cancel-order`);
  },
  removeOrder: (id) => {
    return axiosDELETE(`/sales-order/${id}`);
  },
  updateOrderStatus: (id, status) => {
    return axiosPUT(`/sales-order/${id}/update-status/${status}`);
  },

  getEnvironmentPromotions: (filter) => {
    return axiosGET("/environment-promotions/promotion-order-limit", filter);
  },

  createEnvironmentPromotions: (body) => {
    return axiosPOST("/environment-promotions", body);
  },

  removeEnvironmentPromotions: (id) => {
    return axiosDELETE(`/environment-promotions/${id}`);
  },

  updateEnvironmentPromotions: (id, body) => {
    return axiosPUT(`/environment-promotions/${id}`, body);
  },

  getEnvironmentPromotionsById: (id) => {
    return axiosGET(`/environment-promotions/${id}`);
  },
  // Promotion Classification
  getRelatedPromotionClassification: (id) => {
    return axiosGET(
      `/environment-promotions-classification/environmentPromotionId/${id}`
    );
  },
  simpleCreatePromotionClassification: (body) => {
    return axiosPOST(`/environment-promotions-classification`, body);
  },
  unRelatedPromotionClassification: (id) => {
    return axiosDELETE(`/environment-promotions-classification/${id}`);
  },

  //Banner
  createBannerClassification: (body) => {
    return axiosPOST(`/banners-classification`, body);
  },
  getRelatedBannerClassification: (id) => {
    return axiosGET(`/banners-classification/banners/${id}`);
  },
  unRelatedBannerClassification: (id) => {
    return axiosDELETE(`/banners-classification/${id}`);
  },
  createBannerEnvironment: (body) => {
    return axiosPOST(`/banners-environment`, body);
  },
  getRelatedBannerEnvironment: (id) => {
    return axiosGET(`/banners-environment/banners/${id}`);
  },
  unRelatedBannerEnvironment: (id) => {
    return axiosDELETE(`/banners-environment/${id}`);
  },


  //analytics
  getOrdersAnalytics: () => {
    return axiosGET("/analytics/orders-analysis");
  },
  getSellersRanking: (body) => {
    return axiosPOST("/analytics/sellers-ranking", body);
  },
  getProductsRanking: (body) => {
    return axiosPOST("/analytics/product-ranking", body);
  },
  getCustomersRanking: (body) => {
    return axiosPOST("/analytics/customers-ranking", body);
  },
  getFrequencyAnalysis: () => {
    return axiosGET(`/analytics/frequency-analysis`);
  },
  getRecencyAnalysis: () => {
    return axiosGET(`/analytics/recency-analysis`);
  },
  getPaymentMethodsRanking: (body) => {
    return axiosPOST("/analytics/payment-methods-ranking", body);
  },
  getWaysOfDeliveringRanking: (body) => {
    return axiosPOST("/analytics/ways-of-delivering-ranking", body);
  },
  getNumberOfOrdersByHoursRange: (body) => {
    return axiosGET("/analytics/orders-by-hours-range");
  },
  getNumberOfOrdersByValue: (body) => {
    return axiosGET("/analytics/orders-by-value");
  },
  getCategoryRanking: (body) => {
    return axiosPOST("/analytics/category-ranking", body);
  },
  getAverageTicket: (body) => {
    return axiosPOST("/analytics/average-ticket", body);
  },

  //banners
  getBanners: (filter) => {
    return axiosGET("/banners", filter);
  },
  getBannerById: (id) => {
    return axiosGET(`/banners/${id}`);
  },
  createBanner: (body) => {
    return axiosPOST("/banners", body);
  },
  uploadImageBanner: (id, formData) => {
    return axiosPATCH(`/banners/${id}/upload`, formData);
  },
  deleteBannerById: (id) => {
    return axiosDELETE(`/banners/${id}`);
  },
  updateBanner: (id, body) => {
    return axiosPATCH(`/banners/${id}`, body);
  },
  reorderBanner: (body) => {
    return axiosPATCH(`/banners/order`, body);
  },
  //banner Grid
  getBannersGrid: (environmentId, classificationId) => {
    return axiosGET(`/banners-grid/environment/${environmentId}/classification/${classificationId}`);
  },
  createBannersGrid: (body) => {
    return axiosPOST("/banners-grid", body);
  },
  updateBannersGrid: (id, body) => {
    return axiosPATCH(`/banners-grid/${id}`, body);
  },
  getPapoomClubs: () => {
    return axiosGET(`/papoom-clubs`);
  },
  cloneBannerGrid: (body) => {
    return axiosPOST("/banners-grid/clone", body);
  },
  checkBannerExists: (position, environmentId, classificationId) => {
    return axiosGET(`/banners-grid/check-exists/${position}/${environmentId}/${classificationId}`);
  }
};

export default API;
