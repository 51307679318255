import React from "react";
import { PaginationContainer } from "./styled";
import backButton from "../../../assets/angle-left-solid.svg";
import forwardButton from "../../../assets/angle-right-solid.svg";
import firstPageButton from "../../../assets/angle-double-left-solid.svg";

const PaginationControls = ({ loading, pagination }) => {
  const { page, length, skip, limit, goForward, goBack, goFirstPage } =
    pagination;

  const isFirstPage = page === 1 && skip === 0;
  const isNextDisabled = length < limit;

  return (
    <PaginationContainer>
      <button
        className={
          isFirstPage || loading ? "edit--button disabled" : "edit--button"
        }
        onClick={goFirstPage}
        disabled={isFirstPage || loading}
      >
        <img
          src={firstPageButton}
          alt="primeira página"
          width={13}
          style={{ opacity: isFirstPage || loading ? 0.3 : 1 }}
        />
      </button>
      <button
        className={
          isFirstPage || loading ? "edit--button disabled" : "edit--button"
        }
        onClick={goBack}
        disabled={isFirstPage || loading}
      >
        <img
          src={backButton}
          alt="voltar"
          width={8}
          style={{ opacity: isFirstPage || loading ? 0.3 : 1 }}
        />
      </button>
      <div>
        Pág. {page} ({length} {length === 1 ? "item" : "itens"})
      </div>
      <button
        className={
          isNextDisabled || loading ? "edit--button disabled" : "edit--button"
        }
        onClick={goForward}
        disabled={isNextDisabled || loading}
      >
        <img
          src={forwardButton}
          alt="próximo"
          width={8}
          style={{ opacity: isNextDisabled || loading ? 0.3 : 1 }}
        />
      </button>
    </PaginationContainer>
  );
};

export default PaginationControls;
