import React from "react";
import { WelcomePage, LogoutButton } from "./styled";

const Page = ({ auth }) => {
  const { loggedUser, logout } = auth;

  return (
    <WelcomePage>
      <h1>
        Bem-vindo(a), {loggedUser ? loggedUser.accountName : "usuário(a)"}!
      </h1>
      <LogoutButton onClick={logout}>Logout</LogoutButton>
    </WelcomePage>
  );
};

export default Page;
