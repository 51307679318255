import React from "react";

import { LoadingPage } from "./styled";

const Loading = () => {
  return (
    <LoadingPage>
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </LoadingPage>
  );
};

export default Loading;
