import React from "react";

import { PageArea } from "./styled";

import ActiveDeleted from "../components/ActiveDeleted";

import SearchFilter from "./SearchFilter";
import ActiveTable from "./ActiveTable";
import EmphasisTable from "./DeletedTable";

const Page = () => {
  return (
    <PageArea>
      <div className="page--area">
        <ActiveDeleted
          searchFilter={SearchFilter}
          activeTable={ActiveTable}
          deletedTable={EmphasisTable}
          route="/store"
          activeTabName="Lojas"
          inactiveTabName="Destaques"
          paginate={true}
          displayAddButton={false}
        />
      </div>
    </PageArea>
  );
};

export default Page;
