import { PageArea } from "./styled";
import RankingCard from "../components/rankingCard";
import { useEffect, useState } from "react";
import API from '../../../utils/api';
import { downloadCsv } from "../../../utils/generate.csv";
import { DownloadButton } from '../components/downloadButton';

const AnalyticsWaysOfDeliveringPage = () => {
    const [ waysOfDeliveringRanking, setWaysOfDeliveringRanking ] = useState([ [] ]);
    const [ indexFilter, setIndexFilter ] = useState(0);
    const CsvData = [];

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const requestBody1 = { "limit": 20 };
        const requestBody2 = {
            "lastMonth": true,
            "limit": 20
        };
        const requestBody3 = {
            "lastWeek": true,
            "limit": 20
        };

        const responseAllTime = await API.getWaysOfDeliveringRanking(requestBody1);
        const responseMonth = await API.getWaysOfDeliveringRanking(requestBody2);
        const responseWeek = await API.getWaysOfDeliveringRanking(requestBody3);

        if (responseAllTime.status !== 201 && responseMonth !== 201 && responseWeek !== 201) {
            //tratar erro
            return;
        }
        const allData = [ responseAllTime.data.body.data, responseMonth.data.body.data, responseWeek.data.body.data ];
        setWaysOfDeliveringRanking(allData);
    };

    const getCsv = () => {
        waysOfDeliveringRanking[ indexFilter ].forEach((element) => {
            CsvData.push({
                "nome": element.wayOfDeliveringId.description,
                "nº de pedidos": element.count
            });
        });
        downloadCsv(CsvData, 'Ranking de Vendedores', true);
    };

    return <PageArea>
        <div className="page-area">
            <div className="page-label">
                <h1>Formas de entrega</h1>
                <DownloadButton onclick={ () => getCsv() } />
            </div>
            <div className="top-buyers-label-section">
                <h1 className="label">Ranking Formas de entrega</h1>
                <div className="dropdown">
                    <select
                        value={ indexFilter }
                        onChange={ (e) => setIndexFilter(parseInt(e.target.value)) }
                    >
                        <option value={ 0 }>Geral</option>
                        <option value={ 1 }>Mensal</option>
                        <option value={ 2 }>Semanal</option>
                    </select>
                </div>
            </div>
            {
                waysOfDeliveringRanking[ indexFilter ].map((key, index) => {
                    return <RankingCard
                        position={ index + 1 }
                        name={ waysOfDeliveringRanking[ indexFilter ][ index ].wayOfDeliveringId.description }
                        ordersQtt={ waysOfDeliveringRanking[ indexFilter ][ index ].count }
                    />;
                })
            }

        </div>
    </PageArea>;
};
export default AnalyticsWaysOfDeliveringPage;