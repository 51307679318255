import React, { useState, useRef } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import API from "../../utils/api";
import { useHistory } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage:
      "url(https://images.unsplash.com/photo-1492551557933-34265f7af79e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80)",
    backgroundSize: "cover",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

async function loginUser(credentials) {
  try {
    const response = await API.login(credentials);
    return response.data.body.data;
  } catch (error) {
    if (!error.response?.status || error.response?.status >= 500) {
      await swal({
        title: "Algo inesperado aconteceu!",
        text: "Por favor tente novamente mais tarde.",
        icon: "error",
      });
      return null;
    }

    if (error.response.status === 403) {
      if (error.response.data.body.errors === "exceptions:TOO_MANY_REQUESTS") {
        await swal({
          title: "Número máximo de tentativas de login excedida!",
          text: "Por favor tente novamente mais tarde.",
          icon: "error",
        });
        return null;
      }

      await swal({
        title: "Verificação do captcha falhou!",
        text: "Por favor tente novamente.",
        icon: "error",
      });
      return null;
    }

    await swal({
      title: "E-mail ou password inválidos!",
      text: "Por favor insira credenciais válidas.",
      icon: "error",
    });
  }

  return null;
}

export default function Signin({ auth }) {
  const history = useHistory();
  const recaptchaRef = useRef(null);

  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const { setToken } = auth;

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    const isValid = validateRecaptchaToken();

    if (!isValid) {
      await swal({
        title: "Verificação do captcha falhou!",
        text: "Por favor tente novamente.",
        icon: "error",
      });
      setLoading(false);
      setEmail("");
      setPassword("");
      recaptchaRef.current.reset();
      return;
    }

    if (password.length < 8) {
      await swal({
        title: "Password deve conter um mínimo de 8 caractéres!",
        text: "Por favor corrija o erro e tente novamente.",
        icon: "error",
      });
      setLoading(false);
      setEmail("");
      setPassword("");
      recaptchaRef.current.reset();
      return;
    }

    if (password.length > 40) {
      await swal({
        title: "Password deve conter um máximo de 40 caractéres!",
        text: "Por favor corrija o erro e tente novamente.",
        icon: "error",
      });
      setLoading(false);
      setEmail("");
      setPassword("");
      recaptchaRef.current.reset();
      return;
    }

    const response = await loginUser({
      token: recaptchaToken,
      email,
      password,
    });

    if (!response || !response.accessToken) {
      setLoading(false);
      setEmail("");
      setPassword("");
      recaptchaRef.current.reset();
      return;
    }

    localStorage.setItem("accessToken", JSON.stringify(response.accessToken));

    setToken(response.accessToken);

    history.push("/");

    setLoading(false);
  };

  const validateRecaptchaToken = () => {
    return !!recaptchaToken;
  };

  const onCaptchaChange = (value) => {
    if (value) setRecaptchaToken(value);
    else {
      recaptchaRef.current.reset();
      setRecaptchaToken(null);
    }
  };

  const onCaptchaError = async () => {
    await swal({
      title: "Verificação do captcha falhou!",
      text: "Por favor tente novamente.",
      icon: "error",
    });
  };

  return (
    <Grid container className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} md={7} className={classes.image} />
      <Grid item xs={12} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Fazer Login
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required={true}
              fullWidth
              id="email"
              name="email"
              label="Email Address"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required={true}
              fullWidth
              id="password"
              name="password"
              label="Password"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />

            <div style={{ minHeight: 80 }}>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_GOOGLE_SITE_KEY}
                onChange={onCaptchaChange}
                style={{ marginTop: 20 }}
                onErrored={onCaptchaError}
              />
            </div>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              style={{ cursor: "pointer" }}
              disabled={loading}
            >
              {loading ? "Carregando..." : "Entrar"}
            </Button>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}
