import React from "react";
import { useHistory } from "react-router";
import { formatDateAndTime } from "../../utils/date";

const DeletedTable = ({ data, refreshTable }) => {
  const history = useHistory();

  const handleEditAction = (id) => {
    history.push(`registration/edit/${id}`);
  };

  return (
    <div className="table-responsive">
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Data de Registro</th>
            <th>Imagem</th>
            <th>Nome Fantasia</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{formatDateAndTime(item.createdAt)}</td>
                <td className="profile--image">
                  <img
                    src={item.imageUrl}
                    alt={item.fantasyName + " " + index}
                  />
                </td>
                <td>{item.fantasyName}</td>
                <td>
                  <button
                    className="edit--button"
                    onClick={() => {
                      handleEditAction(item._id);
                    }}
                  >
                    Editar
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default DeletedTable;
