import React, { useEffect, useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";

import { FormArea } from "./styled";
import Api from "../../utils/api";
import swal from "sweetalert";
import { isEmpty } from "lodash";

import backButton from "../../assets/arrow_back.svg";
import API from "../../utils/api";

import LengthCounter from "../components/LengthCounter";

const Edit = () => {
  const history = useHistory();
  const { action, wayId } = useParams();

  const [description, setDescription] = useState("");
  const [status, setStatus] = useState(1);
  const [type, setType] = useState("");
  const [errors, setErrors] = useState({
    description: "",
    type: "",
    status: "",
  });

  useEffect(() => {
    setErrors({ ...errors, name: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description]);

  useEffect(() => {
    setErrors({ ...errors, status: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    setErrors({ ...errors, type: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    const availableActions = ["edit", "create"];

    if (
      !availableActions.includes(action) ||
      (action === "edit" && !wayId) ||
      (action === "create" && wayId)
    ) {
      history.push("/way-of-payment");
      return null;
    }

    if (action === "edit") {
      getwayId(wayId);
    }
  }, [action, wayId, history]);

  const getwayId = async (id) => {
    try {
      const response = await Api.getPaymentWaysById(id);

      if (response.status === 200) {
        const { description, status, type } = response.data.body.data;

        setDescription(description);
        setStatus(status);
        setType(type);
      }
    } catch (error) {
      await swal({
        title: "Algo inesperado aconteceu!",
        text: "Por favor tente novamente mais tarde.",
        icon: "error",
      });
    }
  };

  const validateForm = () => {
    const err = {};

    if (description < 3)
      err.description = "O campo deve possuir pelo menos 3 dígitos";
    if (!type) err.type = "O campo é obrigatório";

    if (!isEmpty(err)) {
      setErrors({ ...errors, ...err });
      return false;
    }

    setErrors({
      description: "",
      status: "",
    });
    return true;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const requestBody = {
      status: Number.parseInt(status),
      description: description,
      type: Number.parseInt(type),
    };

    try {
      if (action === "edit") {
        await API.updatePaymentWays(wayId, requestBody);

        await swal({
          title: "Tudo certo!",
          text: "Forma de Pagamento foi atualizada com sucesso!",
          icon: "success",
        });
      } else {
        await Api.createPaymentWays(requestBody);

        await swal({
          title: "Tudo certo!",
          text: "Forma de Pagamento foi criada com sucesso!",
          icon: "success",
        });
      }

      history.push("/way-of-payment");
    } catch (error) {
      if (error.response.status === 409) {
        await swal({
          title: "Forma de Pagamento já existe!",
          text: "Por favor tente outra forma de pagamento.",
          icon: "error",
        });
      } else {
        await swal({
          title: "Algo inesperado aconteceu!",
          text: "Por favor tente novamente mais tarde.",
          icon: "error",
        });
      }
    }
  };

  return (
    <FormArea>
      <div className="form--area">
        <div className="form--header">
          <Link to="/way-of-payment">
            <img src={backButton} alt="Voltar" />
          </Link>

          <h1>
            {action === "edit"
              ? "Editar Forma de Pagamento"
              : "Adicionar Forma de Pagamento"}
          </h1>
        </div>
        <form onSubmit={handleFormSubmit}>
          <label htmlFor="description">Descrição:</label>
          <input
            type="text"
            id="description"
            value={description}
            maxLength={50}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            required={true}
          />

          <LengthCounter
            props={{
              field: description,
              maxLength: 50,
            }}
          />

          {errors.description !== "" && (
            <div className="form--error">{errors.description}</div>
          )}

          <label htmlFor="type">Tipo:</label>
          <select
            id="type"
            value={type}
            onChange={(e) => setType(e.target.value)}
          >
            <option value="" disabled defaultValue>
              Selecione um tipo
            </option>
            <option value={1}>Interna</option>
            <option value={2}>Externa</option>
            <option value={3}>Ambas</option>
          </select>
          {errors.type !== "" && (
            <div className="form--error">{errors.type}</div>
          )}

          <button type="submit">Salvar</button>
        </form>
      </div>
    </FormArea>
  );
};

export default Edit;
