import styled from "styled-components";

export const PaginationContainer = styled.div`
  width: 100%;
  height: 40px;

  margin-top: 10px;

  display: flex;
  justify-content: end;
  align-items: center;

  color: #757575;

  * {
    margin: 0 5px;
  }

  .edit--button {
    color: #fff;
    background-color: #35a69b;
    border-color: gray;

    border-radius: 5px;
    padding: 5px;

    transition: ease-in;
    transition-duration: 0.1s;

    &:hover {
      cursor: pointer;
      background-color: transparent;
      color: #757575;
    }

    &:disabled {
      background: transparent;
      color: #757575;
      border: solid 2px #e4e4e4;
    }
  }

  .disabled {
    &:hover {
      cursor: not-allowed;
    }
  }
`;
