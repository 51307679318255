import styled from "styled-components";

export const PageArea = styled.div`
    
    .page-area {
        padding: 24px 24px 0 34px;
        background: #F7F8FC;
        min-height: 100vh;

        .page-label {
            display: flex;
            align-items: center;

            h1 {
                color: #363740;
                font-size: 20px;
                margin-right: 15px;
                font-family: 'Mulish', sans-serif;
            }
        }

        .top-buyers-label-section {
            display: flex;
            align-items: flex-end;
            margin-bottom: 20px;

            .dropdown {
                margin-left: 20px;
                box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
            }

            select {
                width: 280px;
                height: 55px;
                background: white   
                
                border-radius: 8px; 
                border: none;
                color: gray;
                font-size: 16px;
                padding: 8px;
            }    
        }

        .label {
            color: #363740;
            font-size: 24px;
            margin: 25px 0 15px 15px;
            font-family: 'Mulish', sans-serif;
            font-weight: bold;
        }

        .card-list {
            display: flex;
        }
        
    }
    

`