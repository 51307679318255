import React from "react";
import swal from "sweetalert";
import API from "../../utils/api";
import { formatDateAndTime } from "../../utils/date";
import { formatText } from "../../utils/formatText";

const DeletedTable = ({ data, refreshTable }) => {
  const handleDeleteAction = async (id) => {
    if (await confirmModal()) {
      try {
        const responseEmphasis = await API.getHomeEmphasisByAnnounce(id);
        const emphasisId = responseEmphasis.data.body.data[0];
        const response = await API.deleteHomeEmphasis(emphasisId._id);

        if (response.status === 200) {
          await swal({
            title: "Tudo certo!",
            text: "Anúncio removido dos destaques com sucesso!",
            icon: "success",
          });

          refreshTable();
        } else {
          await swal({
            title: "Ocorreu algo de errado!",
            text: "Ocorreu algum erro ao remover os anúncios dos destaques.",
            icon: "error",
          });
        }
      } catch (error) {
        if (!error.response) {
          await swal({
            title: "Algo inesperado aconteceu!",
            text: "Por favor tente novamente mais tarde.",
            icon: "error",
          });
          return;
        }
        await swal({
          title: "Ocorreu algo de errado!",
          text: error.response.data.message,
          icon: "error",
        });
      }
    }

    refreshTable();
    return;
  };

  const confirmModal = () => {
    const confirm = swal(
      "Tem certeza que deseja remover esse anúncio dos destaques?",
      {
        buttons: ["Cancelar", "Confirmar"],
      }
    ).then((value) => (value ? true : false));

    return confirm;
  };

  return (
    <div className="table-responsive">
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Data de Registro</th>
            <th>Nome</th>
            <th>Descrição</th>
            <th>Valor</th>
            <th>Nome do vendedor</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{formatDateAndTime(item.createdAt)}</td>
                  <td>{formatText(item.title, 30)}</td>
                  <td>
                    {item.description
                      ? formatText(item.description, 20)
                      : "---"}
                  </td>
                  <td>
                    {(item.value / 100).toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </td>
                  <td>
                    {item.accountId
                      ? formatText(item.accountId.accountName, 35)
                      : "Sem loja"}
                  </td>
                  <td>
                    <button
                      className="delete--button"
                      onClick={() => {
                        handleDeleteAction(item._id);
                      }}
                    >
                      Remover Destaque
                    </button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default DeletedTable;
