import React, { useState } from "react";
import { useHistory } from "react-router";
import swal from "sweetalert";
import API from "../../utils/api";
import { formatDateAndTime } from "../../utils/date";
import { Switch } from "@material-ui/core";

import styled from "styled-components";

export const OrderContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
`;

const ActiveTable = ({ data, refreshTable }) => {
  const [hiddenActive, setHiddenActive] = useState(false);

  // const handleEditAction = (id) => {
  //   history.push(`store/edit/${id}`);
  // };

  // eslint-disable-next-line
  const history = useHistory();

  const setTokenModal = (storeId) => {
    const confirm = swal("Digite abaixo o token:", {
      content: "input",
    }).then((token) => {
      if(token === "" || token === null) return
      handleUpdateToken(token, storeId);
    });
    return confirm;
  };

  const handleUpdateToken = async (token, storeId) => {
    try {
      const body = {
        foodyDeliveryToken: token,
      };
      await API.updateDeliveryToken(storeId, body);

      await swal({
        title: "Tudo certo!",
        text: "Estabelecimento marcado como destaque!",
        icon: "success",
      });
    } catch (error) {
      console.log(error)
      await swal({
        title: "Algo inesperado aconteceu!",
        text: "Por favor tente novamente mais tarde.",
        icon: "error",
      });
    }
    refreshTable();
  };

  return (
    <div className="table-responsive">
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Data de Registro</th>
            <th>Nome</th>
            <th>Responsável</th>
            <th>Token</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item, index) => {
              const storeHasToken = item.foodyDeliveryToken != null;
              return (
                <tr key={index}>
                  {hiddenActive && storeHasToken ? null : (
                    <>
                      <td>{index + 1}</td>
                      <td>{formatDateAndTime(item.createdAt)}</td>
                      <td>{item.title}</td>
                      <td>
                        {item?.responsible && !item?.responsible.fullName
                          ? item?.responsible.corporateName
                          : item?.responsible.fullName}
                      </td>
                      <td>
                        <button
                          className={
                            storeHasToken ? "edit--button" : "activate--button" //activate--button
                          }
                          onClick={() => {
                            setTokenModal(item._id);
                          }}
                        >
                          {storeHasToken ? "Editar" : "Adicionar"}
                        </button>
                      </td>
                    </>
                  )}
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default ActiveTable;
