
import { PageArea } from "./styled";
import CardComponent from "../components/card";
import { useEffect, useState } from "react";
import API from '../../../utils/api';
import Card2Component from '../components/card2';
import { downloadCsv } from "../../../utils/generate.csv";
import { DownloadButton } from '../components/downloadButton';
import AverageTicketCardComponent from '../components/averageTicketCard';

const AnalyticsOrdersPage = () => {
    const [ ordersQttData, setOrdersQttData ] = useState({});
    const [ ordersDataFromHoursFilter, setOrdersDataFromHoursFilter ] = useState([]);
    const [ dataFromHoursFilterIndex, setDataFromHoursFilterIndex ] = useState(0);
    const [ ordersDataFromValueFilter, setOrdersDataFromValueFilter ] = useState([]);
    const [ dataFromValueFilterIndex, setDataFromValueFilterIndex ] = useState(0);
    const [
        averageTicketInitialRangeFilter,
        // setAverageTicketInitialRangeFilte
    ] = useState("");
    const [
        averageTicketFinalRangeFilter,
        //  setAverageTicketFinalRangeFilter 
    ] = useState("");
    const [ averageTicket, setAverageTicket ] = useState(0);

    let csvData = [ [], [] ];

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function fetchData() {
        fetchOrdersOverviewInfo();
        getNumberOfOrdersByValue();
        getNumberOfOrdersByHourRange();
        getAverageTicket(true);
    }

    const fetchOrdersOverviewInfo = async () => {
        const response = await API.getOrdersAnalytics();

        if (response.status !== 200) {
            //tratar erro
            return;
        }
        setOrdersQttData(response.data.body.data);
    };

    const getNumberOfOrdersByHourRange = async () => {
        const response = await API.getNumberOfOrdersByHoursRange();

        if (response.status !== 201 && response.status !== 200) {
            //tratar erro
            return;
        }
        setOrdersDataFromHoursFilter(response.data.body.data);
    };

    const getNumberOfOrdersByValue = async () => {
        const response = await API.getNumberOfOrdersByValue();

        if (response.status !== 201 && response.status !== 200) {
            //tratar erro
            return;
        }

        setOrdersDataFromValueFilter(response.data.body.data);
    };

    const getAverageTicket = async (lastMonth) => {
        const requestBody = lastMonth ?
            {
                "lastMonth": true
            } :
            {
                "initialDateRange": averageTicketInitialRangeFilter,
                "finalDateRange": averageTicketFinalRangeFilter
            };

        const response = await API.getAverageTicket(requestBody);

        if (response.status !== 201 && response.status !== 200) {
            //tratar erro
            return;
        }

        setAverageTicket(response.data.body.data);
    };

    const getCsv = () => {
        createCSVData();
        downloadCsv(csvData, 'Estatísticas de pedidos', false);
    };

    const createCSVData = () => {
        ordersQttData.forEach(element => {
            csvData[ 0 ].push(element.label);
            csvData[ 1 ].push(element.value);
        });
        ordersDataFromHoursFilter.forEach(element => {
            csvData[ 0 ].push(element.label);
            csvData[ 1 ].push(element.value);
        });
        ordersDataFromValueFilter.forEach(element => {
            csvData[ 0 ].push(element.label);
            csvData[ 1 ].push(element.value);
        });
        csvData[ 0 ].push('Ticket Médio');
        csvData[ 1 ].push(averageTicket);
    };

    return <PageArea>
        <div className="page-area">
            <div className="page-label">
                <h1 className="">Pedidos</h1>
                <DownloadButton onclick={ () => getCsv() } />
            </div>
            <h1 className="sub-label">Informações Gerais dos Pedidos</h1>
            <div className="card-list">
                {
                    Object.values(ordersQttData).map((orderData, index) =>
                        <CardComponent
                            label={ orderData.label }
                            subInfo=""
                            qtt={ orderData.value } />
                    )
                }
            </div>

            <div className="filter-section">
                <h1 className="sub-label">Pedidos por faixa de horário</h1>
                <div className="dropdown">
                    <select
                        value={ dataFromHoursFilterIndex }
                        onChange={ (e) => setDataFromHoursFilterIndex(e.target.value) }
                    >
                        <option value={ 0 }>00h00 às 06h00</option>
                        <option value={ 1 }>06h às 09h00</option>
                        <option value={ 2 }>9h às 12h00</option>
                        <option value={ 3 }>12h às 14h00</option>
                        <option value={ 4 }>14h00 às 17h00</option>
                        <option value={ 5 }>17h00 às 19h00</option>
                        <option value={ 6 }>19h00 às 21h00</option>
                        <option value={ 7 }>21h00 às 00h00</option>
                    </select>
                </div>
            </div>
            <Card2Component
                label='Pedidos'
                qtt={ ordersDataFromHoursFilter.length === 0 ? 0 : ordersDataFromHoursFilter[ dataFromHoursFilterIndex ].value } />

            <div className="filter-section">
                <h1 className="sub-label">Pedidos por faixa de valor</h1>
                <div className="dropdown">
                    <select
                        value={ dataFromValueFilterIndex }
                        onChange={ (e) => setDataFromValueFilterIndex(parseInt(e.target.value)) }
                    >
                        <option value={ 0 }>R$ 0,00 a R$ 5,00</option>
                        <option value={ 1 }>R$ 5,00 a R$ 10,00</option>
                        <option value={ 2 }>R$ 10,00 a R$ 15,00</option>
                        <option value={ 3 }>R$ 15,00 a R$ 20,00</option>
                        <option value={ 4 }>R$ 20,00 a R$ 25,00</option>
                        <option value={ 5 }>R$ 25,00 a R$ 30,00</option>
                        <option value={ 6 }>Mais de R$ 30,00</option>
                    </select>
                </div>
            </div>
            <Card2Component
                label='Pedidos'
                qtt={ ordersDataFromValueFilter.length === 0 ? 0 : ordersDataFromValueFilter[ dataFromValueFilterIndex ].value } />

            <h1 className="sub-label">Ticket Médio Mensal</h1>
            <AverageTicketCardComponent
                qtt={ averageTicket } />

            {/* <div className="date-filter-wrapper">
                <h1 className="sub-label">Ticket Médio</h1>
                <div className="date-filter-section">
                    <p>Data inicial</p>
                    <input id="date" type="date"></input>
                </div>
                <div className="date-filter-section">
                    <p>Data final</p>
                    <input id="date" type="date"></input>
                </div>
                <button >Calcular</button>
            </div>
            <Card2Component
                label='Pedidos'
                qtt={numberOfOrdersFromValueFilter} /> */}
        </div>
    </PageArea>;
};

export default AnalyticsOrdersPage;