const orderStatus = [
	{
		name: "Novos Pedidos",
		handle: "newOrders",
		status: 1,
	},
	{
		name: "Em Produção",
		handle: "inProduction",
		status: 2,
	},
	{
		name: "Entrega / Retirada",
		handle: "prepared",
		status: 3,
	},
	{
		name: "Concluídos",
		handle: "finished",
		status: 4,
	},
	{
		name: "Avaliados",
		handle: "evaluated",
		status: 5,
	},
	{
		name: "Devolvidos",
		handle: "returned",
		status: 6,
	},
	{
		name: "Cancelados",
		handle: "canceled",
		status: 7,
	},
	{
		name: "Recusados",
		handle: "refused",
		status: 8,
	},
];

export default orderStatus;