import React, { useState } from 'react';

import Select from 'react-select';

const SearchSelect = ({data, placeholder, onChange, value}) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <Select
        className="basic-single"
        classNamePrefix="select"
        isLoading={isLoading}
        isClearable
        placeholder={placeholder}
        isSearchable
        value={value}
        onChange={onChange}
        options={data}
      />
    </>
  );
};

export default SearchSelect;