import React, { useState, useEffect } from "react";
import API from "../../utils/api";

import { SearchFilterArea } from "./styled";

let timer;
let delay = 0;
const DELAY_TIME = 800;

const SearchFilter = ({ setSearchMethod, activeOrDelete, pagination }) => {
  const [description, setDescription] = useState("");
  const [environment, setEnvironment] = useState("");
  const [environmentList, setEnvironmentList] = useState([]);
  const [classification, setClassification] = useState("");
  const [classificationList, setClassificationList] = useState([]);
  const [deviceType, setDeviceType] = useState(0);

  useEffect(() => {
    delay = 0;
  }, [environment, activeOrDelete]);

  useEffect(() => {
    async function fetchData() {
      const response = await API.getEnvironmentActiveAndFilter();
      setEnvironmentList(response.data.body.data);
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const response = await API.getClassificationStructureActive();
      const data = response.data.body.data
      const result  = data.sort((a, b) =>
      a.description > b.description ? 1 : -1,
    );
      setClassificationList(result);
    }
    fetchData();
  }, []);

  useEffect(() => {
    const params = {};

    if (deviceType) params.deviceType = deviceType.toString();
    if (description) params.description = description;
    if (environment) params.environmentId = environment;
    if (classification) params.classificationId = classification;
    if (pagination.skip) params.skip = pagination.skip;
    if (pagination.limit) params.limit = pagination.limit;

    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      setSearchMethod(() => async () => {
        const response = await API.getEnvironmentPromotions(params);

        const data = response.data.body.data;
        return data;
      });
    }, delay);

    delay = DELAY_TIME;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    deviceType,
    description,
    environment,
    classification,
    activeOrDelete,
    pagination.skip,
  ]);

  return (
    <SearchFilterArea>
      Filtrar:
      <div className="search-filter">
        <div className="search-filter__input">
          <input
            type="text"
            placeholder="Pesquisar por descrição"
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
        </div>
        <div className="search-filter__select">
          <select
            id="type"
            value={environment}
            onChange={(e) => setEnvironment(e.target.value)}
          >
            <option value="" defaultValue>
              Selecione a Comunidade
            </option>

            {environmentList.map((e) => (
              <option key={e._id} value={e._id}>
                {e.description}
              </option>
            ))}
          </select>
        </div>
        <div className="search-filter__select">
          <select
            id="type"
            value={classification}
            onChange={(e) => {
              setClassification(e.target.value);
            }}
          >
            <option value="" defaultValue>
              Selecione a Classificação
            </option>

            {classificationList.map((e) => (
              <option key={e._id} value={e._id}>
                {e.description}
              </option>
            ))}
          </select>
        </div>
        <div className="search-filter__select">
          <select
            onChange={(e) => {
              setDeviceType(e.target.value);
            }}
          >
            <option value="0" defaultValue>
              Pesquisar por dispositivo
            </option>
            <option value="1">Todos dispositivos</option>
            <option value="2">Apenas Android</option>
            <option value="3">Apenas IOS</option>
          </select>
        </div>
      </div>
    </SearchFilterArea>
  );
};

export default SearchFilter;
