import React from "react";

import { PageArea } from "./styled";

import ActiveDeletedRelease from "./ActiveDeletedRelease";

import SearchFilter from "./SearchFilter";
import PageTable from "./menu";

const Page = () => {
  return (
    <PageArea>
      <div className="page--area">
        <ActiveDeletedRelease
          searchFilter={SearchFilter}
          newOrdersTable={PageTable}
          inProductionTable={PageTable}
          preparedTable={PageTable}
          finishedTable={PageTable}
          evaluatedTable={PageTable}
          returnedTable={PageTable}
          canceledTable={PageTable}
          refusedTable={PageTable}
          route="/order-management"
          paginate={true}
        />
      </div>
    </PageArea>
  );
};

export default Page;
