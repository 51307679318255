import styled from "styled-components";

export const Image = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;

  img {
    max-width: 50px;
    max-height: 50px;
    border-radius: 30%;
  }
`;

export const PageArea = styled.div`
  .page--area {
    padding: 24px 24px 0 34px;

    .table-responsive {
      overflow-x: auto;
      margin-top: 24px;
      width: 100%;

      .classificationStructure--image {
        display: flex;
        align-items: center;
        justify-content: left;

        img {
          max-width: 50px;
          max-height: 50px;
          border-radius: 30%;
        }
      }
      table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;

        thead {
          font-size: 16px;
          font-weight: 600;
          color: #757575;
          border-bottom: 1px solid #dee2e6;
          padding: 8px;

          th {
            padding: 15px;
            text-align: left;
          }
        }

        tbody {
          font-size: 16px;
          color: #757575;
          border-bottom: 1px solid #dee2e6;
          padding: 8px;

          td {
            padding: 15px;
            text-align: left;

            button {
              &:not(:last-child) {
                margin-right: 10px;
              }

              padding: 10px;
              border: 2px solid transparent;
              border-radius: 10px;
              cursor: pointer;
              transition: all 0.3s ease-in-out 0s;

              &.edit--button {
                color: #fff;
                background-color: #35a69b;
                border-color: gray;

                &:hover {
                  background-color: transparent;
                  color: #757575;
                }
              }

              &.delete--button {
                color: #fff;
                background-color: #ef4937;
                border-color: gray;

                &:hover {
                  background-color: transparent;
                  color: #d9534f;
                }
              }

              &.activate--button {
                color: #fff;
                background-color: #5cb85c;
                border-color: #5cb85c;

                &:hover {
                  background-color: transparent;
                  color: #5cb85c;
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const SearchFilterArea = styled.div`
  .search-filter {
    margin-top: 10px;
    display: flex;

    .search-filter__input,
    .search-filter__select {
      flex: 1;

      &:not(:last-child) {
        margin-right: 20px;
      }

      input {
        width: 100%;
        height: 40px;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 0 10px;
        font-size: 14px;
        box-sizing: border-box;
      }

      select {
        width: 100%;
        height: 40px;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 0 10px;
        font-size: 14px;
        box-sizing: border-box;
        color: #757575;
      }
    }
  }
`;

export const FormArea = styled.div`
  .form--area {
    padding: 24px 24px 0 34px;
    color: #757575;
    font-family: "Montserrat", sans-serif;

    //  Remove arrows from input type number
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type="number"] {
      -moz-appearance: textfield;
    }

    .form--header {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #777;
      padding-bottom: 10px;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background-position: center center;
        background-repeat: no-repeat;
        margin-right: 20px;
        transition: all 0.3s ease-in-out 0s;

        &:hover {
          background-color: #f1f1f1;
        }
      }

      h1 {
        font-size: 24px;
      }
    }

    form {
      margin-top: 20px;
      display: flex;
      flex-direction: column;

      label {
        margin-bottom: 10px;
        font-family: "Montserrat", sans-serif;
      }

      input,
      textarea {
        display: block;
        height: 24px;
        padding: 0.375rem 0.75rem;
        font-size: 0.8rem;
        font-weight: 400;
        line-height: 1.5;
        color: black;
        background-clip: padding-box;
        border: 1px solid gray;
        border-radius: 0.35rem;
        transition: border-color 0.15s ease-in-out 0s,
          box-shadow 0.15s ease-in-out 0s;
        font-family: "Montserrat", sans-serif;
        margin-bottom: 10px;
      }

      textarea {
        height: 8rem;
      }

      select {
        padding: 10px 2px;
        color: black;

        background-clip: padding-box;
        border: 1px solid gray;
        border-radius: 0.35rem;
        transition: border-color 0.15s ease-in-out 0s,
          box-shadow 0.15s ease-in-out 0s;
        font-family: "Montserrat", sans-serif;
        margin-bottom: 20px;
      }

      button {
        margin-top: 10px;
        margin-bottom: 10px;
        height: 45px;
        width: 150px;
        border: 2px solid #35a69b;
        border-radius: 4px;
        font-size: 14px;
        font-family: "Montserrat", sans-serif;
        background-color: #35a69b;
        color: #fff;
        cursor: pointer;
        transition: all 0.3s ease-in-out 0s;
        margin-left: auto;

        &:hover {
          background-color: transparent;
          color: #35a69b;
        }
      }
    }

    .form--error {
      font-size: 16px;
      color: red;
      margin-bottom: 5px;
    }
  }
`;

export const Children = styled.div`
  width: 100%;
  margin-left: ${(props) => (props.level > 1 ? `40px` : "20px")};
`;

export const ClassificationsContainer = styled.div``;

export const ClassificationParent = styled.div`
  opacity: ${(props) => (props.status === 0 ? `0.7` : "1")};
  display: flex;
  justify-content: left;
  padding: 5px 0px 5px 10px;
  margin-left: 0;

  & > div {
    width: 140px;
    margin-right: 50px;
  }

  transition: all 0.1s ease-in;
  border-radius: 10px;

  &:hover {
    background-color: #eeeeee;
  }
`;

export const ClassificationItem = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;

  width: fit-content;

  margin: 5px;
  padding: 5px;

  color: black;

  button {
    &:not(:last-child) {
      margin-right: 10px;
    }

    padding: 10px;
    border: 2px solid transparent;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s ease-in-out 0s;

    &.edit--button {
      color: #fff;
      background-color: #35a69b;
      border-color: gray;

      &:hover {
        background-color: transparent;
        color: #757575;
      }
    }

    &.delete--button {
      color: #fff;
      background-color: #ef4937;
      border-color: gray;

      &:hover {
        background-color: transparent;
        color: #d9534f;
      }
    }

    &.activate--button {
      color: #fff;
      background-color: #5cb85c;
      border-color: #5cb85c;

      &:hover {
        background-color: transparent;
        color: #5cb85c;
      }
    }
  }
`;

export const ClassificationEditContainer = styled.div`
  padding: 15px 0;
`;

export const ClassificationItemEdit = styled.div`
  margin-left: 15px;
`;

export const ClassificationDescriptionContainer = styled.div`
  padding: 5px;
  cursor: pointer;

  display: flex;

  transition: background-color 0.1s ease-in;
  border-radius: 10px;

  &:hover {
    background-color: #eeeeee;
  }

  div {
    margin-right: 5px;
  }
`;

export const Badge = styled.div`
  padding: 10px 15px;
  border-radius: 20px;
  box-shadow: 2px 2px 4px #363836;
  width: fit-content;
  background-color: #5cb85c;
  color: white;

  display: flex;

  img {
    margin-left: 5px;
    color: white;
  }
`;
