import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { Route, Switch, useLocation } from "react-router-dom";
import Signin from "../pages/signin/signin";
import PrivateRoutes from "./PrivateRoutes";

const AdminRouterSwitch = ({ auth }) => {
  const { token, loggedUser } = auth;
  const location = useLocation();

  const [redirectRoute, setRedirectRoute] = useState("/");

  useEffect(() => {
    if (
      location.pathname !== "/login" &&
      location.pathname !== "/registro" &&
      location.pathname !== "/"
    ) {
      setRedirectRoute(location.pathname);
    }
  }, []); // eslint-disable-line

  return (
    <Switch>
      <Route exact path="/login">
        {!(token && loggedUser) ? (
          <Signin auth={auth} />
        ) : (
          <Redirect to={redirectRoute} />
        )}
      </Route>
      <Route exact path="*">
        {token && loggedUser ? (
          <PrivateRoutes auth={auth} />
        ) : (
          <Redirect to="/login" />
        )}
      </Route>
    </Switch>
  );
};

export default AdminRouterSwitch;
