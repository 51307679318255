import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Loading from "../../components/Loading";

import { ActiveDeleted } from "./styled";

const Component = ({
  searchFilter = null,
  activeTable = null,
  deletedTable = null,
  route = "",
}) => {
  const [activeOrDelete] = useState("active");
  const [activeData, setActiveData] = useState();
  const [deletedData, setDeletedData] = useState([]);
  const [searchMethod, setSearchMethod] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    if (searchMethod) {
      const response = await searchMethod();

      if (activeOrDelete === "active") {
        setActiveData(response);
      } else {
        setDeletedData(response);
      }

      setLoading(false);
    }
  };

  const handleFetchData = async () => {
    setLoading(true);
    await fetchData();
    setLoading(false);
  };

  useEffect(() => {
    handleFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchMethod]);

  useEffect(() => {
    setActiveData([]);
    setDeletedData([]);
  }, [activeOrDelete]);

  const SearchFilter = searchFilter;
  const ActiveTable = activeTable;
  const DeletedTable = deletedTable;

  return (
    <ActiveDeleted>
      <Link to={`${route}/create`} className="add-button">
        Adicionar
      </Link>
      <div className="active-deleted-header">
        <div className="sizedBox" />
      </div>
      <div className="search--bar">
        {searchFilter && (
          <SearchFilter
            setSearchMethod={setSearchMethod}
            activeOrDelete={activeOrDelete}
          />
        )}
      </div>
      <div> asdas</div>
      <div className="table--area">
        {loading ? (
          <div className="loading">
            <Loading />
          </div>
        ) : null}
        {activeOrDelete === "active" && activeTable && (
          <ActiveTable data={activeData} refreshTable={fetchData} />
        )}
        {activeOrDelete === "delete" && deletedTable && (
          <DeletedTable data={deletedData} refreshTable={fetchData} />
        )}
      </div>
    </ActiveDeleted>
  );
};

export default Component;
