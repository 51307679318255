import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import API from "../../utils/api";
import ListBannerSection from "./listBannerSection/index";
import EditBannerModal from "./editBannerModal/index";
import EditSectionModal from "./editSectionModal/index";
import SearchSelect from "../../components/SearchSelect";
import { SearchFilterArea} from "./styled";
import CloneSectionModal from "./cloneSectionModal";

const ActiveTable = ({ data, refreshTable }) => {
  const [bannersGrid, setBannersGrid] = useState();
  const [bannerGridId, setBannerGridId] = useState("");

  const [sectionName, setSectionName] = useState("");

  const [selectedSection, setSelectedSection] = useState(null);
  const [editSectionOpen, setEditSectionOpen] = useState(false);
  const [editBannerOpen, setEditBannerOpen] = useState(false);
  const [cloneSectionModalOpen, setcloneSectionModalOpen] = useState(false);

  const [selectedBanner, setSelectedBanner] = useState(null);

  const [selectedEnvironment, setSelectedEnvironment] = useState(null);
  const [allEnvironments, setAllEnvironments] = useState(null);

  const [selectedClassification, setSelectedClassification] = useState(null);
  const [allClassifications, setAllClassifications] = useState(null);

  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    const response = await API.getBannersGrid(selectedEnvironment, selectedClassification);
    setBannersGrid(response.data.body.data.sort(orderFunction));
  };

  const getEnvironments = async () => {
    const response = await API.getEnvironmentActiveAndFilter();
    
    const environmentsFormatted = response.data.body.data.map((enviroment) => ({
      value: enviroment._id,  
      label: `${enviroment.resume} - ${enviroment.description}`,  
    }));
    setAllEnvironments(environmentsFormatted)
    if(environmentsFormatted.length > 0){
      setSelectedEnvironment(environmentsFormatted[0].value)
    }
  }

  const getClassifications = async () => {
    const response = await API.getClassificationStructureByLevel(1)
    const classificationsFormatted = response.data.body.data.map((classification) => ({
      value: classification._id,
      label: classification.description,
    }));
    setAllClassifications(classificationsFormatted)
    const foodClassification = classificationsFormatted.find((classification) => classification.label.toLowerCase() === 'alimentação')
    setSelectedClassification(foodClassification.value || classificationsFormatted[0].value)
  }

  const orderFunction = (a, b) => {
    return a.position - b.position;
  };

  useEffect(() => {
    getEnvironments();
    getClassifications()
  }, []);

  useEffect(() => {
    if(selectedEnvironment && selectedClassification) {
      fetchData();
    }
}, [selectedEnvironment, selectedClassification])

  const moveLeft = (id, list) => {
    const index = list.findIndex((item) => item._id === id);

    let firstBannerId;
    let secondBannerId;

    if (index > 0) {
      const temp = list[index];
      list[index] = list[index - 1];
      list[index - 1] = temp;

      firstBannerId = list[index - 1];
      secondBannerId = list[index];
      // Atualizar as posições carrouselPosition
      const tempOrder = list[index].carrouselPosition;
      list[index].carrouselPosition = list[index - 1].carrouselPosition;
      list[index - 1].carrouselPosition = tempOrder;

      // Atualizar as posições carrouselPosition dos itens restantes
      for (let i = index; i < list.length; i++) {
        list[i].carrouselPosition = i + 1;
      }
    }

    return {
      updatedBanners: list,
      firstBannerId,
      secondBannerId,
    };
  };

  const moveRight = (id, list) => {
    const index = list.findIndex((item) => item._id === id);

    let firstBannerId;
    let secondBannerId;

    if (index < list.length - 1) {
      const temp = list[index];
      list[index] = list[index + 1];
      list[index + 1] = temp;

      firstBannerId = list[index];
      secondBannerId = list[index + 1];
      const tempOrder = list[index].carrouselPosition;
      list[index].carrouselPosition = list[index + 1].carrouselPosition;
      list[index + 1].carrouselPosition = tempOrder;

      for (let i = index; i < list.length; i++) {
        list[i].carrouselPosition = i + 1;
      }
    }

    return {
      updatedBanners: list,
      firstBannerId,
      secondBannerId,
    };
  };

  const handleReorder = async (bannerId, direction) => {
    let firstBanner;
    let secondBanner;

    const updatedBannersGrid = bannersGrid.map((bannerGrid) => {
      if (bannerGrid._id === bannerGridId) {
        if (direction === "right") {
          const result = moveRight(bannerId, bannerGrid.banners);
          firstBanner = result.firstBannerId;
          secondBanner = result.secondBannerId;

          return { ...bannerGrid, banners: result.updatedBanners };
        } else if (direction === "left") {
          const result = moveLeft(bannerId, bannerGrid.banners);
          firstBanner = result.firstBannerId;
          secondBanner = result.secondBannerId;

          return { ...bannerGrid, banners: result.updatedBanners };
        }
      }
      return bannerGrid;
    });

    const body = {
      firstBannerId: firstBanner._id,
      secondBannerId: secondBanner._id,
    };

    setLoading(true);
    await API.reorderBanner(body);
    setBannersGrid(updatedBannersGrid);
    setLoading(false);
  };

  const handleManageConfigurationAction = (sectionName, item) => {
    setSectionName(sectionName);
    setSelectedSection(item);
    setEditSectionOpen(true);
  };

  const handleManageEditAction = (banner, destinationType) => {
    if (banner) {
      setSelectedBanner({
        bannerId: banner._id,
        destinationURL: banner.destinationURL,
        imageUrl: banner.imageUrl,
        destinationType,
      });
    } else {
      setSelectedBanner({
        bannerId: null,
        destinationURL: null,
        imageUrl: null,
        destinationType,
      });
    }
    // setSelectedBanner(null);
    setEditBannerOpen(true);
  };

  const handleCloneSection = async (bannerGrid, sectionName) => {
    setcloneSectionModalOpen(true)
    setBannerGridId(bannerGrid)
    setSectionName(sectionName)
  }

  return (
    <div>
      <CloneSectionModal
        open={cloneSectionModalOpen}
        setOpen={setcloneSectionModalOpen}
        bannerGridId={bannerGridId}
        allEnvironments={allEnvironments}
        currentEnvironment={selectedEnvironment}
        allClassifications={allClassifications}
        currentClassification={selectedClassification}
        sectionName={sectionName}
        fetchData={fetchData}
      />
      <EditSectionModal
        open={editSectionOpen}
        setOpen={setEditSectionOpen}
        sectionName={sectionName}
        selectedSection={selectedSection}
        setSelectedSection={setSelectedSection}
        fetchData={fetchData}
        selectedEnvironment={selectedEnvironment}
        selectedClassification={selectedClassification}
      />
      <EditBannerModal
        open={editBannerOpen}
        setOpen={setEditBannerOpen}
        bannerGridId={bannerGridId}
        selectedBanner={selectedBanner}
        setSelectedBanner={setSelectedBanner}
        fetchData={fetchData}
      />

      <SearchFilterArea>
        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
          <div className="search-filter" style={{marginRight: 30}}>
            <div className="search-filter__select">
              <select
                onChange={(e) => {
                  setSelectedEnvironment(e.target.value);
                }}
              >
                {
                  allEnvironments && allEnvironments.map((env, index) => (
                    <option key={index} value={env.value}>{env.label}</option>
                  ))
                }
              </select>
            </div>
          </div>
          <div className="search-filter">
            <div className="search-filter__select">
              <select
                onChange={(e) => {
                  setSelectedClassification(e.target.value);
                }}
              >
                {
                  allClassifications && allClassifications.map((clasf, index) => (
                    <option key={index} value={clasf.value}>{clasf.label}</option>
                  ))
                }
              </select>
            </div>
          </div>
        </div>
      </SearchFilterArea>    

      {bannersGrid &&
        [...Array(6)].map((_, index) => {
          const item = bannersGrid.find(banner => banner.position === index + 1) || null;
          const sectionName = index < 3 ? `Topo ${index + 1}` : `Meio ${index - 2}`;
          if (item) {
            return (
              <ListBannerSection
                key={index}
                handleManageConfigurationAction={handleManageConfigurationAction}
                handleManageEditAction={handleManageEditAction}
                handleReorder={handleReorder}
                setBannerGridId={setBannerGridId}
                item={item}
                sectionName={sectionName}
                loading={loading}
                fetchData={fetchData}
                handleCloneSection={handleCloneSection}
              />
            );
          } else {
            return (
              <div style={{ marginLeft: 10 }} key={index}>
                <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                  <p style={{ color: "#757575", fontSize: 18, fontWeight: 600 }}>{sectionName}</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: 4,
                    marginTop: 20,
                    marginBottom: 20,
                    alignItems: "center",
                  }}
                >
                  <Button
                    style={{ padding: "10px", marginRight: 10 }}
                    variant="outlined"
                    onClick={() => handleManageConfigurationAction(sectionName, null)}
                  >
                    Configurar
                  </Button>
                </div>
                <hr />
              </div>
            );
          }
        })}
    </div>
  );
};

export default ActiveTable;
