import styled from "styled-components";

export const Loader = styled.div`
  border: 5px solid #f3f3f3;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  border-top: 5px solid #555;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 16px;

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

export const Image = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;

  img {
    max-width: 50px;
    max-height: 50px;
    border-radius: 30%;
  }
`;

export const PageArea = styled.div`
  .page--area {
    padding: 24px 24px 0 34px;

    .table-responsive {
      overflow-x: auto;
      margin-top: 24px;
      width: 100%;

      table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;

        thead {
          font-size: 16px;
          font-weight: 600;
          color: #757575;
          border-bottom: 1px solid #dee2e6;
          padding: 8px;

          th {
            padding: 15px;
            text-align: left;
          }
        }

        tbody {
          font-size: 16px;
          color: #757575;
          border-bottom: 1px solid #dee2e6;
          padding: 8px;

          td {
            padding: 15px;
            text-align: left;

            button {
              &:not(:last-child) {
                margin-right: 10px;
              }

              padding: 10px;
              border: 2px solid transparent;
              border-radius: 10px;
              cursor: pointer;
              transition: all 0.3s ease-in-out 0s;

              &.edit--button {
                color: #fff;
                background-color: #35a69b;
                border-color: gray;

                &:hover {
                  background-color: transparent;
                  color: #757575;
                }
              }

              &.delete--button {
                color: #fff;
                background-color: #ef4937;
                border-color: gray;

                &:hover {
                  background-color: transparent;
                  color: #d9534f;
                }
              }

              &.manage--button {
                color: #fff;
                background-color: #35a69b;
                border-color: gray;

                &:hover {
                  background-color: transparent;
                  color: #757575;
                }
              }

              &.activate--button {
                color: #fff;
                background-color: #5cb85c;
                border-color: #5cb85c;

                &:hover {
                  background-color: transparent;
                  color: #5cb85c;
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const SearchFilterArea = styled.div`
  .search-filter {
    display: flex;
    margin-bottom: 20px;
    width: '100%';
    flex: 1;

    .search-filter__input,
    .search-filter__select {
      flex: 1;

      &:not(:last-child) {
        margin-right: 20px;
      }

      input {
        width: 100%;
        height: 40px;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 0 10px;
        font-size: 14px;
        box-sizing: border-box;
      }

      select {
        width: 100%;
        height: 40px;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 0 10px;
        font-size: 14px;
        box-sizing: border-box;
        color: #757575;
      }
    }
  }
`;

export const FormArea = styled.div`
  .form--area {
    padding: 24px 24px 0 34px;
    color: #757575;
    font-family: "Montserrat", sans-serif;

    //  Remove arrows from input type number
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type="number"] {
      -moz-appearance: textfield;
    }

    .form--header {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #777;
      padding-bottom: 10px;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background-position: center center;
        background-repeat: no-repeat;
        margin-right: 20px;
        transition: all 0.3s ease-in-out 0s;

        &:hover {
          background-color: #f1f1f1;
        }
      }

      h1 {
        font-size: 24px;
      }
    }

    form {
      margin-top: 20px;
      display: flex;
      flex-direction: column;

      label {
        margin-bottom: 10px;
        font-family: "Montserrat", sans-serif;
      }

      input,
      textarea {
        display: block;
        height: 24px;
        padding: 0.375rem 0.75rem;
        font-size: 0.8rem;
        font-weight: 400;
        line-height: 1.5;
        color: black;
        background-clip: padding-box;
        border: 1px solid gray;
        border-radius: 0.35rem;
        transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
        font-family: "Montserrat", sans-serif;
        margin-bottom: 10px;
      }

      .lista-valores {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .item {
          margin-right: 10px;
          margin-bottom: 10px;
          background-color: #f1f1f1;
          padding: 10px;
          border-radius: 10px;
          font-size: 14px;
          font-weight: 600;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      textarea {
        height: 8rem;
      }

      select {
        padding: 10px 2px;
        color: black;

        background-clip: padding-box;
        border: 1px solid gray;
        border-radius: 0.35rem;
        transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
        font-family: "Montserrat", sans-serif;
        margin-bottom: 20px;
      }

      button {
        margin-top: 10px;
        height: 45px;
        width: 150px;
        border: 2px solid #35a69b;
        border-radius: 4px;
        font-size: 14px;
        font-family: "Montserrat", sans-serif;
        background-color: #35a69b;
        color: #fff;
        cursor: pointer;
        transition: all 0.3s ease-in-out 0s;
        margin-left: auto;

        &:hover {
          background-color: transparent;
          color: #35a69b;
        }
      }
    }

    .form--error {
      font-size: 16px;
      color: red;
      margin-bottom: 5px;
    }
  }
`;

export const RelatedClassificationArea = styled.div`
  .page--area {
    padding: 24px 24px 0 34px;
    color: #757575;
    font-family: "Montserrat", sans-serif;

    .header {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #777;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background-position: center center;
        background-repeat: no-repeat;
        margin-right: 20px;
        transition: all 0.3s ease-in-out 0s;

        &:hover {
          background-color: #f1f1f1;
        }
      }

      h1 {
        font-size: 24px;
      }

      .navigation-area {
        margin-left: auto;
        .active-deleted-header {
          display: flex;
          margin-top: 25px;
          border-bottom: 1px solid #ddd;

          .active-deleted-header-title {
            padding: 10px 15px;
            border: 1px solid #757575;
            margin-bottom: -1px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            cursor: pointer;
            transition: all 0.3s ease-in-out 0s;
            color: #757575;

            &:hover {
              border-color: #c9c1c1;
            }

            &.active {
              background-color: #ef4937;
              border-color: #ddd;
              border-bottom-color: rgba(221, 221, 221, 0.3);
              color: #fff;
              border-left: none;
              border-right: none;
            }
          }
        }
      }
    }

    .table-responsive {
      overflow-x: auto;
      margin-top: 24px;
      width: 100%;

      table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;

        thead {
          font-size: 16px;
          font-weight: 600;
          color: #757575;
          border-bottom: 1px solid #dee2e6;
          padding: 8px;

          th {
            padding: 15px;
            text-align: left;
          }
        }

        tbody {
          font-size: 16px;
          color: #757575;
          border-bottom: 1px solid #dee2e6;
          padding: 8px;

          td {
            padding: 15px;
            text-align: left;

            button {
              &:not(:last-child) {
                margin-right: 10px;
              }

              padding: 10px;
              border: 2px solid transparent;
              border-radius: 10px;
              cursor: pointer;
              transition: all 0.3s ease-in-out 0s;

              &.edit--button {
                color: #fff;
                background-color: #35a69b;
                border-color: gray;

                &:hover {
                  background-color: transparent;
                  color: #757575;
                }
              }

              &.delete--button {
                color: #fff;
                background-color: #ef4937;
                border-color: gray;

                &:hover {
                  background-color: transparent;
                  color: #d9534f;
                }
              }

              &.manage--button {
                color: #fff;
                background-color: #35a69b;
                border-color: gray;

                &:hover {
                  background-color: transparent;
                  color: #757575;
                }
              }

              &.activate--button {
                color: #fff;
                background-color: #5cb85c;
                border-color: #5cb85c;

                &:hover {
                  background-color: transparent;
                  color: #5cb85c;
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const BannerImageContainer = styled.div`
  position: relative;
  height: 80px;
  width: 180px;
  margin-bottom: 10px;

  &:hover {
    .overlay {
      opacity: 1;
    }
  }
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 8px;
`;

export const EditButton = styled.span`
  color: white;
  font-size: 15pt;
`;

export const ReorderButton = styled.span`
  color: white;
  font-size: 18pt;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 2px 8px;

  :hover {
    color: #f2c94c;
  }
`;

export const DeleteIcon = styled.img`
  color: white;
  font-size: 20pt;
`;
