import {CardArea} from './styled';

const CardComponent = ({label, subInfo, qtt}) => {
    return <CardArea >
        <div className="card-area">
            <div className='label'>
                {label}
            </div>
            
            <div className='qtt'>
                {qtt}
            </div>
            
        </div>
    </CardArea>
}

export default CardComponent;