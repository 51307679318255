import React, { useState } from "react";
import {
  Image,
  Children,
  ClassificationParent,
  ClassificationItem,
} from "./styled";
import down from "../../assets/chevron-down-solid.svg";
import up from "../../assets/chevron-up-solid.svg";
import swal from "sweetalert";
import API from "../../utils/api";
import styled from "styled-components";

export const SDivButton = styled.div`
  display: contents;
`;

const Classification = ({ props }) => {
  const {
    item,
    previousItem,
    nextItem,
    index,
    openOrCloseSubitems,
    shouldDisplaySubitems,
    handleActivate,
    handleEditAction,
    handleDeleteAction,
    getClassificationsByLevel,
    level,
    refreshTable,
    orderingActive,
  } = props;

  const [loading, setLoading] = useState(false);

  const moveItem = async (e, classificationId, targetId) => {
    e.stopPropagation();

    setLoading(true);

    try {
      const response = await API.moveClassification(classificationId, targetId);

      const success = response.data.body.data?.success || false;

      if (!success) throw new Error("Falha ao mover item");

      refreshTable();
    } catch (error) {
      await swal({
        title: "Ocorreu algo de errado!",
        text: error.response.data.message,
        icon: "error",
      });
    }

    setLoading(false);
  };

  return (
    <ClassificationItem key={index}>
      <ClassificationParent
        onClick={(e) => openOrCloseSubitems(e, item._id)}
        status={item.status}
      >
        <div>
          <img
            src={shouldDisplaySubitems(item._id) ? up : down}
            alt={item.description}
            style={{ width: 10 }}
          ></img>
        </div>
        <div>{item.description}</div>
        <Image>
          <img src={item.imageUrl} alt={item.fullName + " " + index} />
        </Image>
        <SDivButton>
          <button
            className="edit--button"
            onClick={() => {
              handleEditAction(item._id);
            }}
          >
            Editar
          </button>
          {item.status === 0 ? (
            <button
              className="activate--button"
              onClick={() => {
                handleActivate(item._id);
              }}
            >
              Ativar
            </button>
          ) : (
            <button
              className="delete--button"
              onClick={() => {
                handleDeleteAction(item._id);
              }}
            >
              Inativar
            </button>
          )}
        </SDivButton>
        {loading ? (
          <div style={{ color: "red" }}>carregando...</div>
        ) : (
          <div>
            {previousItem && orderingActive && (
              <button
                className="edit--button"
                onClick={(e) => moveItem(e, item._id, previousItem?._id)}
              >
                <img
                  src={up}
                  alt="mover item para cima"
                  style={{ width: 10 }}
                ></img>
              </button>
            )}
            {nextItem && orderingActive && (
              <button
                className="edit--button"
                onClick={(e) => moveItem(e, item._id, nextItem?._id)}
              >
                <img
                  src={down}
                  alt="mover item para baixo"
                  style={{ width: 10 }}
                ></img>
              </button>
            )}
          </div>
        )}
      </ClassificationParent>
      <Children level={item.level}>
        {shouldDisplaySubitems(item._id) &&
          getClassificationsByLevel(item._id, level + 1)}
      </Children>
    </ClassificationItem>
  );
};

export default Classification;
