import React, { useEffect, useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import { FormArea } from "./styled";
import Api from "../../utils/api";
import swal from "sweetalert";
import backButton from "../../assets/arrow_back.svg";
import API from "../../utils/api";
import LengthCounter from "../components/LengthCounter";

const Edit = () => {
  const history = useHistory();
  const { action, storeId } = useParams();
  // eslint-disable-next-line no-unused-vars
  const [status, setStatus] = useState(1);
  const [title, setTitle] = useState("");
  const [resume, setResume] = useState("");
  const [storeTypeId, setStoreTypeId] = useState("");
  const [storeTypeIdOptions, setStoreTypeIdOptions] = useState([]);
  const [responsible, setResponsible] = useState("");
  const [nameResponsible, setNameResponsible] = useState("");
  const [allowsCouponPolicy, setAllowsCouponPolicy] = useState(true);
  const [errors, setErrors] = useState({
    description: "",
    type: "",
    status: "",
  });

  useEffect(() => {
    setErrors({ ...errors, title: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  useEffect(() => {
    setErrors({ ...errors, resume: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resume]);

  useEffect(() => {
    setErrors({ ...errors, storeTypeId: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeTypeId]);

  useEffect(() => {
    const availableActions = ["edit", "create"];

    if (
      !availableActions.includes(action) ||
      (action === "edit" && !storeId) ||
      (action === "create" && storeId)
    ) {
      history.push("/store");
      return null;
    }

    if (action === "edit") {
      getstoreId(storeId);
    }

    getStoreTypeIdOptions();
  }, [action, storeId, history]);

  const getstoreId = async (id) => {
    try {
      const response = await Api.getStoreById(id);

      if (response.status === 200) {
        const { title, resume, responsible, allowsCouponPolicy } =
          response.data.body.data;

        setTitle(title);
        setStatus(resume);
        setResponsible(responsible);
        setAllowsCouponPolicy(allowsCouponPolicy);
      }
    } catch (error) {
      await swal({
        title: "Algo inesperado aconteceu!",
        text: "Por favor tente novamente mais tarde.",
        icon: "error",
      });
    }
  };

  const getStoreTypeIdOptions = async () => {
    try {
      const response = await Api.getTypeOfStoreActive();
      if (response.status === 200) {
        const data = response.data.body.data;

        setStoreTypeIdOptions(data);
      }
    } catch (error) {
      await swal({
        title: "Algo inesperado aconteceu!",
        text: "Por favor tente novamente mais tarde.",
        icon: "error",
      });
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const requestBody = {
      status: 1,
      storeTypeId: storeTypeId,
      responsible: responsible,
      allowsCouponPolicy: allowsCouponPolicy,
      title: title,
      resume: resume,
    };

    try {
      if (action === "edit") {
        await API.updatePaymentWays(storeId, requestBody);

        await swal({
          title: "Tudo certo!",
          text: "Loja foi atualizada com sucesso!",
          icon: "success",
        });
      } else {
        await Api.createStore(requestBody);

        await swal({
          title: "Tudo certo!",
          text: "Loja foi criada com sucesso!",
          icon: "success",
        });
      }

      history.push("/store");
    } catch (error) {
      await swal({
        title: "Algo inesperado aconteceu!",
        text: "Por favor tente novamente mais tarde.",
        icon: "error",
      });
    }
  };

  const handleViewData = async () => {
    swal("Conta de Pessoa Fisíca ou Jurídica?", {
      buttons: {
        cancel: "Voltar",
        Pf: {
          text: "Pessoa Fisíca",
          value: "PF",
        },
        Pj: {
          text: "Pessoa Jurídica",
          value: "PJ",
        },
      },
    }).then(async (value) => {
      switch (value) {
        case "PJ":
          await swal("Digite o número do CNPJ:", {
            content: "input",
          })
            .then(async (value) => {
              const response = await Api.getAccountByCNPJ(value);
              const data = response.data.body.data;

              const responsible = data._id;
              setResponsible(responsible);

              await swal({
                title: "Dados:",
                text: `Nome: ${data.corporateName}
                      \nTipo: ${data.type}
                      \nDocumento: ${data.document}`,
                buttons: {
                  cancel: {
                    text: "Voltar",
                    value: "pf",
                  },
                  ok: {
                    text: "Vincular",
                  },
                },
              });

              const nameResponsible = data.corporateName;
              setNameResponsible(nameResponsible);
            })
            .catch((e) => swal("Conta não encontrada!"));
          break;

        case "PF":
          await swal("Digite o número do CPF:", {
            content: "input",
          })
            .then(async (value) => {
              const response = await Api.getAccountByCPF(value);
              const data = response.data.body.data;

              const responsible = data._id;
              setResponsible(responsible);

              await swal({
                title: "Dados:",
                text: `Nome: ${data.fullName}
                        \nTipo: ${data.type}
                        \nDocumento: ${data.document}`,
                buttons: {
                  cancel: {
                    text: "Voltar",
                    value: "pf",
                  },
                  ok: {
                    text: "Vincular",
                  },
                },
              });

              const nameResponsible = data.fullName;
              setNameResponsible(nameResponsible);
            })
            .catch((e) => swal("Conta não encontrada!"));
          break;
        default:
          swal.close();
      }
    });
  };
  return (
    <FormArea>
      <div className="form--area">
        <div className="form--header">
          <Link to="/store">
            <img src={backButton} alt="Voltar" />
          </Link>

          <h1>
            {action === "edit"
              ? "Editar Estabelecimento"
              : "Adicionar Estabelecimento"}
          </h1>
        </div>
        <form onSubmit={handleFormSubmit}>
          <label htmlFor="description">Título:</label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            required={true}
            maxLength="50"
          />
          <LengthCounter
            props={{
              field: title,
              maxLength: 50,
            }}
          />
          {errors.title !== "" && (
            <div className="form--error">{errors.title}</div>
          )}

          <label htmlFor="resumo">Resumo:</label>
          <textarea
            id="resumo"
            value={resume}
            onChange={(e) => {
              setResume(e.target.value);
            }}
            required={true}
            maxLength="300"
          />
          <LengthCounter
            props={{
              field: resume,
              maxLength: 300,
            }}
          />
          <label htmlFor="type">Permite politica de cupom:</label>
          <select
            id="type"
            value={allowsCouponPolicy}
            onChange={(e) => setAllowsCouponPolicy(e.target.value === "true")}
          >
            <option value="true">Sim</option>
            <option value="false">Não</option>
          </select>
          {errors.allowsCouponPolicy !== "" && (
            <div className="form--error">{errors.allowsCouponPolicy}</div>
          )}

          <label htmlFor="parent_id">Tipo de loja:</label>

          <select
            id="storeTypeOptionsId"
            value={storeTypeId}
            onChange={(e) => {
              setStoreTypeId(e.target.value);
            }}
          >
            {storeTypeIdOptions.map((option, index) => (
              <option key={index} value={option._id}>
                {option.description}
              </option>
            ))}
          </select>

          <label htmlFor="type">Vincular responsável:</label>
          <input
            type="text"
            id="title"
            readOnly
            value={nameResponsible}
            onChange={(e) => {
              setNameResponsible(e.target.value);
            }}
            maxLength="40"
          />
          <button
            type="button"
            onClick={() => {
              handleViewData();
            }}
          >
            {" "}
            Vincular
          </button>

          <button type="submit">Salvar</button>
        </form>
      </div>
    </FormArea>
  );
};

export default Edit;
