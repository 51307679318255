import styled from "styled-components";

export const PageArea = styled.div`
    font-family: 'Mulish', sans-serif;
    .page-area {
        padding: 24px 24px 0 34px;
        background: #F7F8FC;
        min-height: 100vh;

        .page-label {
            display: flex;
            align-items: center;

            h1 {
                color: #363740;
                font-size: 20px;
                margin-right: 15px;
            }
            
        }

        .filter-section {
            display: flex;
            align-items: flex-end;
            margin-bottom: 20px;

            .dropdown {
                margin-left: 20px;
                box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
            }

            select {
                width: 280px;
                height: 55px;
                background: white   
                
                border-radius: 8px; 
                border: none;
                color: gray;
                font-size: 16px;
                padding: 8px;
            } 
        }

        .date-filter-wrapper {
            margin-top: 25px;
            display: flex;
            align-items: center;
            
            .date-filter-section {
                margin: 15px 0 15px 25px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                p {
                    margin-bottom: 5px;
                }

                input {
                    height: 35px;
                    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
                    border: none;
                    color: gray;
                }
            }

            button {
                    margin-left: 40px;
                    padding: 8px 16px;
                    border-radius: 5px;
                    border: 1px solid gray;
                }
            button:hover {
                cursor: pointer;
            }
        }

        .sub-label {
            color: #363740;
            font-size: 24px;
            margin: 25px 0 15px 15px;
            font-weight: bold;
        }

        .card-list {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }
    }
    

`