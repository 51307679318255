import styled from "styled-components";

export const NavBarArea = styled.nav`
  position: fixed;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 224px;
  padding: 0 10px;
  box-sizing: border-box;
  background-color: #fff;
  font-family: "Montserrat", sans-serif;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.2), 0px 3px 3px rgba(0, 0, 0, 0.2);
  z-index: 90;

  @media (max-width: 720px) {
    display: none;
  }

  .navbar-brand {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 10px 10px 10px;
    border-bottom: 1px solid #999;

    img {
      width: auto;
      height: 65px;
    }
  }

  a {
    text-decoration: none;
  }

  .icon {
    height: 15px;
    width: 15px;
    color: gray;
    margin-left: 10px;
  }

  .title {
    color: gray;
  }

  .navbar-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    overflow: auto;

    .analytics-redirect {

      button {
        color:  white;
        background:  #35A69B;
        padding: 12px 48px;
        border: none;
        border-radius: 8px
      }
      button:hover {
        cursor: pointer
      }
    }

    .nav-item {
      width: 100%;
      display: flex;

      &.active {
        .icon,
        .title {
          color: #ef4937;
        }
      }

      a {
        width: 100%;
        display: flex;
        align-items: center;

        .title {
          margin-left: 10px;
          margin-right: 5px;
          font-size: 15px;
        }
      }

      &:not(:first-child) {
        margin-top: 30px;
      }

      &:hover {
        .title,
        .icon {
          color: #ef4937;
        }
      }
    }
  }
`;
