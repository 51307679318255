import React from "react";
import selected from "../../assets/check-circle-regular.svg";
import down from "../../assets/chevron-down-solid.svg";
import up from "../../assets/chevron-up-solid.svg";

import {
  ClassificationItemEdit,
  ClassificationDescriptionContainer,
} from "./styled";

const ClassificationListItem = ({ props }) => {
  const {
    item,
    openOrCloseSubitems,
    shouldDisplaySubitems,
    getClassificationsByLevel,
    parent,
  } = props;

  return (
    <ClassificationItemEdit
      key={item._id}
      onClick={(e) => openOrCloseSubitems(e, item._id)}
    >
      <ClassificationDescriptionContainer
        style={parent === item._id ? { border: "2px solid #5cb85c" } : {}}
      >
        <div>
          <img
            src={shouldDisplaySubitems(item._id) ? up : down}
            alt={item.description}
            style={{ width: 10, marginRight: 10 }}
          ></img>
          <span>{item.description}</span>
        </div>
        {parent === item._id && (
          <img
            src={selected}
            alt="remover classificação"
            style={{ width: 15 }}
          ></img>
        )}
      </ClassificationDescriptionContainer>
      <div style={{ marginLeft: 20 }}>
        {shouldDisplaySubitems(item._id) &&
          getClassificationsByLevel(item._id, item.level + 1)}
      </div>
    </ClassificationItemEdit>
  );
};

export default ClassificationListItem;
