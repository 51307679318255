import React, { useState } from "react";
import { useHistory } from "react-router";
import swal from "sweetalert";
import API from "../../utils/api";
import { ClassificationsContainer } from "./styled";
import Classification from "./Classification";
import { Switch } from "@material-ui/core";

import styled from "styled-components";

export const OrderContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
`;

const ActiveTable = ({ data, refreshTable }) => {
  const history = useHistory();
  const [level] = useState(1);
  const [activeItems, setActiveItems] = useState([]);

  const [orderingActive, setOrderingActive] = useState(false);

  const handleEditAction = (id) => {
    history.push(`classification-structure/edit/${id}`);
  };

  const handleDeleteAction = async (id) => {
    if (await confirmModal("desativar")) {
      try {
        const response = await API.inactivateClassificationStructure(id);

        if (response.status === 200) {
          await swal({
            title: "Tudo certo!",
            text: "Estrutura de Classificação inativada com sucesso!",
            icon: "success",
          });

          refreshTable();
        } else {
          await swal({
            title: "Ocorreu algo de errado!",
            text: "Ocorreu algum erro ao inativar a Estrutura de Classificação",
            icon: "error",
          });
        }
      } catch (error) {
        if (!error.response) {
          await swal({
            title: "Algo inesperado aconteceu!",
            text: "Por favor tente novamente mais tarde.",
            icon: "error",
          });
          return;
        }
        await swal({
          title: "Ocorreu algo de errado!",
          text: error.response.data.message,
          icon: "error",
        });
      }
    }
    return;
  };

  const handleActivate = async (id) => {
    if (await confirmModal("ativar")) {
      try {
        const response = await API.activateClassificationStructure(id);

        if (response.status === 200) {
          await swal({
            title: "Tudo certo!",
            text: "Estrutura de Classificação ativada com sucesso!",
            icon: "success",
          });

          refreshTable();
        } else {
          await swal({
            title: "Ocorreu algo de errado!",
            text: "Ocorreu algum erro ao ativar a Estrutura de Classificação",
            icon: "error",
          });
        }
      } catch (error) {
        console.log(error.response);
        if (!error.response) {
          await swal({
            title: "Algo inesperado aconteceu!",
            text: "Por favor tente novamente mais tarde.",
            icon: "error",
          });
          return;
        }
        if (error.response.data.body.errors === "exceptions:SEGMENT_INACTIVE") {
          await swal({
            title: "Segmento inativo!",
            text: "Ative a classificação Pai, para ativar as filhas.",
            icon: "error",
          });
          return;
        }
        await swal({
          title: "Ocorreu algo de errado!",
          text: error.response.data.message,
          icon: "error",
        });
      }
    }
    return;
  };

  const confirmModal = (action) => {
    const confirm = swal(
      `Tem certeza que deseja ${action} essa Estrutura de Classificação?`,
      {
        buttons: ["Cancelar", "Confirmar"],
      }
    ).then((value) => (value ? true : false));

    return confirm;
  };

  const openOrCloseSubitems = (event, id) => {
    event.stopPropagation();

    if (activeItems.includes(id)) {
      setActiveItems(activeItems.filter((i) => i !== id));
    } else {
      setActiveItems([...activeItems, id]);
    }
  };

  const shouldDisplaySubitems = (id) => activeItems.includes(id);

  const getClassificationsByLevel = (parentId, level) => {
    const children = data.filter(
      (c) => c.parent === parentId && c.level === level
    );

    return children.map((item, index, arr) => (
      <Classification
        key={item._id}
        props={{
          item,
          previousItem: arr[index - 1] || null,
          nextItem: arr[index + 1] || null,
          index,
          openOrCloseSubitems,
          shouldDisplaySubitems,
          handleActivate,
          handleEditAction,
          handleDeleteAction,
          getClassificationsByLevel,
          level,
          refreshTable,
          orderingActive,
        }}
      />
    ));
  };

  return (
    <div>
      <OrderContainer>
        <span style={{ color: "gray" }}>Ordenar</span>
        <Switch
          label={{ inputProps: { "aria-label": "Ordenar itens" } }}
          checked={orderingActive}
          onChange={() => setOrderingActive(!orderingActive)}
        />
      </OrderContainer>

      <ClassificationsContainer>
        {data &&
          data
            .filter((v) => v.level === 1)
            .map((item, index, arr) => (
              <Classification
                key={item._id}
                props={{
                  item,
                  previousItem: arr[index - 1] || null,
                  nextItem: arr[index + 1] || null,
                  index,
                  openOrCloseSubitems,
                  shouldDisplaySubitems,
                  handleActivate,
                  handleEditAction,
                  handleDeleteAction,
                  getClassificationsByLevel,
                  level,
                  refreshTable,
                  orderingActive,
                }}
              />
            ))}
      </ClassificationsContainer>
    </div>
  );
};

export default ActiveTable;
