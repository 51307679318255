import styled from "styled-components";

export const ComponentArea = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: white;
    border: 1px solid #DFE0EB;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 28px;
    margin-bottom: 8px;

    .main-info {
        display: flex;
        align-items: center;

        .ranking-position {
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 700;
            font-size: 40px;
            line-height: 50px;
            text-align: center;
            letter-spacing: 1px;
            color: #252733;
            margin-right: 15px
        }

        .store-image {
            width: 44px;
            height: 44px;
            border-radius: 50%;
            object-fit: cover;
            margin-right: 25px
        }

        .store-name {
            font-family: 'Mulish';
            font-weight: 600;
            font-size: 25px;
            line-height: 30px;

            text-align: center;
            letter-spacing: 0.5px;

            color: #252733;
        }
    }

    .orders-qtt {
        font-family: 'Mulish';
        font-weight: 700;
        font-size: 25px;
        line-height: 31px;
        text-align: center;
        letter-spacing: 1px;

        color: #252733;
    }
`