import React from "react";
import { useHistory } from "react-router";
import swal from "sweetalert";
import API from "../../utils/api";
import { formatDateAndTime } from "../../utils/date";

const ActiveTable = ({ data, refreshTable }) => {
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const handleManageAction = (id) => {
    history.push(`/related-announce-classification/${id}`);
  };
  const handleEditAction = (id) => {
    history.push(`announce-type/edit/${id}`);
  };

  const handleViewData = async (id) => {
    const response = await API.getAdTypeById(id);
    if (response.status === 200) {
      const {
        name,
        resume,
        RequiresSalesTransaction,
        RequiresRegistrationOfTransaction,
        RequiresRegistrationOfConfiguration,
        RequiresRegistrationOfPayment,
        RequiresRegistrationOfDelivery,
        RequiresRegistrationOfRealization,
        RequiresAppointment,
        RequiresOrder,
        RequiresPurchaseForPublication,
        AllowsPromotionRegistration,
        AllowsChat,
        AllowsEvaluation,
        AllowsSingleAdvertisement,
        AllowsChangeOfEnvironment,

        limitImages,
        limitVideos,
        limitComplementaryFeatures,
        limitEnvironmentLinked,
        limitPostByDay,
        limitStoriesByDay,
        limitNormalPromotionsbyMonth,
        limitLightningPromotionbyMonth,
        limitlinkedAnnouncesAccount,
        limitAnnouncebyAnnounceType,
        limitAnnounceLoose,
      } = response.data.body.data;

      swal({
        title: `${name}`,
        text: `   Resumo: ${resume}\n
                            Transação de venda: ${
                              RequiresSalesTransaction ? "Sim" : "Não"
                            }
                            Registro de adicionais na transação: ${
                              RequiresRegistrationOfTransaction ? "Sim" : "Não"
                            }
                            Registro de configurações especiais na transação: ${
                              RequiresRegistrationOfConfiguration
                                ? "Sim"
                                : "Não"
                            }
                            Registro de forma de pagamento: ${
                              RequiresRegistrationOfPayment ? "Sim" : "Não"
                            }
                            Registro de forma de entrega: ${
                              RequiresRegistrationOfDelivery ? "Sim" : "Não"
                            }
                            Registro de forma de realização: ${
                              RequiresRegistrationOfRealization ? "Sim" : "Não"
                            }
                            Requer agendamento: ${
                              RequiresAppointment ? "Sim" : "Não"
                            }
                            Requer encomenda: ${RequiresOrder ? "Sim" : "Não"}
                            Requer Compra na plataforma para publicação: ${
                              RequiresPurchaseForPublication ? "Sim" : "Não"
                            }
                            Permite registro de promoção: ${
                              AllowsPromotionRegistration ? "Sim" : "Não"
                            }
                            Chat de comunicação: ${AllowsChat ? "Sim" : "Não"}
                            Permite avaliação: ${
                              AllowsEvaluation ? "Sim" : "Não"
                            }
                            Permite anúncio avulso: ${
                              AllowsSingleAdvertisement ? "Sim" : "Não"
                            }
                            Permite mudança de ambiente: ${
                              AllowsChangeOfEnvironment ? "Sim" : "Não"
                            }
                            
                            Limite de imagens: ${limitImages}
                            Limite de videos: ${limitVideos}
                            Limite de características complementares: ${limitComplementaryFeatures}
                            Limite de ambientes vínculados: ${limitEnvironmentLinked}
                            Limite de Posts por dia: ${limitPostByDay}
                            Limite de Stories por dia: ${limitStoriesByDay}
                            Limite de promoções normais por mês: ${limitNormalPromotionsbyMonth}
                            Limite de promoções relâmpago por mês: ${limitLightningPromotionbyMonth}
                            Limite de vínculo com outros anúncios da conta: ${limitlinkedAnnouncesAccount}
                            Limite de anúncios por Tipo de Anúncio: ${limitAnnouncebyAnnounceType}
                            Limite de anúncios avulsos (Sem loja vínculada): ${limitAnnounceLoose}
                        `,
        //icon: "success",
      });
    }
  };

  const handleDeleteAction = async (id) => {
    if (await confirmModal()) {
      try {
        const response = await API.toggleAdType(id);

        if (response.status === 200) {
          await swal({
            title: "Tudo certo!",
            text: "Tipo de Anúncio inativado com sucesso!",
            icon: "success",
          });

          refreshTable();
        } else {
          await swal({
            title: "Ocorreu algo de errado!",
            text: "Ocorreu algum erro ao inativar o Tipo de Anúncio",
            icon: "error",
          });
        }
      } catch (error) {
        if (!error.response) {
          await swal({
            title: "Algo inesperado aconteceu!",
            text: "Por favor tente novamente mais tarde.",
            icon: "error",
          });
          return;
        }
        await swal({
          title: "Ocorreu algo de errado!",
          text: error.response.data.message,
          icon: "error",
        });
      }
    }
    return;
  };

  const confirmModal = () => {
    const confirm = swal(
      "Tem certeza que deseja desativar esse Tipo de Anúncio?'",
      {
        buttons: ["Cancelar", "Confirmar"],
      }
    ).then((value) => (value ? true : false));

    return confirm;
  };

  return (
    <div className="table-responsive">
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Data de Registro</th>
            <th>Descrição</th>
            {/* <th>Vincular</th> */}
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{formatDateAndTime(item.createdAt)}</td>
                <td>{item.name}</td>
                <td>
                  <button
                    className="view--button"
                    onClick={() => {
                      handleViewData(item._id);
                    }}
                  >
                    Ver mais
                  </button>
                  <button
                    className="edit--button"
                    onClick={() => {
                      handleEditAction(item._id);
                    }}
                  >
                    Editar
                  </button>
                  <button
                    className="delete--button"
                    onClick={() => {
                      handleDeleteAction(item._id);
                    }}
                  >
                    Inativar
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default ActiveTable;
