import { ExportToCsv } from 'export-to-csv';

export const downloadCsv = (json, filename, useKeysAsHeaders)=> {
    const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true, 
        showTitle: true,
        title: 'Estatísticas de pedidos',
        useTextFile: false,
        useBom: true,
        filename,
        useKeysAsHeaders
      };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(json);
}

