import styled from 'styled-components';

export const PageArea = styled.div`
    .page--area {
        padding: 24px 24px 0 34px;

        .table-responsive {
            overflow-x: auto;
            margin-top: 24px;
            width: 100%;

            table {
                width: 100%;
                border-collapse: collapse;
                border-spacing: 0;

                thead {
                    font-size: 16px;
                    font-weight: 600;
                    color: #757575;
                    border-bottom: 1px solid #dee2e6;
                    padding: 8px;

                    th {
                        padding: 15px;
                        text-align: left;
                    }
                }

                tbody {
                    font-size: 16px;
                    color: #757575;
                    border-bottom: 1px solid #dee2e6;
                    padding: 8px;

                    td {
                        padding: 15px;
                        text-align: left;

                        button {
                            &:not(:last-child) {
                                margin-right: 10px;
                            }

                            padding: 10px;
                            border: 2px solid transparent;
                            border-radius: 10px;
                            cursor: pointer;
                            transition: all 0.3s ease-in-out 0s;
                            
                            &.view--button {
                                color: #fff;
                                background-color: #35a644;
                                border-color: gray;

                                &:hover {
                                    background-color: transparent;
                                    color: #757575;
                                }
                            }

                            &.edit--button {
                                color: #fff;
                                background-color: #35A69B;
                                border-color: gray;

                                &:hover {
                                    background-color: transparent;
                                    color: #757575;
                                }
                            }

                            &.delete--button {
                                color: #fff;
                                background-color: #EF4937;
                                border-color: gray;

                                &:hover {
                                    background-color: transparent;
                                    color: #d9534f;
                                }
                            }

                            &.activate--button {
                                color: #fff;
                                background-color: #5cb85c;
                                border-color: #5cb85c;

                                &:hover {
                                    background-color: transparent;
                                    color: #5cb85c;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const SearchFilterArea = styled.div`
    .search-filter {
        margin-top: 10px;
        display: flex;

        .search-filter__input, .search-filter__select {
            flex: 1;

            &:not(:last-child) {
                margin-right: 20px;
            }

            input {
                width: 100%;
                height: 40px;
                border: 1px solid #ccc;
                border-radius: 4px;
                padding: 0 10px;
                font-size: 14px;
                box-sizing: border-box;
            }

            select {
                width: 100%;
                height: 40px;
                border: 1px solid #ccc;
                border-radius: 4px;
                padding: 0 10px;
                font-size: 14px;
                box-sizing: border-box;
                color: #757575;
            }
        }
    }
`;

export const FormArea = styled.div`

    .form--area {
        padding: 24px 24px 0 34px;
        color: #757575;
        font-family: 'Montserrat', sans-serif;

        //  Remove arrows from input type number 
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        input[type=number] {
            -moz-appearance: textfield;
        }

        .form--header {
            display: flex;
            align-items: center;
            border-bottom: 1px solid #777;
            padding-bottom: 10px;

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 50px;
                width: 50px;
                border-radius: 50%;
                background-position: center center;
                background-repeat: no-repeat;
                margin-right: 20px;
                transition: all 0.3s ease-in-out 0s;

                &:hover {
                    background-color: #f1f1f1;
                }
            }

            h1 {
                font-size: 24px;
            }
        }

        form {
            margin-top: 20px;
            display: flex;
            flex-direction: column;

            label {
                margin-bottom: 10px;
                font-family: 'Montserrat', sans-serif;
            }

            input,textarea {
                display: block;
                height: 24px;
                padding: 0.375rem 0.75rem;
                font-size: 0.8rem;
                font-weight: 400;
                line-height: 1.5;
                color: black;
                background-clip: padding-box;
                border: 1px solid gray;
                border-radius: 0.35rem;
                transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
                font-family: 'Montserrat', sans-serif;
                margin-bottom: 10px;
            }

            /* .input-date {
                width: 150px;
            } */

            textarea{
                height: 8rem;
            }

            

            select {
                padding: 10px 2px;
                color: black;
                
                background-clip: padding-box;
                border: 1px solid gray;
                border-radius: 0.35rem;
                transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
                font-family: 'Montserrat', sans-serif;
                margin-bottom: 20px;
            }

            button {
                margin-top: 10px;
                margin-bottom: 10px;
                height: 45px;
                width: 150px;
                border: 2px solid #35A69B;
                border-radius: 4px;
                font-size: 14px;
                font-family: 'Montserrat', sans-serif;
                background-color: #35A69B;
                color: #fff;
                cursor: pointer;
                transition: all 0.3s ease-in-out 0s;
                margin-left: auto;

                &:hover {
                    background-color: transparent;
                    color: #35A69B;
                }
            }
        }

        .form--error {
            font-size: 16px;
            color: red;
            margin-bottom: 5px;
        }
    }
`;
