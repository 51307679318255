import { PageArea } from "./styled";
import API from '../../../utils/api';
import { useState } from "react";
import { downloadCsv } from "../../../utils/generate.csv";
import ExportsCardComponent from '../components/exportsCard';
import { formatDate, formatDateByMonth, formatTime } from "../../../utils/date";
// import { LocalConvenienceStoreOutlined } from "@material-ui/icons";

const ExportsPage = () => {
    const [ loading1, setLoading1 ] = useState(false);
    const [ loading2, setLoading2 ] = useState(false);
    const [ loading3, setLoading3 ] = useState(false);
    const [ loading4, setLoading4 ] = useState(false);
    let ordersCsvData = [];
    let ordersItemsCsvData = [];
    let refundCsvData = [];
    let usersCsvData = [];

    const getOrdersInfoAndDownloadCsv = async () => {
        setLoading1(true);
        await getOrdersInfo();
        downloadCsv(ordersCsvData, 'pedidos', true);
        setLoading1(false);
    };
    
    const getOrdersInfo = async () => {
        const response = await API.getOrders();
        if (response.status !== 200) {
            return;
        }
        
        const responseData = response.data.body.data;
        const formattedData = responseData.map((order) => {
            let total; 
            if(order.totalPrice) total = order.totalPrice;

            if (order.promotionId) total = (order.totalPrice + order.promotionId.discountValue);

            if(!order.totalPrice) total = 0;

            return {
                "loja_id": order.storeId._id,
                "LojaTitulo": order.storeId.title,
                "RazaoSocial": order.profile.corporateName ?? "",
                "nomeVendedor": order.profile.fullName ?? "",
                "vendedor_ddd": order.storeId.responsible.accountId.dddPhone,
                "vendedor_celular": order.storeId.responsible.accountId.cellPhone,
                "cepVendedor": order.storeAddress[0].accountAddressId.addressId.cep ?? "",
                "cidadeVendedor": order.storeAddress[0].accountAddressId.addressId.city ?? "",
                "complementoVendedor": order.storeAddress[0].accountAddressId.addressId.complement ?? "",
                "bairroVendedor": order.storeAddress[0].accountAddressId.addressId.district ?? "",
                "logradouroVendedor": order.storeAddress[0].accountAddressId.addressId.logradouro ?? "",
                "numeroEnderecoVendedor": order.storeAddress[0].accountAddressId.addressId.number ?? "",
                "email": order.buyer.email,
                "tipo": order.profile.type,
                "pedido_id": order._id,
                "pedidoStatus": convertOrderStatus(order.status),
                "PedidoNumero": order.orderNumber,
                "dataRegistro": formatDate(order.createdAt),
                "mesRegistro": formatDateByMonth(order.createdAt),
                "horaRegistro": formatTime(order.createdAt),
                "desconto": order.promotionId ? order.promotionId.description : '---',
                "valorDesconto": order.promotionId ? String(order.promotionId.discountValue / 100).replace('.', ',') : '0,00',
                "totalPedido": String(total / 100).replace('.', ','),
                "consumidor_id": order.buyer._id,
                "nomeConsumidor": order.buyer.accountName,
                "consumidor_ddd": order.buyer.dddPhone,
                "consumidor_celular": order.buyer.cellPhone,
                "cepConsumidor": order.deliveryAddress.addressId.cep ?? "",
                "cidadeConsumidor": order.deliveryAddress.addressId.city ?? "",
                "complementoConsumidor": order.deliveryAddress.addressId.complement ?? "",
                "bairroConsumidor": order.deliveryAddress.addressId.district ?? "",
                "logradouroConsumidor": order.deliveryAddress.addressId.logradouro ?? "",
                "numeroEnderecoConsumidor": order.deliveryAddress.addressId.number ?? "",
                "formaPgto_id": order.paymentMethodId._id,
                "formaPgto": order.paymentMethodId.description
            };
        });
        ordersCsvData = formattedData;
    };

    const getOrderItemsInfoAndDownloadCsv = async () => {
        setLoading2(true);
        await getOrderItemsInfo();
        downloadCsv(ordersItemsCsvData, 'pedidosItens', true);
        setLoading2(false);
    };

    const getOrderItemsInfo = async () => {
        const response = await API.getSalesOrderItemsInfo();
        if (response.status !== 200) {
            return;
        }

        const responseData = response.data.body.data;

        const formattedData = responseData.filter((order) => {
            if (!order.salesOrderId) {
                return false;
            }
            return true;
        }).map((orderItem) => {   
            // set storeId
            const storeID = orderItem.salesOrderId.storeId && orderItem.salesOrderId.storeId._id
            const storeTitle = orderItem.salesOrderId.storeId && orderItem.salesOrderId.storeId.title
            
            // set seller name
            let nomeVendedor;
            if(orderItem.salesOrderId.storeId.responsible.fullName){
                nomeVendedor = orderItem.salesOrderId.storeId.responsible.fullName
            } else if(orderItem.salesOrderId.storeId.responsible.fantasyName ) {
                nomeVendedor = orderItem.salesOrderId.storeId.responsible.fantasyName
            } 
             else if(orderItem.salesOrderId.storeId.responsible.corporateName) {
                nomeVendedor = orderItem.salesOrderId.storeId.responsible.corporateName
            } else {
                nomeVendedor = ''
            }   
            
            return {
                "pedido_id": orderItem.salesOrderId._id,
                "pedidoNumero": orderItem.salesOrderId.orderNumber,
                "pedidoStatus": convertOrderStatus(orderItem.salesOrderId.status),
                "dataRegistro": formatDate(orderItem.createdAt),
                "mesRegistro": formatDateByMonth(orderItem.createdAt),
                "nomeConsumidor": orderItem.salesOrderId.buyer.accountName,
                "cepConsumidor": orderItem.salesOrderId.deliveryAddress.addressId?.cep ?? "",
                "cidadeConsumidor": orderItem.salesOrderId.deliveryAddress.addressId?.city ?? "",
                "complementoConsumidor": orderItem.salesOrderId.deliveryAddress.addressId?.complement ?? "",
                "bairroConsumidor": orderItem.salesOrderId.deliveryAddress.addressId?.district ?? "",
                "logradouroConsumidor": orderItem.salesOrderId.deliveryAddress.addressId?.logradouro ?? "",
                "numeroEnderecoConsumidor": orderItem.salesOrderId.deliveryAddress.addressId?.number ?? "",
                "consumidor_ddd": orderItem.salesOrderId.buyer.dddPhone,
                "consumidor_celular": orderItem.salesOrderId.buyer.cellPhone,
                "loja_id": String(storeID),
                "loja_nome": String(storeTitle),
                "anuncio_id": orderItem.announceId._id,
                "anuncio": orderItem.announceId.title,
                "valor (R$)": String(orderItem.value / 100).replace('.', ','),
                "qtde": orderItem.amount,
                "nomeVendedor": String(nomeVendedor),
                "vendedor_ddd": orderItem.salesOrderId.storeId.responsible.accountId.dddPhone,
                "vendedor_celular": orderItem.salesOrderId.storeId.responsible.accountId.cellPhone,
                "cepVendedor": orderItem.salesOrderId.storeAddress[0]?.accountAddressId.addressId?.cep ?? "",
                "cidadeVendedor": orderItem.salesOrderId.storeAddress[0]?.accountAddressId.addressId.city ?? "",
                "complementoVendedor": orderItem.salesOrderId.storeAddress[0]?.accountAddressId.addressId.complement ?? "",
                "bairroVendedor": orderItem.salesOrderId.storeAddress[0]?.accountAddressId.addressId.district ?? "",
                "logradouroVendedor": orderItem.salesOrderId.storeAddress[0]?.accountAddressId.addressId.logradouro ?? "",
                "numeroEnderecoVendedor": orderItem.salesOrderId.storeAddress[0]?.accountAddressId.addressId.number ?? "",
            };
        });

        ordersItemsCsvData = formattedData;
    };

    const getRefundInfoAndDownloadCsv = async () => {
        setLoading3(true);
        await getRefundInfo();
        if (refundCsvData.length !== 0) {
            downloadCsv(refundCsvData, 'reembolso', true);
        }
        setLoading3(false);
    };

    const getRefundInfo = async () => {
        const response = await API.getOrders();
        if (response.status !== 200) {
            return;
        }
        const responseData = response.data.body.data;

        const formatedData = responseData.filter((order) => {
            if (!order.promotionId) {
                return false;
            }
            return true;

        }).map((order) => {
            let total; 
            if(order.totalPrice) total = order.totalPrice;

            if (order.promotionId) total = (order.totalPrice + order.promotionId.discountValue);

            if(!order.totalPrice) total = 0;

            return {
                "loja_id": order.storeId._id,
                "LojaTitulo": order.storeId.title,
                "RazaoSocial": order.profile.corporateName ?? "",
                "nomeVendedor": order.profile.fullName ?? "",
                "vendedor_ddd": order.storeId.responsible.accountId.dddPhone,
                "cepVendedor": order.storeAddress[0].accountAddressId.addressId.cep ?? "",
                "vendedor_celular": order.storeId.responsible.accountId.cellPhone,
                "cidadeVendedor": order.storeAddress[0].accountAddressId.addressId.city ?? "",
                "complementoVendedor": order.storeAddress[0].accountAddressId.addressId.complement ?? "",
                "bairroVendedor": order.storeAddress[0].accountAddressId.addressId.district ?? "",
                "logradouroVendedor": order.storeAddress[0].accountAddressId.addressId.logradouro ?? "",
                "numeroEnderecoVendedor": order.storeAddress[0].accountAddressId.addressId.number ?? "",
                "email": order.buyer.email,
                "tipo": order.profile.type,
                "pedido_id": order._id,
                "pedidoStatus": convertOrderStatus(order.status),
                "PedidoNumero": order.orderNumber,
                "dataRegistro": formatDate(order.createdAt),
                "mesRegistro": formatDateByMonth(order.createdAt),
                "horaRegistro": formatTime(order.createdAt),
                "totalPedido": String(total / 100).replace('.', ','),
                "promocao_id": order.promotionId._id,
                "promocao": order.promotionId.description,
                "valor reembolso (R$)": order.promotionId ? String(order.promotionId.discountValue / 100).replace('.', ',') : '0,00',
                "valor final pago": order.totalPrice ? String(order.totalPrice / 100).replace('.', ','): '0,00',
                "consumidor_id": order.buyer._id,
                "nomeConsumidor": order.buyer.accountName,
                "consumidor_ddd": order.buyer.dddPhone,
                "consumidor_celular": order.buyer.cellPhone,
                "cepConsumidor": order.deliveryAddress.addressId.cep ?? "",
                "cidadeConsumidor": order.deliveryAddress.addressId.city ?? "",
                "complementoConsumidor": order.deliveryAddress.addressId.complement ?? "",
                "bairroConsumidor": order.deliveryAddress.addressId.district ?? "",
                "logradouroConsumidor": order.deliveryAddress.addressId.logradouro ?? "",
                "numeroEnderecoConsumidor": order.deliveryAddress.addressId.number ?? "",
            };
        });

        refundCsvData = formatedData;
    };

    const getUsersInfoAndDownloadCsv = async () => {
        setLoading4(true);
        await getUsersInfo();
        downloadCsv(usersCsvData, 'consumidores', true);
        setLoading4(false);
    };

    const getUsersInfo = async () => {
        const response = await API.getAccountProfiles();

        if (response.status !== 200) {
            return;
        }
        const responseData = response.data.body.data;

        const formattedData = responseData.filter((profile) => {
            if (!profile.accountId) {
                return false;
            }
            return true;
        })
            .map((profile) => {
                return {
                    "conta_id": profile.accountId._id,
                    "nome": profile.accountId.accountName,
                    "dddPhone": profile.accountId.dddPhone,
                    "cellPhone": profile.accountId.cellPhone,
                    "email": profile.accountId.email,
                    "criadoEm": formatDate(profile.accountId.createdAt),
                    "perfil_id": profile._id,
                    "tipo": profile.type,
                    "nomeCompleto": profile.fullName ?? "",
                    "dataNascimento": profile.birthdayOrOpening ? formatDate(profile.birthdayOrOpening) : '',
                    "cepConsumidor": profile.accountAddress[0]?.addressId.cep ?? "",
                    "cidadeConsumidor": profile.accountAddress[0]?.addressId.city ?? "",
                    "complementoConsumidor": profile.accountAddress[0]?.addressId.complement ?? "",
                    "bairroConsumidor": profile.accountAddress[0]?.addressId.district ?? "",
                    "logradouroConsumidor": profile.accountAddress[0]?.addressId.logradouro ?? "",
                    "numeroEnderecoConsumidor": profile.accountAddress[0]?.addressId.number ?? "",
                    "razaoSocial": profile.corporateName ?? '',
                    "nomeFantasia": profile.fantasyName ?? '',
                    "possuiLoja": profile.Store.length !== 0 ? "SIM" : "NAO",
                    "loja_id": profile.Store.length !== 0 ? profile.Store[ 0 ]._id : "",
                    "status_loja": profile.Store.length !== 0 ? profile.Store[ 0 ].status : "",
                    "lojaTitulo": profile.Store.length !== 0 ? profile.Store[ 0 ].title : "",
                };
            });
        usersCsvData = formattedData;
    };

    const convertOrderStatus = (status) => {
        switch (status) {
            case (1):
                return 'Encaminhado';
            case (2):
                return 'Em Preparo';
            case (3):
                return 'Preparado';
            case (4):
                return 'Concluído';
            case (5):
                return 'Avaliado';
            case (6):
                return 'Devolvido';
            case (7):
                return 'Cancelado';
            case (8):
                return 'Recusado';
            default:
                return '';
        }
    };

    return <PageArea>
        <div className="page-area">
            <div className="page-label">
                <h1 >Exportadores</h1>

            </div>
            <div className="content-area">
                <ExportsCardComponent
                    label="Informações de Pedidos"
                    onclick={ () => getOrdersInfoAndDownloadCsv() }
                    loading={ loading1 }
                />
                <ExportsCardComponent
                    label="Informações de Itens de Pedidos"
                    onclick={ () => getOrderItemsInfoAndDownloadCsv() }
                    loading={ loading2 }
                />

                <ExportsCardComponent
                    label="Informações de Reembolso"
                    onclick={ () => getRefundInfoAndDownloadCsv() }
                    loading={ loading3 }
                />

                <ExportsCardComponent
                    label="Informações de Consumidores"
                    onclick={ () => getUsersInfoAndDownloadCsv() }
                    loading={ loading4 }
                />
            </div>
        </div>
    </PageArea>;
};

export default ExportsPage;