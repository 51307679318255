import React from "react";
import Page from "./page";

const PageTable = ({ data, refreshTable }) => {
  return (
    <div className="table-responsive">
      <Page data={data} refreshTable={refreshTable} />
    </div>
  );
};

export default PageTable;
