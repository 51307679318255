import React, { useState, useEffect } from "react";
import Loading from "../../components/Loading";
import { ActiveDeleted } from "./styled";
import { defaults } from "../../../utils/pagination";
import PaginationControls from "../../components/Pagination/PaginationControls";

function Tab(props) {
  return (
    <div
      className={
        props.activeData === props.status
          ? "active-deleted-header-title active"
          : "active-deleted-header-title"
      }
      onClick={() => props.handleAction(props.status)}
    >
      <span style={{ fontSize: 14 }}>{props.name} </span>
    </div>
  );
}

const Component = ({
  searchFilter = null,
  onReleaseTable = null,
  activesTable = null,
  closedTable = null,
  pausedTable = null,
  removedTable = null,
  paginate = false,
}) => {
  const [activeDeleteOrRelease, setActiveOrDelete] = useState("onRelease");
  const [onReleaseData, setOnReleaseData] = useState([]);
  const [activesData, setActivesData] = useState([]);
  const [closedData, setClosedData] = useState([]);

  const [pausedData, setPausedData] = useState([]);
  const [removedData, setRemovedData] = useState([]);

  const [searchMethod, setSearchMethod] = useState(null);
  const [loading, setLoading] = useState(false);

  // pagination
  const [page, setPage] = useState(defaults.page);
  const [skip, setSkip] = useState(defaults.skip);
  const [limit, setLimit] = useState(defaults.limit);

  const goForward = () => {
    if (onReleaseData?.length > 0 && onReleaseData.length < limit) return;
    if (activesData?.length > 0 && activesData.length < limit) return;
    if (closedData?.length > 0 && closedData.length < limit) return;
    if (pausedData?.length > 0 && pausedData.length < limit) return;
    if (removedData?.length > 0 && removedData.length < limit) return;
    setLoading(true);
    setSkip(skip + limit);
    setPage(page + 1);
    fetchData();
  };
  const goBack = () => {
    if (skip === 0 || page === 1) return;
    setLoading(true);
    setSkip(skip - limit);
    setPage(page - 1);
    fetchData();
  };
  const goFirstPage = () => {
    if (skip === 0 && page === 1) return;
    setLoading(true);
    setSkip(defaults.skip);
    setPage(defaults.page);
    fetchData();
  };
  const setItemsPerPage = (num) => {
    setPage(defaults.page);
    setSkip(defaults.skip);
    setLimit(num);
    fetchData();
  };

  const toggleTab = (data) => {
    if (data === activeDeleteOrRelease) return;
    setActiveOrDelete(data);

    setPage(defaults.page);
    setSkip(defaults.skip);
    setLimit(defaults.limit);
  };

  const getCurrentTabLength = (action) => {
    if (action === "onRelease") return onReleaseData?.length || 0;
    if (action === "actives") return activesData?.length || 0;
    if (action === "closed") return closedData?.length || 0;
    if (action === "paused") return pausedData?.length || 0;
    if (action === "removed") return removedData?.length || 0;

    return 0;
  };

  const fetchData = async () => {
    if (searchMethod) {
      const response = await searchMethod();

      if (activeDeleteOrRelease === "onRelease") {
        setOnReleaseData(response);
      } else if (activeDeleteOrRelease === "actives") {
        setActivesData(response);
      } else if (activeDeleteOrRelease === "closed") {
        setClosedData(response);
      } else if (activeDeleteOrRelease === "paused") {
        setPausedData(response);
      } else if (activeDeleteOrRelease === "removed") {
        setRemovedData(response);
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFetchData = async () => {
    setLoading(true);
    await fetchData();
    setLoading(false);
  };

  useEffect(() => {
    handleFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchMethod]);

  useEffect(() => {
    setOnReleaseData([]);
    setActivesData([]);
    setClosedData([]);
    setPausedData([]);
    setRemovedData([]);
  }, [activeDeleteOrRelease]);

  const SearchFilter = searchFilter;
  const OnReleaseTable = onReleaseTable;
  const ActivesTable = activesTable;
  const ClosedTable = closedTable;
  const PausedTable = pausedTable;
  const RemovedTable = removedTable;

  return (
    <ActiveDeleted>
      <div className="active-deleted-header">
        <Tab
          status="onRelease"
          name="Em liberação"
          handleAction={(status) => toggleTab(status)}
          activeData={activeDeleteOrRelease}
        />
        <Tab
          status="actives"
          name="Ativos"
          handleAction={(status) => toggleTab(status)}
          activeData={activeDeleteOrRelease}
        />
        <Tab
          status="paused"
          name="Pausados"
          handleAction={(status) => toggleTab(status)}
          activeData={activeDeleteOrRelease}
        />

        <Tab
          status="closed"
          name="Encerrados"
          handleAction={(status) => toggleTab(status)}
          activeData={activeDeleteOrRelease}
        />

        <Tab
          status="removed"
          name="Removidos"
          handleAction={(status) => toggleTab(status)}
          activeData={activeDeleteOrRelease}
        />
      </div>
      <div className="search--bar">
        {searchFilter && (
          <SearchFilter
            setSearchMethod={setSearchMethod}
            activeDeleteOrRelease={activeDeleteOrRelease}
            pagination={{
              skip,
              limit,
            }}
          />
        )}
      </div>

      {paginate && (
        <PaginationControls
          pagination={{
            page,
            length: getCurrentTabLength(activeDeleteOrRelease),
            skip,
            limit,
            goForward,
            goBack,
            goFirstPage,
            setItemsPerPage,
          }}
          loading={loading}
        />
      )}

      <div className="table--area" style={{ marginTop: 10 }}>
        {loading ? (
          <div className="loading">
            <Loading />
          </div>
        ) : null}
        {activeDeleteOrRelease === "onRelease" && onReleaseTable && (
          <OnReleaseTable data={onReleaseData} refreshTable={fetchData} />
        )}
        {activeDeleteOrRelease === "actives" && activesTable && (
          <ActivesTable data={activesData} refreshTable={fetchData} />
        )}
        {activeDeleteOrRelease === "closed" && closedTable && (
          <ClosedTable data={closedData} refreshTable={fetchData} />
        )}

        {activeDeleteOrRelease === "paused" && pausedTable && (
          <PausedTable data={pausedData} refreshTable={fetchData} />
        )}
        {activeDeleteOrRelease === "removed" && removedTable && (
          <RemovedTable data={removedData} refreshTable={fetchData} />
        )}
      </div>

      {paginate && getCurrentTabLength(activeDeleteOrRelease) > 5 && (
        <PaginationControls
          pagination={{
            page,
            length: getCurrentTabLength(activeDeleteOrRelease),
            skip,
            limit,
            goForward,
            goBack,
            goFirstPage,
            setItemsPerPage,
          }}
          loading={loading}
        />
      )}
    </ActiveDeleted>
  );
};

export default Component;
