import styled from "styled-components";

export const WelcomePage = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 40px;
  margin-left: 40px;

  font-size: 1em;

  .page--area {
    padding: 24px;
  }
`;

export const LogoutButton = styled.div`
  color: #fff;
  background-color: #ef4937;
  border-color: gray;

  padding: 10px;
  border: 2px solid transparent;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out 0s;

  &:hover {
    background-color: transparent;
    color: #d9534f;
    border-color: gray;
  }
`;
