import styled from "styled-components";


export const SpinLoading = styled.svg`
  width: 2rem;
  height: 2rem;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  animation: spin 1s linear infinite;

  color: rgb(229 231 235);
  fill: #1e40af;
`;
