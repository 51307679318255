import React, { useEffect, useState, useRef } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import { FormArea } from "./styled";
import Api from "../../utils/api";
import swal from "sweetalert";
import { isEmpty } from "lodash";
import backButton from "../../assets/arrow_back.svg";
import API from "../../utils/api";
import close from "../../assets/times-circle-solid.svg";
import { ClassificationEditContainer, Badge } from "./styled";
import ClassificationListItem from "./ClassificationListItem";
import LengthCounter from "../components/LengthCounter";

const Edit = () => {
  const imageInput = useRef(null);

  const history = useHistory();
  const { action, structureId } = useParams();

  const [classifications, setClassifications] = useState([]);
  const [activeItems, setActiveItems] = useState([]);

  const [description, setDescription] = useState("");
  const [imageUrl, setImageUrl] = useState();
  const [status, setStatus] = useState(1);
  const [resume, setResume] = useState("");
  const [level, setLevel] = useState(1);
  const [parent, setParent] = useState(null);
  const [errors, setErrors] = useState({
    description: "",
    status: "",
    resume: "",
    parent: "",
  });
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    const getClassifications = async () => {
      try {
        const response = await API.getClassificationStructure();

        const data = response.data.body.data;

        setClassifications(data);
      } catch (e) {
        await swal({
          title: "Algo inesperado aconteceu!",
          text: "Por favor tente novamente mais tarde.",
          icon: "error",
        });
      }
    };

    getClassifications();
  }, []);

  useEffect(() => {
    setErrors({ ...errors, name: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description]);

  useEffect(() => {
    setErrors({ ...errors, status: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    setErrors({ ...errors, resume: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resume]);

  useEffect(() => {
    setErrors({ ...errors, parent: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parent]);

  useEffect(() => {
    const availableActions = ["edit", "create"];

    if (
      !availableActions.includes(action) ||
      (action === "edit" && !structureId) ||
      (action === "create" && structureId)
    ) {
      history.push("/classification-structure");
      return null;
    }

    if (action === "edit") {
      getStructureId(structureId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, structureId, history]);

  const handleUploadFile = async (e) => {
    const imageUrl = e.target.files[0];
    setImageUrl(imageUrl);
  };

  const getStructureId = async (id) => {
    try {
      const response = await Api.getClassificationStructureById(id);

      if (response.status === 200) {
        const { description, status, resume, /* urlImagem, */ level, parent } =
          response.data.body.data;

        const parentId = parent || null;

        setDescription(description);
        setStatus(status);
        setResume(resume);
        setLevel(level);
        setParent(parentId);
      }
    } catch (error) {
      await swal({
        title: "Algo inesperado aconteceu!",
        text: "Por favor tente novamente mais tarde.",
        icon: "error",
      });
    }
  };

  const validateForm = () => {
    const err = {};

    if (description.length < 3)
      err.description = "O campo deve possuir pelo menos 3 dígitos";

    if (!isEmpty(err)) {
      setErrors({ ...errors, ...err });
      return false;
    }

    setErrors({
      description: "",
      status: "",
      resume: "",
      parent: "",
    });
    return true;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }
    const requestBody = {
      status: Number(status),
      description,
      resume,
      level,
      parent,
    };

    setSubmitLoading(true);
    try {
      if (action === "edit") {
        const data = new FormData();
        data.append("file", imageUrl);

        const input = imageInput.current;
        if (input.files.length > 0) {
          await Api.uploadImageClassificationStructure(structureId, data);
        }

        await API.updateClassificationStructure(structureId, requestBody);

        await swal({
          title: "Tudo certo!",
          text: "Estrutura de Classificação foi atualizada com sucesso!",
          icon: "success",
        });
      } else {
        const response = await Api.createClassificationStructure(requestBody);
        const { _id } = response.data.body.data;
        const data = new FormData();
        data.append("file", imageUrl);

        await Api.uploadImageClassificationStructure(_id, data);

        await swal({
          title: "Tudo certo!",
          text: "Estrutura de Classificação foi criada com sucesso!",
          icon: "success",
        });
      }

      history.push("/classification-structure");
    } catch (error) {
      if (error.response.status === 406) {
        await swal({
          title: "Descrição já registrada na plataforma!",
          text: "Por favor tente outra descrição.",
          icon: "error",
        });
      } else {
        await swal({
          title: "Algo inesperado aconteceu!",
          text: "Por favor tente novamente mais tarde.",
          icon: "error",
        });
      }
    }
    setSubmitLoading(false);
  };

  // classifications
  const openOrCloseSubitems = (event, id) => {
    event.stopPropagation();

    if (activeItems.includes(id)) {
      setActiveItems(activeItems.filter((i) => i !== id));
      setParent(null);
      setLevel(1);
    } else {
      setActiveItems([...activeItems, id]);
      setParent(id);

      const parent = classifications.find((item) => item._id === id);
      const currentLevel = parent.level || 1;
      setLevel(currentLevel + 1);
    }
  };

  const shouldDisplaySubitems = (id) => activeItems.includes(id);

  const getSelectedParentDescription = (parentId) => {
    const parent = classifications.find((item) => item._id === parentId);

    if (!parent) return "untitled";

    return parent.description;
  };

  const getClassificationsByLevel = (parentId, level) => {
    const children = classifications.filter(
      (c) => c.parent === parentId && c.level === level
    );

    return children.map((item, index) => (
      <ClassificationListItem
        key={item._id}
        props={{
          item,
          index,
          openOrCloseSubitems,
          shouldDisplaySubitems,
          getClassificationsByLevel,
          level,
          parent,
        }}
      />
    ));
  };

  const removeParent = () => {
    setActiveItems([]);
    setParent(null);
    setLevel(1);
  };

  return (
    <FormArea>
      <div className="form--area">
        <div className="form--header">
          <Link to="/classification-structure">
            <img src={backButton} alt="Voltar" />
          </Link>

          <h1>
            {action === "edit"
              ? "Editar Estrutura de Classificação"
              : "Adicionar Estrutura de Classificação"}
          </h1>
        </div>
        <form onSubmit={handleFormSubmit}>
          <label htmlFor="type">Status:</label>
          <select
            id="type"
            value={status}
            onChange={(e) => {
              setStatus(e.target.value);
            }}
          >
            <option value="1">Ativo</option>
            <option value="0">Inativo</option>
          </select>
          {errors.status !== "" && (
            <div className="form--error">{errors.status}</div>
          )}

          <label htmlFor="description">Descrição:</label>
          <input
            type="text"
            id="description"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            required={true}
            maxLength="40"
          />
          <LengthCounter
            props={{
              field: description,
              maxLength: 40,
            }}
          />
          {errors.description !== "" && (
            <div className="form--error">{errors.description}</div>
          )}

          <label htmlFor="resumo">Resumo:</label>
          <textarea
            id="resumo"
            value={resume}
            onChange={(e) => {
              setResume(e.target.value);
            }}
            required={true}
            maxLength="300"
          />
          <LengthCounter
            props={{
              field: resume,
              maxLength: 300,
            }}
          />
          <label htmlFor="parent_id" style={{ marginTop: 10 }}>
            Estrutura de Classificação Pai:
          </label>

          {parent ? (
            <Badge>
              <div>{getSelectedParentDescription(parent)}</div>
              <img
                src={close}
                alt="remover classificação"
                style={{ width: 15 }}
                onClick={removeParent}
              ></img>
            </Badge>
          ) : (
            <div style={{ marginLeft: 15, fontSize: 12 }}>
              nenhuma classificação selecionada
            </div>
          )}

          <ClassificationEditContainer>
            {classifications &&
              classifications
                .filter((v) => v.level === 1)
                .map((item, index) => (
                  <ClassificationListItem
                    key={item._id}
                    props={{
                      item,
                      index,
                      openOrCloseSubitems,
                      shouldDisplaySubitems,
                      getClassificationsByLevel,
                      level,
                      parent,
                    }}
                  />
                ))}
          </ClassificationEditContainer>

          <label className="form-label">Imagem</label>
          <input
            ref={imageInput}
            type="file"
            required={action === "create"}
            onChange={handleUploadFile}
            accept="image/*"
          />

          {submitLoading ? (
            <button disabled={true}>Salvando...</button>
          ) : (
            <button type="submit">Salvar</button>
          )}
        </form>
      </div>
    </FormArea>
  );
};

export default Edit;
