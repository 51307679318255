import React, { useState, useEffect } from "react";

import { ActiveDeleted } from "./styled";
import Loading from "../../components/Loading";
import { defaults } from "../../../utils/pagination";
import PaginationControls from "../../components/Pagination/PaginationControls";

const Component = ({
  searchFilter = null,
  newOrdersTable = null,
  paginate = false,
}) => {
  const [activeDeleteOrRelease] = useState("items");

  const [members, setMembers] = useState([]);

  const [searchMethod, setSearchMethod] = useState(null);
  const [loading, setLoading] = useState(false);

  // pagination
  const [page, setPage] = useState(defaults.page);
  const [skip, setSkip] = useState(defaults.skip);
  const [limit, setLimit] = useState(defaults.limit);

  const goForward = () => {
    if (members?.length > 0 && members.length < limit) return;

    setLoading(true);
    setSkip(skip + limit);
    setPage(page + 1);
    fetchData();
  };

  const goBack = () => {
    if (skip === 0 || page === 1) return;
    setLoading(true);
    setSkip(skip - limit);
    setPage(page - 1);
    fetchData();
  };

  const goFirstPage = () => {
    if (skip === 0 && page === 1) return;
    setLoading(true);
    setSkip(defaults.skip);
    setPage(defaults.page);
    fetchData();
  };

  const setItemsPerPage = (num) => {
    setPage(defaults.page);
    setSkip(defaults.skip);
    setLimit(num);
    fetchData();
  };

  const getCurrentTabLength = (action) => {
    if (action === "items") return members?.length || 0;
    return 0;
  };

  const fetchData = async () => {
    if (searchMethod) {
      const response = await searchMethod();

      if (activeDeleteOrRelease === "items") {
        setMembers(response);
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFetchData = async () => {
    setLoading(true);
    await fetchData();
    setLoading(false);
  };

  useEffect(() => {
    const timer = setInterval(handleFetchData, 30000);
    return () => clearInterval(timer);
  }, [activeDeleteOrRelease, handleFetchData]);

  useEffect(() => {
    handleFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchMethod]);

  useEffect(() => {
    setMembers([]);
  }, [activeDeleteOrRelease]);

  const SearchFilter = searchFilter;
  const NewOrdersTable = newOrdersTable;

  return (
    <ActiveDeleted>
      <div className="search--bar">
        {searchFilter && (
          <SearchFilter
            setSearchMethod={setSearchMethod}
            activeDeleteOrRelease={activeDeleteOrRelease}
            pagination={{
              skip,
              limit,
            }}
          />
        )}
      </div>

      {paginate && (
        <PaginationControls
          pagination={{
            page,
            length: getCurrentTabLength(activeDeleteOrRelease),
            skip,
            limit,
            goForward,
            goBack,
            goFirstPage,
            setItemsPerPage,
          }}
          loading={loading}
        />
      )}

      <div className="table--area" style={{ marginTop: 10 }}>
        {loading ? (
          <div className="loading">
            <Loading />
          </div>
        ) : null}
        {activeDeleteOrRelease === "items" && newOrdersTable && (
          <NewOrdersTable data={members} refreshTable={fetchData} />
        )}
      </div>

      {paginate && getCurrentTabLength(activeDeleteOrRelease) > 5 && (
        <PaginationControls
          pagination={{
            page,
            length: getCurrentTabLength(activeDeleteOrRelease),
            skip,
            limit,
            goForward,
            goBack,
            goFirstPage,
            setItemsPerPage,
          }}
          loading={loading}
        />
      )}
    </ActiveDeleted>
  );
};

export default Component;
