import React from "react";
import { PageArea } from "./styled";
import ActiveDeleted from "../components/ActiveDeleted";
import ActiveTable from "./ActiveTable";
import DeletedTable from "./DeletedTable";
import SearchFilter from "./SearchFilter";

const RegistrationPage = () => {
  return (
    <PageArea>
      <div className="page--area">
        <div className="page--area">
          <ActiveDeleted
            searchFilter={SearchFilter}
            activeTable={ActiveTable}
            deletedTable={DeletedTable}
            route="/registration"
            activeTabName="Pessoa Física"
            inactiveTabName="Pessoa Jurídica"
            paginate={true}
          />
        </div>
      </div>
    </PageArea>
  );
};

export default RegistrationPage;
