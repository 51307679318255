import React, { useEffect, useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";

import { FormArea } from "./styled";
import Api from "../../utils/api";
import swal from "sweetalert";
import { isEmpty } from "lodash";

import backButton from "../../assets/arrow_back.svg";
import API from "../../utils/api";

import LengthCounter from "../components/LengthCounter";
import styled from "styled-components";

export const SDiv = styled.div`
  display: flex;
  /* border: 1px solid red; */
  justify-items: center;
  align-items: center;
  margin-bottom: 0.3rem;
`;

const Edit = () => {
  const history = useHistory();
  const { action, featureId } = useParams();

  const [isWithinTable, setIsWithinTable] = useState(true);

  const [status, setStatus] = useState(1);
  const [label, setLabel] = useState("");
  const [valueType, setValueType] = useState("");
  const [featureGroupId, setFeatureGroupId] = useState("");
  const [valuesList, setValuesList] = useState([]);
  const [featureGroups, setFeatureGroups] = useState([]);
  const [inputValuesList, setInputValuesList] = useState("");
  const [errors, setErrors] = useState({
    status: "",
    label: "",
    valueType: "",
    valuesList: "",
  });

  useEffect(() => {
    const getFeatureGroup = async () => {
      try {
        const response = await API.getFeatureGroupActive();

        const data = response.data.body.data;

        setFeatureGroups(data);
      } catch (e) {
        await swal({
          title: "Algo inesperado aconteceu!",
          text: "Por favor tente novamente mais tarde.",
          icon: "error",
        });
      }
    };

    getFeatureGroup();
  }, []);

  useEffect(() => {
    setErrors({ status: "", ...errors });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    setErrors({ label: "", ...errors });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [label]);

  useEffect(() => {
    setErrors({ valueType: "", ...errors });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueType]);

  useEffect(() => {
    setErrors({ valuesList: "", ...errors });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuesList]);

  useEffect(() => {
    const availableActions = ["edit", "create"];

    if (
      !availableActions.includes(action) ||
      (action === "edit" && !featureId) ||
      (action === "create" && featureId)
    ) {
      history.push("/complementary-features");
      return null;
    }

    if (action === "edit") {
      getFeatureId(featureId);
    }
  }, [action, featureId, history]);

  const getFeatureId = async (id) => {
    try {
      const response = await Api.getComplementaryFeaturesById(id);
      if (response.status === 200) {
        const { label, valueType, valuesList, isWithinTable, featureGroupId } =
          response.data.body.data;
        setFeatureGroupId(featureGroupId);
        setIsWithinTable(isWithinTable);
        setLabel(label);
        setValueType(valueType.toString());

        valuesList && setValuesList(valuesList.split(","));
        valuesList && setInputValuesList(valuesList);
      }
    } catch (error) {
      await swal({
        title: "Algo inesperado aconteceu!",
        text: "Por favor tente novamente mais tarde.",
        icon: "error",
      });
    }
  };

  const formatStringListaValores = (valuesList) => {
    setInputValuesList(valuesList);

    if (!valuesList) {
      setValuesList([]);
      return;
    }

    const valuesListArray = valuesList.split(",");

    if (
      valuesListArray[valuesListArray.length - 1].trim() === "" ||
      valuesListArray[valuesListArray.length - 1].trim() === "," ||
      valuesListArray[valuesListArray.length - 1].trim() === " "
    ) {
      valuesListArray.pop();
      setValuesList(valuesListArray);
      return;
    }

    const formattedValuesList = valuesListArray.map((item) => {
      return item.trim();
    });

    const filteredValuesList = formattedValuesList.filter((item) => {
      return item !== "";
    });

    setValuesList(filteredValuesList);
  };

  const validateForm = () => {
    const err = {};

    if (label < 3) err.label = "O campo deve possuir pelo menos 3 dígitos";
    if (valueType === "") err.valueType = "O campo é obrigatório";
    if (valueType === "4" && valuesList.length === 0)
      err.valuesList =
        "Ao menos um valor deve ser informado para a Lista de Valores";

    if (!isEmpty(err)) {
      setErrors({ ...errors, ...err });
      return false;
    }

    setErrors({
      status: "",
      label: "",
      valueType: "",
      valuesList: "",
    });

    return true;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }
    const requestBody = {
      status,
      label,
      valueType: Number.parseInt(valueType, 10),
      isWithinTable,
      featureGroupId,
    };

    if (valueType === "4") requestBody.valuesList = valuesList?.join() || null;

    try {
      if (action === "edit") {
        await API.updateComplementaryFeatures(featureId, requestBody);

        await swal({
          title: "Tudo certo!",
          text: "Característica Complementar foi atualizada com sucesso!",
          icon: "success",
        });
      } else {
        await Api.createComplementaryFeatures(requestBody);

        await swal({
          title: "Tudo certo!",
          text: "Característica Complementar foi criada com sucesso!",
          icon: "success",
        });
      }

      history.push("/complementary-features");
    } catch (error) {
      if (error.response.status === 406) {
        await swal({
          title: "Rótulo já existe na plataforma!",
          text: "Tente outra Rótulo.",
          icon: "error",
        });
      } else {
        await swal({
          title: "Algo inesperado aconteceu!",
          text: "Por favor tente novamente mais tarde.",
          icon: "error",
        });
      }
    }
  };

  return (
    <FormArea>
      <div className="form--area">
        <div className="form--header">
          <Link to="/complementary-features">
            <img src={backButton} alt="Voltar" />
          </Link>
          <h1>{action === "edit" && "Editar Característica Complementar"}</h1>
          <h1>{action === "create" && "Criar Característica Complementar"}</h1>
          <h1>
            {action === "manage" && "Gerenciar Característica Associadas"}
          </h1>
        </div>
        <form onSubmit={handleFormSubmit}>
          <label htmlFor="type">Status:</label>
          <select
            id="type"
            value={status}
            onChange={(e) => {
              setStatus(e.target.value);
            }}
          >
            <option value="1">Ativo</option>
            <option value="0">Inativo</option>
          </select>
          {errors.status !== "" && (
            <div className="form--error">{errors.status}</div>
          )}

          <label htmlFor="label">Label:</label>
          <input
            type="text"
            id="label"
            value={label}
            onChange={(e) => {
              setLabel(e.target.value);
            }}
            required={true}
            maxLength="40"
          />
          <LengthCounter
            props={{
              field: label,
              maxLength: 40,
            }}
          />
          {errors.label !== "" && (
            <div className="form--error">{errors.label}</div>
          )}
          <>
            <label htmlFor="featureGroup">
              Associe a um grupo de Característica:
            </label>
            <select
              id="featureGroup"
              value={featureGroupId}
              onChange={(e) => {
                setFeatureGroupId(e.target.value);
              }}
            >
              <option value="" defaultValue disabled>
                Selecione um grupo de Característica
              </option>
              {featureGroups.map((e) => (
                <option key={e._id} value={e._id}>
                  {e.description}
                </option>
              ))}
            </select>
          </>
          <>
            <label htmlFor="tipoValor">Tipo de Valor:</label>
            <select
              id="tipoValor"
              value={valueType}
              onChange={(e) => {
                setValueType(e.target.value);
              }}
            >
              <option value="" defaultValue disabled>
                Selecione o tipo de valor
              </option>
              <option value="1">Texto</option>
              <option value="2">Número</option>
              <option value="3">Data/Hora</option>
              <option value="4">Lista de Valores</option>
            </select>
          </>
          {valueType === "4" && (
            <>
              <label htmlFor="listaValores">Lista de Valores:</label>
              {valuesList.length > 0 && valueType === "4" ? (
                <div className="lista-valores">
                  {valuesList.map((item, index) => (
                    <div className="item" key={index}>
                      {item}
                    </div>
                  ))}
                </div>
              ) : (
                "Insira os valores da lista e separe-os por vírgula."
              )}
              <textarea
                type="text"
                id="listaValores"
                value={inputValuesList}
                onChange={(e) => {
                  formatStringListaValores(e.target.value);
                }}
                maxLength="500"
              />
              {errors.valuesList !== "" && (
                <div className="form--error">{errors.valuesList}</div>
              )}
            </>
          )}
          <SDiv>
            <label htmlFor="checkFeatureGroup">Dentro da tabela</label>
          </SDiv>
          <select
            id="type"
            value={isWithinTable}
            onChange={(e) => setIsWithinTable(e.target.value === "true")}
          >
            <option value="true">Sim</option>
            <option value="false">Não</option>
          </select>
          <button
            onClick={(e) => {
              handleFormSubmit(e);
            }}
            type="submit"
          >
            Salvar
          </button>
        </form>
      </div>
    </FormArea>
  );
};

export default Edit;
