import { CardArea } from './styled';

const Card2Component = ({ label, subInfo, qtt }) => {
    return <CardArea >
        <div className="card-area">
            <div className='qtt'>
                {qtt}
            </div>

            <div className='label'>
                {label}
            </div>


        </div>
    </CardArea>
}

export default Card2Component;