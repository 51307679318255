import React, { useEffect } from "react";
import API from "../../utils/api";

import { SearchFilterArea } from "./styled";

let timer;
let delay = 0;
const DELAY_TIME = 800;

const SearchFilter = ({
  setSearchMethod,
  activeDeleteOrRelease,
  pagination,
}) => {
  useEffect(() => {
    delay = 0;
  }, [activeDeleteOrRelease]);

  useEffect(() => {
    let itemStatus = 1;
    if (timer) clearTimeout(timer);

    const queryParams = {};

    if (pagination.skip) queryParams.skip = pagination.skip;
    if (pagination.limit) queryParams.limit = pagination.limit;

    timer = setTimeout(() => {
      setSearchMethod(() => async () => {
        if (activeDeleteOrRelease === "newOrders") {
          itemStatus = 1;
          queryParams.registrationOrder = "asc";
        } else if (activeDeleteOrRelease === "inProduction") {
          itemStatus = 2;
          queryParams.registrationOrder = "asc";
        } else if (activeDeleteOrRelease === "prepared") {
          itemStatus = 3;
          queryParams.registrationOrder = "asc";
        } else if (activeDeleteOrRelease === "finished") itemStatus = 4;
        else if (activeDeleteOrRelease === "evaluated") itemStatus = 5;
        else if (activeDeleteOrRelease === "returned") itemStatus = 6;
        else if (activeDeleteOrRelease === "canceled") itemStatus = 7;
        else if (activeDeleteOrRelease === "refused") itemStatus = 8;

        const response = await API.getOrdersAndFilter(itemStatus, queryParams);
        const data = response.data.body.data;
        return data;
      });
    }, delay);

    delay = DELAY_TIME;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDeleteOrRelease, pagination.skip]);

  return <SearchFilterArea></SearchFilterArea>;
};

export default SearchFilter;
