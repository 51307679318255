import React, { useEffect, useState } from "react";

import { formatToDate } from "../../utils/formatToDate";

const Page = ({ data }) => {
  const [tabStatus, setTabStatus] = useState(null);

  useEffect(() => {
    if (!tabStatus && data && data?.length > 0) {
      setTabStatus(data[0].status);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <table>
      <thead>
        <tr>
          <th>#</th>
          <th>Data de Registro</th>
          <th>Nome</th>
          <th>Email</th>
          <th>Telefone</th>
          <th>Link</th>
          <th>Nº de clicks</th>
          <th>Nº de instalações</th>
        </tr>
      </thead>
      <tbody>
        {data &&
          data.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{formatToDate(item.createdAt)}</td>
              <td>{item.name}</td>
              <td>
                <p style={{ fontSize: "12pt" }}>{item.email}</p>
              </td>
              <td>
                {item.cellphone.replace(
                  /(\d{2})(\d{4,5})(\d{4})/,
                  "($1) $2-$3"
                )}
              </td>
              <td>{item.link}</td>
              <td>{item.numberOfClicks}</td>
              <td>{item.numberOfInstallations}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};
export default Page;
