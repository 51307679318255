import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Snackbar from "@material-ui/core/Snackbar";
import { Button, FormControl, InputLabel, MenuItem, Select, SnackbarContent } from "@material-ui/core";
import API from "../../../utils/api";
import SearchSelect from "../../../components/SearchSelect";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const getModalStyle = () => {
  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  };
};

export const getPositionBySectionName = (sectionName) => {
  switch (sectionName) {
    case "Topo 1":
      return 1;
    case "Topo 2":
      return 2;
    case "Topo 3":
      return 3;
    case "Meio 1":
      return 4;
    case "Meio 2":
      return 5;
    case "Meio 3":
      return 6;
    default:
      break;
  }
};

const EditSectionModal = ({ open, setOpen, sectionName, selectedSection, 
  setSelectedSection, fetchData, selectedEnvironment, selectedClassification }) => {
  const classes = useStyles();

  const [modalStyle] = useState(getModalStyle);
  const [bannerType, setBannerType] = useState("");
  const [destinationType, setDestinationType] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");

  const handleClose = () => {
    setSelectedSection(null);
    setOpen(false);
  };

  useEffect(() => {
    if (selectedSection) {
      setDestinationType(selectedSection.destinationType);
      setBannerType(selectedSection.bannerType);
      setName(selectedSection.label || "");
      
    }
  }, [selectedSection]);

  const onChangeBannerType = (event) => {
    setBannerType(event.target.value);
  };

  const onChangeDestinationType = (event) => {
    setDestinationType(event.target.value);
  };

  const onSubmit = async () => {
    setLoading(true);
    let body = {
      label: name,
      destinationType,
      bannerType,
      environmentId: selectedEnvironment,
      classificationId: selectedClassification
    };

    try {
      if (selectedSection) {
        await API.updateBannersGrid(selectedSection._id, body);
        setResponseMessage(`${sectionName} atualizado com sucesso!`);
        setOpenSnackBar(true);
      } else {
        body = { ...body, position: getPositionBySectionName(sectionName) };
        await API.createBannersGrid(body);
      }
    } catch (error) {
      setResponseMessage("Ocorreu um erro ao atualizar!");
    }

    await fetchData();
    handleClose();
    setLoading(false);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") return;

    setOpenSnackBar(false);
  };
  
  const body = (
    <div style={modalStyle} className={classes.paper}>
      <p
        style={{
          color: "black",
          fontSize: 22,
          fontFamily: "sans-serif",
          marginTop: "1rem",
          fontWeight: 600,
        }}
      >
        Editar {sectionName}
      </p>
      <div style={{ width: "100%", marginTop: 40, gap: 8 }}>
        <input
          type="text"
          maxLength={100}
          placeholder="Label dos banner (opcional)"
          value={name}
          style={{ width: "100%", padding: 8, fontSize: 16 }}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </div>
      <div style={{ width: "100%", marginTop: 40, gap: 8 }}>
        <FormControl fullWidth>
          <InputLabel id="select-type-label">Tipo de banner</InputLabel>
          <Select
            disabled={selectedSection?.banners?.length > 0 ?? false}
            labelId="select-type-label"
            id="select"
            value={bannerType}
            label="Tipo de banner"
            onChange={onChangeBannerType}
          >
            <MenuItem value={1}>Institucional</MenuItem>
            <MenuItem value={2}>Publicidade Grande</MenuItem>
            <MenuItem value={3}>Lojas Destaque</MenuItem>
            <MenuItem value={4}>Publicidade Pequeno</MenuItem>
            <MenuItem value={5}>Publicidade Médio</MenuItem>
            <MenuItem value={6}>Institucional Pequeno</MenuItem>
          </Select>
        </FormControl>
        {(selectedSection?.banners?.length > 0 ?? false) && (
          <p style={{ color: "red", fontSize: 14, marginTop: 10 }}>
            Para mudar o tipo de banner é necessário remover os banners
          </p>
        )}
      </div>
      <div style={{ width: "100%", marginTop: 40, gap: 8 }}>
        <FormControl fullWidth>
          <InputLabel id="select-link-label">Destino do Banner</InputLabel>
          <Select
            disabled={selectedSection?.banners?.length > 0 ?? false}
            labelId="select-link-label"
            id="simple-select"
            value={destinationType}
            label="Destino do Banner"
            onChange={onChangeDestinationType}
          >
            <MenuItem value="store">Loja</MenuItem>
            <MenuItem value="announce">Produto</MenuItem>
            <MenuItem value="page">Página</MenuItem>
            <MenuItem value="external">Link externo</MenuItem>
          </Select>
        </FormControl>
        {(selectedSection?.banners?.length > 0 ?? false) && (
          <p style={{ color: "red", fontSize: 14, marginTop: 10 }}>
            Para mudar o destino do banner é necessário remover os banners
          </p>
        )}
      </div>
      
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "end",
          gap: 10,
        }}
      >
        <Button
          style={{ marginTop: "2rem", padding: "8px",  textTransform: 'none' }}
          variant="outlined"
          color="secondary"
          onClick={() => handleClose()}
        >
          Fechar
        </Button>
        <Button
          style={
            loading || bannerType === "" || destinationType === "" ?
            { marginTop: "2rem", padding: "8px", background: "#8F8F8F", textTransform: 'none'} :
            { marginTop: "2rem", padding: "8px", background: "#35A69B", textTransform: 'none'} 
          }
          variant="contained"
          color="primary"
          disabled={loading || bannerType === "" || destinationType === ""}
          onClick={onSubmit}
        >
          {loading ? "Salving..." : "Salvar"}
        </Button>
      </div>
    </div>
  );

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackBar}
        autoHideDuration={2000}
        onClose={handleCloseSnackBar}
      >
        <SnackbarContent style={{ backgroundColor: "green" }} message={responseMessage} />
      </Snackbar>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
        {body}
      </Modal>
    </div>
  );
};

export default EditSectionModal;
