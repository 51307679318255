import React, { useEffect, useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";

import { FormArea, InputSearch } from "./styled";
import Api from "../../utils/api";
import swal from "sweetalert";
import { isEmpty } from "lodash";

import backButton from "../../assets/arrow_back.svg";
import API from "../../utils/api";
import Select from 'react-select';
import LengthCounter from "../components/LengthCounter";

const Edit = () => {
  const history = useHistory();
  const { action, environmentId } = useParams();

  const [description, setDescription] = useState("");
  const [resume, setResume] = useState("");
  const [cityId, setCityId] = useState("");
  const [cityName, setCityName] = useState("");
  const [cityList, setCityList] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [statesList, setStatesList] = useState([]);
  const [state, setState] = useState("");
  const [status, setStatus] = useState(1);

  const [errors, setErrors] = useState({
    description: "",
    resume: "",
    city: "",
  });

  useEffect(() => {
    async function fetchData() {
      const response = await Api.getStates();
      const result  = response.data.body.data.sort((a, b) =>
      a.state > b.state ? 1 : -1,
    );
      setStatesList(result);
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      if(!state) {
        setIsDisabled(true)
        return []
      };
      setIsDisabled(true)
      const response = await API.getCitiesByState(state, {
        skip: 0,
        limit: 900,
      });

      const newResult = response.data.body.data.map((i) => ({
        label: i.city,
        value: i.city,
        id: i._id,
      }))
      setCityList(newResult)
      setIsDisabled(false)
    }
    fetchData();
  }, [state]);

  useEffect(() => {
    setErrors({ ...errors, description: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description]);

  useEffect(() => {
    setErrors({ ...errors, resume: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resume]);

  useEffect(() => {
    setErrors({ ...errors, city: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityId]);

  useEffect(() => {
    const availableActions = ["edit", "create"];

    if (
      !availableActions.includes(action) ||
      (action === "edit" && !environmentId) ||
      (action === "create" && environmentId)
    ) {
      history.push("/environment");
      return null;
    }

    if (action === "edit") {
      getEnvironmentById(environmentId);
    }
  }, [action, environmentId, history]);

  const getEnvironmentById = async (id) => {
    try {
      const response = await Api.getEnvironmentById(id);

      if (response.status === 200) {
        const { description, resume, cityId, status } = response.data.body.data;
        setDescription(description);
        setResume(resume);
        setCityId(cityId._id);
        setCityName(cityId.city);
        setState(cityId.stateId._id)
        setStatus(status);
      }
    } catch (error) {
      await swal({
        title: "Algo inesperado aconteceu!",
        text: "Por favor tente novamente mais tarde.",
        icon: "error",
      });
    }
  };

  const validateForm = () => {
    const err = {};

    if (description < 3)
      err.description = "O campo descrição deve possuir pelo menos 3 dígitos";

    if (resume < 3)
      err.resume = "O campo resumo deve possuir pelo menos 3 dígitos";

    if (cityId === "") err.city = "O campo cidade deve ser informado";

    if (!isEmpty(err)) {
      setErrors({ ...errors, ...err });
      return false;
    }

    setErrors({
      description: "",
      resume: "",
      city: "",
    });
    return true;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const requestBody = {
      status: status,
      description: description,
      resume: resume,
      cityId: cityId,
    };
    try {
      if (action === "edit") {
        await API.updateEnvironment(environmentId, requestBody);

        await swal({
          title: "Tudo certo!",
          text: "Ambiente foi atualizado com sucesso!",
          icon: "success",
        });
      } else {
        await Api.createEnvironment(requestBody);

        await swal({
          title: "Tudo certo!",
          text: "Ambiente foi criado com sucesso!",
          icon: "success",
        });
      }

      history.push("/environment");
    } catch (error) {
      await swal({
        title: "Algo inesperado aconteceu!",
        text: "Por favor tente novamente mais tarde.",
        icon: "error",
      });
    }
  };

  const onChange = (option) => {
    setCityId(option.id)
    setCityName(option.value)
 }
  return (
    <FormArea>
      <div className="form--area">
        <div className="form--header">
          <Link to="/environment">
            <img src={backButton} alt="Voltar" />
          </Link>

          <h1>
            {action === "edit" ? "Editar Ambiente" : "Adicionar Ambiente"}
          </h1>
        </div>
        <form onSubmit={handleFormSubmit}>
          <label htmlFor="description">Descrição:</label>
          <input
            type="text"
            id="description"
            maxLength="50"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            required={true}
          />
          <LengthCounter
            props={{
              field: description,
              maxLength: 50,
            }}
          />
          {errors.description !== "" && (
            <div className="form--error">{errors.description}</div>
          )}

          <label htmlFor="resume">Resumo:</label>
          <input
            type="text"
            id="resume"
            maxLength="500"
            value={resume}
            onChange={(e) => {
              setResume(e.target.value);
            }}
            required={true}
          />
          <LengthCounter
            props={{
              field: resume,
              maxLength: 500,
            }}
          />
          {errors.resume !== "" && (
            <div className="form--error">{errors.resume}</div>
          )}

          <label htmlFor="state">Estado:</label>

          <select
            id="state"
            value={state}
            onChange={(e) => setState(e.target.value)}
          >
            <option value="" defaultValue disabled>
              Selecione um estado
            </option>
            {statesList.map((e) => (
              <option key={e._id} value={e._id}>
                {e.state}
              </option>
            ))}
          </select>
          {errors.state !== "" && (
            <div className="form--error">{errors.state}</div>
          )}

          <label>Cidade:</label>
          <Select
            isDisabled={isDisabled}
            isClearable
            isSearchable
            onChange={onChange}
            isLoading={isDisabled}
            name="city"
            value={{
              label: cityName,
              value: cityName,
              id: cityId
            }}
            placeholder={"Nome da cidade"}
            options={cityList}
      />
          <button type="submit">Salvar</button>
        </form>
      </div>
    </FormArea>
  );
};

export default Edit;
