import React, { useState, useEffect } from "react";
import API from "../../utils/api";

import { SearchFilterArea } from "./styled";

let timer;
let delay = 0;
const DELAY_TIME = 800;

const SearchFilter = ({ setSearchMethod, activeOrDelete, pagination }) => {
  const [title, setTitle] = useState("");

  useEffect(() => {
    delay = 0;
  }, [activeOrDelete]);

  useEffect(() => {
    if (timer) clearTimeout(timer);

    const params = { populateEmphasis: true };

    if (title) params.title = title;
    if (pagination.skip) params.skip = pagination.skip;
    if (pagination.limit) params.limit = pagination.limit;

    timer = setTimeout(() => {
      setSearchMethod(() => async () => {
        const response =
          activeOrDelete === "active"
            ? await API.getStoreByStatusAndFilter(2, params)
            : await API.getHomeEmphasisStore(params);

        const data = response.data.body.data;

        return data;
      });
    }, delay);

    delay = DELAY_TIME;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, activeOrDelete, pagination.skip]);

  return (
    <SearchFilterArea>
      Filtrar:
      <div className="search-filter">
        <div className="search-filter__input">
          <input
            type="text"
            placeholder="Pesquisar por Nome"
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />
        </div>
      </div>
    </SearchFilterArea>
  );
};

export default SearchFilter;
