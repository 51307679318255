import React, { useState } from "react";
import { useHistory } from "react-router";
import swal from "sweetalert";
import API from "../../utils/api";
import { formatDateAndTime } from "../../utils/date";
import { Switch } from "@material-ui/core";

import styled from "styled-components";

export const OrderContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
`;

const ActiveTable = ({ data, refreshTable }) => {
  const [hiddenActive, setHiddenActive] = useState(false);

  // const handleEditAction = (id) => {
  //   history.push(`store/edit/${id}`);
  // };

  // eslint-disable-next-line
  const history = useHistory();

  const handleCreateEmphasisAction = async (id) => {
    const confirm = await confirmModalEmphasis();

    if (confirm) {
      try {
        const responseStore = await API.getStoreById(id);
        const responseEnvironment = await API.getEnvironmentByStore(id);

        const environment = responseEnvironment.data.body.data;
        const store = responseStore.data.body.data;

        const requestBody = {
          emphasisType: 2,
          emphasisId: id,
          environmentId: environment[0].environmentId,
          classificationId: store.classification[0].classificationId._id,
        };
        await API.createHomeEmphasis(requestBody);

        await swal({
          title: "Tudo certo!",
          text: "Estabelecimento marcado como destaque!",
          icon: "success",
        });
      } catch (error) {
        await swal({
          title: "Algo inesperado aconteceu!",
          text: "Por favor tente novamente mais tarde.",
          icon: "error",
        });
      }
      refreshTable();
    }
  };

  const confirmModalEmphasis = () => {
    const confirm = swal(
      "Tem certeza que deseja destacar esse Estabelecimento?'",
      {
        buttons: ["Cancelar", "Confirmar"],
      }
    ).then((value) => (value ? true : false));

    return confirm;
  };

  return (
    <div className="table-responsive">
      <OrderContainer>
        <span style={{ color: "gray" }}>Ocultar Destacados</span>
        <Switch
          checked={hiddenActive}
          onChange={() => setHiddenActive(!hiddenActive)}
        />
      </OrderContainer>
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Data de Registro</th>
            <th>Nome</th>
            <th>Responsável</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item, index) => {
              const isDestaque = item.emphasis.length > 0;
              return (
                <tr key={index}>
                  {hiddenActive && isDestaque ? null : (
                    <>
                      <td>{index + 1}</td>
                      <td>{formatDateAndTime(item.createdAt)}</td>
                      <td>{item.title}</td>
                      <td>
                        {item?.responsible && !item?.responsible.fullName
                          ? item?.responsible.corporateName
                          : item?.responsible.fullName}
                      </td>
                      <td>
                        <button
                          className={
                            isDestaque ? "button--disabled" : "activate--button"
                          }
                          onClick={() => {
                            if (isDestaque) return;
                            handleCreateEmphasisAction(item._id);
                          }}
                        >
                          {isDestaque ? "Destacado" : "Destacar"}
                        </button>
                        {/* <button
                          className="edit--button"
                          onClick={() => {
                            handleEditAction(item._id);
                          }}
                        >
                          Editar
                        </button> */}
                      </td>
                    </>
                  )}
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default ActiveTable;
