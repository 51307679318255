import React from "react";
import { useHistory } from "react-router";
import swal from "sweetalert";
import API from "../../utils/api";
import { formatDateAndTime } from "../../utils/date";
import { formatText } from "../../utils/formatText";

const ActiveTable = ({ data, refreshTable }) => {
  const history = useHistory();

  const handleEditAction = (id) => {
    history.push(`environment-promotions/edit/${id}`);
  };

  const handleManageAction = (id) => {
    history.push(`/related-promotion-classification/${id}`);
  };

  const handleDeleteAction = async (id) => {
    if (await confirmModal()) {
      try {
        const response = await API.removeEnvironmentPromotions(id);

        if (response.status === 200) {
          await swal({
            title: "Tudo certo!",
            text: "Promoção excluida com sucesso!",
            icon: "success",
          });
          refreshTable();
        } else {
          await swal({
            title: "Ocorreu algo de errado!",
            text: "Ocorreu algum erro ao excluir a Promoção",
            icon: "error",
          });
        }
      } catch (error) {
        if (
          error.response.data.body.errors ===
          "exceptions:CANNOT_DELETE_LINKED_PROMOTION"
        ) {
          await swal({
            title: "Promoção está vinculada a um pedido!",
            //   text: "Por favor tente.",
            icon: "error",
          });
          return;
        }
        await swal({
          title: "Ocorreu algo de errado!",
          text: "Ocorreu algum erro ao excluir a Promoção",
          icon: "error",
        });
      }
    }
    return;
  };

  const confirmModal = () => {
    const confirm = swal("Tem certeza que deseja excluir essa Promoção?'", {
      buttons: ["Cancelar", "Confirmar"],
    }).then((value) => (value ? true : false));

    return confirm;
  };

  const DEVICE_TYPE = { 1: "Todos", 2: "Android", 3: "IOS" };

  return (
    <div className="table-responsive">
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Data de Registro</th>
            <th>Descrição</th>
            <th>Tipo</th>
            <th>Limite</th>
            <th>Valor</th>
            <th>Disp.</th>
            <th>Início</th>
            <th>Término</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{formatDateAndTime(item.promotion.createdAt)}</td>
                <td>{formatText(item.promotion.description, 25)}</td>
                <td>
                  {item.promotion.discountType === 1 ? "Primeira Compra" : ""}
                </td>
                <td>
                  {item.promotionLimit}/{item.promotion.orderLimit} Pedidos
                </td>
                <td>
                  {(item.promotion.discountValue / 100).toLocaleString(
                    "pt-BR",
                    {
                      style: "currency",
                      currency: "BRL",
                    }
                  )}
                </td>
                <td>{DEVICE_TYPE[item.promotion.deviceType]}</td>
                <td>{formatDateAndTime(item.promotion.startTime)}</td>
                <td>{formatDateAndTime(item.promotion.endTime)}</td>
                <td>
                  <button
                    className="manage--button"
                    onClick={() => {
                      handleManageAction(item.promotion._id);
                    }}
                  >
                    Gerenciar
                  </button>
                  <button
                    className="edit--button"
                    onClick={() => {
                      handleEditAction(item.promotion._id);
                    }}
                  >
                    Editar
                  </button>
                  <button
                    className="delete--button"
                    onClick={() => {
                      handleDeleteAction(item.promotion._id);
                    }}
                  >
                    Excluir
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default ActiveTable;
