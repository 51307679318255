import React from "react";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import API from "../../utils/api";
import { formatDateAndTime } from "../../utils/date";

const DeletedTable = ({ data, refreshTable }) => {
  const history = useHistory();

  const handleEditAction = (id) => {
    history.push(`way-of-doing/edit/${id}`);
  };

  const handleActivate = async (id) => {
    if (
      await confirmModal(
        "Tem certeza que deseja ativar essa Forma de Realização?"
      )
    ) {
      try {
        const response = await API.toggleWaysOfDoing(id);

        if (response.status === 200) {
          await swal({
            title: "Tudo certo!",
            text: "Forma de realização reativado com sucesso!",
            icon: "success",
          });

          refreshTable();
        } else {
          await swal({
            title: "Ocorreu algo de errado!",
            text: "Ocorreu algum erro ao reativar a Forma de Realização",
            icon: "error",
          });
        }
      } catch (error) {
        if (!error.response) {
          console.log(error);
          await swal({
            title: "Algo inesperado aconteceu!",
            text: "Por favor tente novamente mais tarde.",
            icon: "error",
          });
          return;
        }
        console.log(error.response.data.message);
        await swal({
          title: "Ocorreu algo de errado!",
          text: error.response.data.message,
          icon: "error",
        });
      }
    }
    return;
  };

  const handleDestroyEmployee = async (id) => {
    if (
      await confirmModal(
        "Tem certeza que deseja excluir definitivamente essa Forma de Realização?"
      )
    ) {
      try {
        const response = await API.deleteWaysOfDoing(id);

        if (response.status === 200) {
          await swal({
            title: "Tudo certo!",
            text: "Forma de realização foi excluída com sucesso!",
            icon: "success",
          });

          refreshTable();
        } else {
          await swal({
            title: "Ocorreu algo de errado!",
            text: "Ocorreu algum erro ao excluir a forma de realização",
            icon: "error",
          });
        }
      } catch (error) {
        if (!error.response) {
          console.log(error);
          await swal({
            title: "Algo inesperado aconteceu!",
            text: "Por favor tente novamente mais tarde.",
            icon: "error",
          });
          return;
        }
        console.log(error.response.data.message);
        await swal({
          title: "Ocorreu algo de errado!",
          text: error.response.data.message,
          icon: "error",
        });
      }
    }
    return;
  };

  const confirmModal = (mensage) => {
    const confirm = swal(mensage, {
      buttons: ["Cancelar", "Confirmar"],
    }).then((value) => (value ? true : false));

    return confirm;
  };

  return (
    <div className="table-responsive">
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Data de Registro</th>
            <th>Descrição</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((wayOfDoing, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{formatDateAndTime(wayOfDoing.createdAt)}</td>
                  <td>{wayOfDoing.description}</td>
                  <td>
                    <button
                      className="edit--button"
                      onClick={() => {
                        handleEditAction(wayOfDoing._id);
                      }}
                    >
                      Editar
                    </button>
                    <button
                      className="activate--button"
                      onClick={() => {
                        handleActivate(wayOfDoing._id);
                      }}
                    >
                      Ativar
                    </button>
                    <button
                      className="delete--button"
                      onClick={() => {
                        handleDestroyEmployee(wayOfDoing._id);
                      }}
                    >
                      Excluir
                    </button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default DeletedTable;
