import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import API from "../../utils/api";
import { formatDateAndTime } from "../../utils/date";

const DeletedTable = ({ data, refreshTable }) => {
  const [homeEmphasisWithStore, setHomeEmphasisWithStore] = useState([]);

  useEffect(() => {
    const getPopulatedEmphasis = async (homeEmphasisList) => {
      const newArray = [...homeEmphasisWithStore];

      // remove os destaques de anúncios
      const storeEmphasis = homeEmphasisList.filter(
        (e) => e.emphasisType === 2
      );

      for (let emphasis of storeEmphasis) {
        const response = await API.getStoreById(emphasis.emphasisId._id);

        const store = response.data.body.data;

        newArray.push({ ...emphasis, emphasisId: store });
      }
      setHomeEmphasisWithStore(newArray);
    };
    getPopulatedEmphasis(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleDeleteAction = async (id) => {
    if (await confirmModal()) {
      try {
        const response = await API.deleteHomeEmphasis(id);

        if (response.status === 200) {
          await swal({
            title: "Tudo certo!",
            text: "Loja removida dos destaques com sucesso!",
            icon: "success",
          });

          setHomeEmphasisWithStore(
            homeEmphasisWithStore.filter((emphasis) => emphasis._id !== id)
          );
          refreshTable();
        } else {
          await swal({
            title: "Ocorreu algo de errado!",
            text: "Ocorreu algum erro ao destacar uma loja!",
            icon: "error",
          });
        }
      } catch (error) {
        if (!error.response) {
          await swal({
            title: "Algo inesperado aconteceu!",
            text: "Por favor tente novamente mais tarde.",
            icon: "error",
          });
          return;
        }
        await swal({
          title: "Ocorreu algo de errado!",
          text: error.response.data.message,
          icon: "error",
        });
      }
    }
    return;
  };

  const confirmModal = () => {
    const confirm = swal(
      "Tem certeza que deseja remover essa loja dos destaques?'",
      {
        buttons: ["Cancelar", "Confirmar"],
      }
    ).then((value) => (value ? true : false));

    return confirm;
  };

  return (
    <div className="table-responsive">
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Data de Registro</th>
            <th>Nome</th>
            <th>Responsável</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item, index) => {
              return (
                <tr key={item._id}>
                  <td>{index + 1}</td>
                  <td>{formatDateAndTime(item.createdAt)}</td>
                  <td>{item.emphasisId.title}</td>
                  <td>{item?.emphasisId.responsible && !item.emphasisId.responsible.fullName
                      ? item?.emphasisId.responsible.corporateName
                      : item?.emphasisId.responsible.fullName
                    }</td>
                  <td>
                    <button
                      className="delete--button"
                      onClick={() => {
                        handleDeleteAction(item._id);
                      }}
                    >
                      Remover destaque
                    </button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default DeletedTable;
