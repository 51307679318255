import React from "react";
import { PageArea } from "./styled";
import ActiveDeleted from "../components/ActiveDeleted";
import SearchFilter from "./SearchFilter";
import ActiveTable from "./ActiveTable";

const Page = () => {
  return (
    <PageArea>
      <div className="page--area">
        <ActiveDeleted
          searchFilter={SearchFilter}
          activeTable={ActiveTable}
          route="/environment-promotions"
          displayTabs={false}
          paginate={true}
        />
      </div>
    </PageArea>
  );
};

export default Page;
