import { CardArea } from './styled';
import { DownloadButton } from '../../components/downloadButton';

const ExportsCardComponent = ({ label, onclick, loading }) => {
    return <CardArea >
        <div className="card-area">
            <div className='label'>
                {label}
            </div>
            {
                loading ?
                    <>
                        <p>Carregando CSV...</p>
                        <div className="loader">Loading...</div>
                    </>
                    :
                    <DownloadButton onclick={() => onclick()} label="Informações de Pedidos" />
                }
        </div>
    </CardArea>
}

export default ExportsCardComponent;