import {ComponentArea} from './styled';

const RankingCard = ({position, imageUrl, name, ordersQtt}) => {
    return <ComponentArea>
        <div className='main-info'>
            <p className='ranking-position'>{position}º</p>
            {imageUrl && <img alt='imagem da loja' className='store-image' src={imageUrl}></img>}
            <p className='store-name'>{name}</p>
        </div>
        <p className='orders-qtt'>{ordersQtt} pedidos</p>
    </ComponentArea>
}

export default RankingCard;