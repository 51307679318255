import React, { useEffect, useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";

import { RelatedClassificationArea } from "../styled";
import Api from "../../../utils/api";
import swal from "sweetalert";

import Loading from "../../components/Loading";

import backButton from "../../../assets/arrow_back.svg";
import AssociationTable from "./AssociationTable";
import { formatDateAndTime } from "../../../utils/date";

const Edit = () => {
  const history = useHistory();
  const { announceTypeId } = useParams();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState("view");

  useEffect(() => {
    if (!announceTypeId) {
      history.push("/announce-type");
      return null;
    }

    handleFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [announceTypeId, history, action]);

  const handleFetchData = async () => {
    setLoading(true);
    await getRelatedAnnounceClassification(announceTypeId);
    setLoading(false);
  };

  const getRelatedAnnounceClassification = async (id) => {
    try {
      const response = await Api.getRelatedAnnounceClassification(id);
      if (response.status === 200) {
        if (response.data.body.data !== null) {
          setData(response.data.body.data);
        }
      }
    } catch (error) {
      await swal({
        title: "Algo inesperado aconteceu!",
        text: "Por favor tente novamente mais tarde.",
        icon: "error",
      });
    }
  };

  const handleUnrelateClassification = async (id) => {
    if (
      await confirmModal(
        "Tem certeza que deseja excluir definitivamente essa Característica Complementar?"
      )
    ) {
      try {
        const response = await Api.unRelateAdClassification(id);
        if (response.status === 200) {
          await swal({
            title: "Tudo certo!",
            text: "Característica Complementar foi excluída com sucesso!",
            icon: "success",
          });
          window.location.reload(false);
        } else {
          await swal({
            title: "Ocorreu algo de errado!",
            text: "Ocorreu algum erro ao excluir a Característica Complementar",
            icon: "error",
          });
        }
      } catch (error) {
        if (!error.response) {
          await swal({
            title: "Algo inesperado aconteceu!",
            text: "Por favor tente novamente mais tarde.",
            icon: "error",
          });
          return;
        }
        await swal({
          title: "Ocorreu algo de errado!",
          text: error.response.data.message,
          icon: "error",
        });
      }
    }
    return;
  };

  const confirmModal = (mensage) => {
    const confirm = swal(mensage, {
      buttons: ["Cancelar", "Confirmar"],
    }).then((value) => (value ? true : false));

    return confirm;
  };

  return (
    <RelatedClassificationArea>
      <div className="page--area">
        <div className="header">
          <Link to="/announce-type">
            <img src={backButton} alt="Voltar" />
          </Link>
          <h1>Gerenciar Tipos de Anúncios Vinculados</h1>
          <div className="navigation-area">
            <div className="active-deleted-header">
              <div
                className={
                  action === "view"
                    ? "active-deleted-header-title active"
                    : "active-deleted-header-title"
                }
                onClick={() => {
                  setAction("view");
                }}
              >
                <h3>Visualizar</h3>
              </div>
              <div
                className={
                  action === "association"
                    ? "active-deleted-header-title active"
                    : "active-deleted-header-title"
                }
                onClick={() => {
                  setAction("association");
                }}
              >
                <h3>Vincular</h3>
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <div className="loading">
            <Loading />
          </div>
        ) : null}
        {action === "view" ? (
          <div className="table-responsive">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Data de Registro</th>
                  <th>Descrição</th>
                  <th>Resumo</th>
                  <th>Status</th>
                  <th>Parent</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{formatDateAndTime(item.classificationId.createdAt)}</td>
                        <td>{item.classificationId.description}</td>
                        <td>{item.classificationId.resume}</td>
                        <td>{item.classificationId.parent ?? "NULL"}</td>
                        <td>{item.classificationId.status}</td>
                        <td>
                          <button
                            className="delete--button"
                            onClick={() => {
                              handleUnrelateClassification(
                                item.classificationId._id
                              );
                            }}
                          >
                            Remover
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        ) : (
          <AssociationTable action={setAction} />
        )}
      </div>
    </RelatedClassificationArea>
  );
};

export default Edit;
