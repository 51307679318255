import { PageArea } from "./styled";
import Card2Component from "../components/card2";
import API from '../../../utils/api';
import { useState, useEffect } from "react";
import RankingCard from "../components/rankingCard";
import { downloadCsv } from "../../../utils/generate.csv";
import { DownloadButton } from '../components/downloadButton';

const FrequencyAnalysisPage = () => {
    const [ numberOfUsersPerFrequency, setNumberOfUsersPerFrequency ] = useState([]);
    const [ recencyAnalysisFilterIndex, setRecencyAnalysisFilterIndex ] = useState(0);
    const [ recencyAnalysisNumberOfUsers, setRecencyAnalysisNumberOfUsers ] = useState([]);
    const [ frequency, setFrequency ] = useState(0);
    const [ customersRanking, setCustomersRanking ] = useState([]);
    const [
        timeFilter,
        // setTimeFilter
    ] = useState("all");
    const CsvData = [ [], [] ];
    const CsvData2 = [];

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchData = async () => {
        fetchNumberOfUsersPerOrderFrequency();
        fetchRecencyAnalysis();
        fetchCustomersRanking();
    };

    const fetchNumberOfUsersPerOrderFrequency = async () => {
        const response = await API.getFrequencyAnalysis();
        if (response.status !== 200) {
            //tratar erro
            return;
        }
        setNumberOfUsersPerFrequency(response.data.body.data);
    };

    const fetchRecencyAnalysis = async () => {
        const response = await API.getRecencyAnalysis();
        if (response.status !== 200) {
            //tratar erro
            return;
        }
        setRecencyAnalysisNumberOfUsers(response.data.body.data);
    };

    const fetchCustomersRanking = async () => {
        let requestBody;

        if (timeFilter === "all") {
            requestBody = { "limit": 20 };
        }

        if (timeFilter === "month") {
            requestBody = {
                "lastMonth": true,
                "limit": 20
            };
        }

        if (timeFilter === "week") {
            requestBody = {
                "lastWeek": true,
                "limit": 20
            };
        }

        const response = await API.getCustomersRanking(requestBody);

        if (response.status !== 200 && response.status !== 201) {
            //tratar erro
            return;
        }
        setCustomersRanking(response.data.body.data);
    };

    const getCsvFrequencyAnalysis = () => {
        numberOfUsersPerFrequency.forEach((element) => {
            CsvData[ 0 ].push(element.label);
            CsvData[ 1 ].push(element.value);
        });
        recencyAnalysisNumberOfUsers.forEach((element) => {
            CsvData[ 0 ].push(element.label);
            CsvData[ 1 ].push(element.value);
        });



        downloadCsv(CsvData, 'Estatísticas de frequência', false);
    };

    const getCsvBuyersRanking = () => {
        customersRanking.forEach((element) => {
            CsvData2.push({
                "nome": element.buyer.accountName,
                "nº de pedidos": element.count
            });
        });

        downloadCsv(CsvData2, 'Ranking de compradores', true);
    };

    return <PageArea>
        <div className="page-area">
            <div className="page-label">
                <h1 >Análise de Frequência</h1>
                <DownloadButton onclick={ () => getCsvFrequencyAnalysis() } />
            </div>
            <div className="label-wrapper">
                <h1 className="label">Número de Usuários que compraram: </h1>
                <div className="dropdown">
                    <select
                        value={ frequency }
                        onChange={ (e) => setFrequency(e.target.value) }
                    >
                        <option value={ 0 }>1 vez</option>
                        <option value={ 1 }>2 vezes</option>
                        <option value={ 2 }>3 vezes</option>
                        <option value={ 3 }>4 vezes</option>
                        <option value={ 4 }>5 vezes</option>
                        <option value={ 5 }>6 vezes</option>
                        <option value={ 6 }>7 vezes</option>
                        <option value={ 7 }>8 vezes</option>
                        <option value={ 8 }>9 vezes</option>
                        <option value={ 9 }>10 vezes</option>
                        <option value={ 10 }>11 vezes</option>
                        <option value={ 11 }>12 vezes</option>
                    </select>
                </div>
            </div>

            <Card2Component
                label='Usuários'
                qtt={
                    numberOfUsersPerFrequency.length === 0 ? 0 :
                        numberOfUsersPerFrequency[ frequency ].value
                } />

            <div className="label-wrapper">
                <h1 className="label">Número de Usuários que compraram a: </h1>
                <div className="dropdown">
                    <select
                        value={ recencyAnalysisFilterIndex }
                        onChange={ (e) => setRecencyAnalysisFilterIndex(e.target.value) }
                    >
                        <option value={ 0 }>0 a 15 dias</option>
                        <option value={ 1 }>15 a 30 dias</option>
                        <option value={ 2 }>30 a 45 dias</option>
                        <option value={ 3 }>45 a 60 dias</option>
                        <option value={ 4 }>60 a 75 dias</option>
                        <option value={ 5 }>75 a 90 dias</option>
                        <option value={ 6 }>mais de 90 dias</option>
                    </select>
                </div>
            </div>

            <Card2Component
                label='Usuários'
                qtt={
                    recencyAnalysisNumberOfUsers.length === 0 ? 0 :
                        recencyAnalysisNumberOfUsers[ recencyAnalysisFilterIndex ].value
                } />

            <div className="page-label">
                <h1 className="label">Maiores compradores</h1>
                <DownloadButton onclick={ () => getCsvBuyersRanking() } />
            </div>

            {
                customersRanking.map((key, index) => {
                    return <RankingCard
                        position={ index + 1 }
                        name={ customersRanking[ index ].buyer.accountName }
                        ordersQtt={ customersRanking[ index ].count }
                    />;
                })
            }
        </div>
    </PageArea>;
};

export default FrequencyAnalysisPage;