import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import Snackbar from "@material-ui/core/Snackbar";
import { Button, SnackbarContent } from "@material-ui/core";

import API from "../../../utils/api";
import swal from "sweetalert";

const ConfirmDeleteBannerModal = ({ bannerId, isOpen, onClose, fetchData }) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteBanner = async () => {
    try {
      if (!bannerId) return;

      setIsDeleting(true);
      await API.deleteBannerById(bannerId);
      setIsDeleting(false);

      await fetchData();
      onClose();
    } catch (error) {
      await swal({
        title: "Ocorreu algo de errado!",
        text: "Não foi possível deletar o banner. Tente novamente mais tarde.",
        icon: "error",
      });
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose} aria-labelledby="modal-title" aria-describedby="modal-description">
      <div
        style={{
          position: "absolute",
          backgroundColor: "white",
          padding: 20,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          borderRadius: '5px',
        }}
      >
        <h1
          style={{
            fontFamily: "'Montserrat', sans-serif",
          }}
        >
          Deseja deletar o banner?
        </h1>
        <div style={{ height: 16 }} />

        <div
          style={{
            display: "flex",
          }}
        >
          <Button
            onClick={onClose}
            style={{ backgroundColor: "#35A69B", color: "white", textTransform: 'none' }}
            component="label"
            color="primary"
            variant="contained"
          >
            Cancelar
          </Button>
          <div style={{ width: 16 }} />
          <Button
            style={{ backgroundColor: "#DB6A6A", color: "white", textTransform: 'none'}}
            color="primary"
            component="label"
            variant="contained"
            disabled={isDeleting}
            onClick={handleDeleteBanner}
          >
            Deletar
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmDeleteBannerModal;
