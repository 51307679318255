import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import swal from "sweetalert";
import API from "../../utils/api";
import { formatDateAndTime } from "../../utils/date";
import { formatDistance } from "date-fns";
import { ptBR } from "date-fns/locale";
import orderStatus from '../../utils/orderStatus';
import { SelectContainer } from './styled';

const Page = ({ data, refreshTable }) => {
  const history = useHistory();

  const [ tabStatus, setTabStatus ] = useState(null);

  useEffect(() => {
    if (!tabStatus && data && data?.length > 0) {
      setTabStatus(data[ 0 ].status);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ data ]);

  const handleShowDetails = (id) => {
    history.push(`/order-management/details/${id}`);
  };
  const handleCancelAction = async (id) => {
    if (await confirmModal()) {
      try {
        const response = await API.cancelAdminOrder(id);

        if (response.status === 200) {
          await swal({
            title: "Tudo certo!",
            text: "Pedido Cancelado!",
            icon: "success",
          });
          refreshTable();
        } else {
          await swal({
            title: "Ocorreu algo de errado!",
            text: "Ocorreu algum erro ao cancelar o pedido",
            icon: "error",
          });
        }
      } catch (error) {
        if (!error.response) {
          await swal({
            title: "Algo inesperado aconteceu!",
            text: "Por favor tente novamente mais tarde.",
            icon: "error",
          });
          return;
        }
        await swal({
          title: "Ocorreu algo de errado!",
          text: error.response.data.message,
          icon: "error",
        });
      }
    }
    return;
  };

  const confirmModal = () => {
    const confirm = swal("Tem certeza que deseja Cancelar esse pedido?", {
      buttons: [ "Cancelar", "Confirmar" ],
    }).then((value) => (value ? true : false));

    return confirm;
  };

  const onOverrideStatus = async (e, id) => {
    const newStatus = e.target.value;
    console.log('newStatus', newStatus);

    const response = await swal({
      title: "Sobrescrever status",
      text: "Tem certeza que deseja sobrescrever o status deste pedido?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (!response) { return console.log('NON!'); }

    try {
      await API.updateOrderStatus(id, newStatus);
    } catch (error) {
      await swal({
        title: "Falha ao mudar status do pedido!",
        text: "Houve um erro ao mudar o status do pedido.",
        icon: "error",
      });
    }

    refreshTable();
  };

  return (
    <table>
      <thead>
        <tr>
          <th>#</th>
          <th>Registro</th>
          <th>Tempo</th>
          <th>Loja</th>
          <th>Cliente</th>
          <th>Nº</th>
          <th>Status</th>
          <th>Ações</th>
        </tr>
      </thead>
      <tbody>
        { data &&
          data.map((item, index) => (
            <tr key={ index }>
              <td>{ index + 1 }</td>
              <td>{ formatDateAndTime(item.createdAt) }</td>
              <td
                style={ {
                  color:
                    new Date() - new Date(item.createdAt) >= 1000 * 60 * 5
                      ? "red"
                      : "green",
                } }
              >
                <p style={ { fontSize: "12pt" } }>
                  { formatDistance(new Date(item.createdAt), new Date(), {
                    locale: ptBR,
                  }) }
                </p>
              </td>
              <td>{ item.storeId.title }</td>
              <td>{ item.buyer.accountName }</td>
              <td>{ item.orderNumber }</td>
              <td>
                <SelectContainer name="status" id="override_status" value={ tabStatus || 0 } onChange={ (e) => onOverrideStatus(e, item._id) }>
                  {
                    orderStatus.map((status) => (
                      <option key={ status.status } value={ status.status }>{ status.name }</option>
                    ))
                  }
                </SelectContainer>
              </td>
              <td>
                <button
                  className="activate--button"
                  onClick={ () => {
                    handleShowDetails(item._id);
                  } }
                >
                  Detalhes
                </button>
                {
                  ![ 4, 5, 6, 7, 8 ].includes(item.status) && (
                    <button
                      className="delete--button"
                      onClick={ () => {
                        handleCancelAction(item._id);
                      } }
                    >
                      Cancelar
                    </button>
                  )
                }
              </td>
            </tr>
          )) }
      </tbody>
    </table>
  );
};
export default Page;
