import React from "react";
import { useHistory } from "react-router";
import { formatDateAndTime } from "../../utils/date";

const ActiveTable = ({ data, refreshTable }) => {
  const history = useHistory();

  const handleEditAction = (id) => {
    history.push(`registration/edit/${id}`);
  };

  return (
    <div className="table-responsive">
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Data de Registro</th>
            <th>Imagem</th>
            <th>Nome</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{formatDateAndTime(item.createdAt)}</td>
                <td className="profile--image">
                  <img src={item.imageUrl} alt={item.fullName + " " + index} />
                </td>
                <td>{item.fullName}</td>
                <td>
                  <button
                    className="edit--button"
                    onClick={() => {
                      handleEditAction(item._id);
                    }}
                  >
                    Editar
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default ActiveTable;
