import { CardArea } from './styled';

const AverageTicketCardComponent = ({ qtt }) => {
    return <CardArea >
        <div className="card-area">
            <div className='qtt'>
            R${qtt}
            </div>
        </div>
    </CardArea>
}

export default AverageTicketCardComponent;