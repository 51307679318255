import React from "react";

const LengthCounter = ({ props }) => {
  const { field, maxLength } = props;

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "end",
        color: field?.length === maxLength ? "red" : null,
      }}
    >
      {field?.length || 0}/{maxLength}
    </div>
  );
};

export default LengthCounter;
