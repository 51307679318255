import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Loading from "../Loading";
import PaginationControls from "../Pagination/PaginationControls";

import { ActiveDeleted } from "./styled";

import { defaults } from "../../../utils/pagination";

const Component = ({
  searchFilter = null,
  activeTable = null,
  deletedTable = null,
  route = "",
  activeTabName = "Ativos",
  inactiveTabName = "Inativos",
  displayTabs = true,
  displayAddButton = true,
  paginate = false,
}) => {
  const [activeOrDelete, setActiveOrDelete] = useState("active");
  const [activeData, setActiveData] = useState([]);
  const [deletedData, setDeletedData] = useState([]);
  const [searchMethod, setSearchMethod] = useState(null);
  const [loading, setLoading] = useState(false);

  // pagination
  const [page, setPage] = useState(defaults.page);
  const [skip, setSkip] = useState(defaults.skip);
  const [limit, setLimit] = useState(defaults.limit);

  const goForward = () => {
    if (activeData?.length > 0 && activeData.length < limit) return;
    if (deletedData?.length > 0 && deletedData.length < limit) return;
    setLoading(true);
    setSkip(skip + limit);
    setPage(page + 1);
    fetchData();
  };
  const goBack = () => {
    if (skip === 0 || page === 1) return;
    setLoading(true);
    setSkip(skip - limit);
    setPage(page - 1);
    fetchData();
  };
  const goFirstPage = () => {
    if (skip === 0 && page === 1) return;
    setLoading(true);
    setSkip(defaults.skip);
    setPage(defaults.page);
    fetchData();
  };
  const setItemsPerPage = (num) => {
    setPage(defaults.page);
    setSkip(defaults.skip);
    setLimit(num);
    fetchData();
  };

  const toggleTab = (data) => {
    if (data === activeOrDelete) return;
    setActiveOrDelete(data);

    setPage(defaults.page);
    setSkip(defaults.skip);
    setLimit(defaults.limit);
  };

  const getCurrentTabLength = (action) => {};

  const fetchData = async () => {
    if (searchMethod) {
      setLoading(true);
      const response = await searchMethod();

      if (activeOrDelete === "active") {
        setActiveData(response);
      } else {
        setDeletedData(response);
      }
      setLoading(false);
    }
  };

  const handleFetchData = async () => {
    await fetchData();
    setLoading(false);
  };

  useEffect(() => {
    handleFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchMethod]);

  useEffect(() => {
    setActiveData([]);
    setDeletedData([]);
  }, [activeOrDelete]);

  const SearchFilter = searchFilter;
  const ActiveTable = activeTable;
  const DeletedTable = deletedTable;

  return (
    <ActiveDeleted>
      {displayAddButton && (
        <Link to={`${route}/create`} className="add-button">
          Adicionar
        </Link>
      )}
      {displayTabs && (
        <div className="active-deleted-header">
          <div
            className={
              activeOrDelete === "active"
                ? "active-deleted-header-title active"
                : "active-deleted-header-title"
            }
            onClick={() => toggleTab("active")}
          >
            <h3>{activeTabName}</h3>
          </div>
          <div
            className={
              activeOrDelete === "delete"
                ? "active-deleted-header-title active"
                : "active-deleted-header-title"
            }
            onClick={() => toggleTab("delete")}
          >
            <h3>{inactiveTabName}</h3>
          </div>
        </div>
      )}
      <div className="search--bar" style={{ marginTop: displayTabs ? 0 : 40 }}>
        {searchFilter && (
          <SearchFilter
            setSearchMethod={setSearchMethod}
            activeOrDelete={activeOrDelete}
            pagination={{
              skip,
              limit,
            }}
          />
        )}
      </div>

      {paginate && (
        <PaginationControls
          pagination={{
            page,
            length:
              activeOrDelete === "active"
                ? activeData?.length || 0
                : deletedData?.length || 0,
            skip,
            limit,
            goForward,
            goBack,
            goFirstPage,
            setItemsPerPage,
          }}
          loading={loading}
        />
      )}

      <div className="table--area" style={{ marginTop: 10 }}>
        {loading ? (
          <div className="loading">
            <Loading />
          </div>
        ) : null}
        {activeOrDelete === "active" && activeTable && (
          <ActiveTable data={activeData} refreshTable={fetchData} />
        )}
        {activeOrDelete === "delete" && deletedTable && (
          <DeletedTable data={deletedData} refreshTable={fetchData} />
        )}
      </div>

      {paginate &&
        (activeData || deletedData) &&
        getCurrentTabLength(activeOrDelete) > 5 && (
          <PaginationControls
            pagination={{
              page,
              length: getCurrentTabLength(activeOrDelete),
              skip,
              limit,
              goForward,
              goBack,
              goFirstPage,
              setItemsPerPage,
            }}
            loading={loading}
          />
        )}
    </ActiveDeleted>
  );
};

export default Component;
