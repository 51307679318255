import React, { useState, useEffect } from "react";
import API from "../../../utils/api";
import Loading from "../../components/Loading";
import { ActiveDeleted } from "./styled";
import { defaults } from "../../../utils/pagination";
import PaginationControls from "../../components/Pagination/PaginationControls";
import orderStatus from "../../../utils/orderStatus";

const Component = ({
  searchFilter = null,
  inProductionTable = null,
  preparedTable = null,
  newOrdersTable = null,
  finishedTable = null,
  evaluatedTable = null,
  returnedTable = null,
  canceledTable = null,
  refusedTable = null,
  paginate = false,
}) => {
  const [ activeDeleteOrRelease, setActiveOrDelete ] = useState("newOrders");

  const [ numOfOrdersPerStatus, setNumOfOrdersPerStatus ] = useState(null);
  const [ loadingNumOfOrders, setLoadingNumOfOrders ] = useState(true);

  const [ newOrdersData, setNewOrdersData ] = useState([]);
  const [ inProductionData, setInProductionData ] = useState([]);
  const [ preparedData, setPreparedData ] = useState([]);

  const [ finishedData, setFinishedData ] = useState([]);
  const [ evaluatedData, setEvaluatedData ] = useState([]);
  const [ returnedData, setReturnedData ] = useState([]);

  const [ canceledData, setCanceledData ] = useState([]);
  const [ refusedData, setRefusedData ] = useState([]);

  const [ searchMethod, setSearchMethod ] = useState(null);
  const [ loading, setLoading ] = useState(false);

  // pagination
  const [ page, setPage ] = useState(defaults.page);
  const [ skip, setSkip ] = useState(defaults.skip);
  const [ limit, setLimit ] = useState(defaults.limit);

  const goForward = () => {
    if (newOrdersData?.length > 0 && newOrdersData.length < limit) return;
    if (inProductionData?.length > 0 && inProductionData.length < limit) return;
    if (preparedData?.length > 0 && preparedData.length < limit) return;
    if (finishedData?.length > 0 && finishedData.length < limit) return;
    if (evaluatedData?.length > 0 && evaluatedData.length < limit) return;
    if (returnedData?.length > 0 && returnedData.length < limit) return;
    if (canceledData?.length > 0 && canceledData.length < limit) return;
    if (refusedData?.length > 0 && refusedData.length < limit) return;

    setLoading(true);
    setSkip(skip + limit);
    setPage(page + 1);
    fetchData();
  };
  const goBack = () => {
    if (skip === 0 || page === 1) return;
    setLoading(true);
    setSkip(skip - limit);
    setPage(page - 1);
    fetchData();
  };
  const goFirstPage = () => {
    if (skip === 0 && page === 1) return;
    setLoading(true);
    setSkip(defaults.skip);
    setPage(defaults.page);
    fetchData();
  };
  const setItemsPerPage = (num) => {
    setPage(defaults.page);
    setSkip(defaults.skip);
    setLimit(num);
    fetchData();
  };

  const toggleTab = (data) => {
    if (data === activeDeleteOrRelease) return;
    setActiveOrDelete(data);

    setPage(defaults.page);
    setSkip(defaults.skip);
    setLimit(defaults.limit);
  };

  const getCurrentTabLength = (action) => {
    if (action === "newOrders") return newOrdersData?.length || 0;
    if (action === "inProduction") return inProductionData?.length || 0;
    if (action === "prepared") return preparedData?.length || 0;
    if (action === "finished") return finishedData?.length || 0;
    if (action === "evaluated") return evaluatedData?.length || 0;
    if (action === "returned") return returnedData?.length || 0;
    if (action === "canceled") return canceledData?.length || 0;
    if (action === "refused") return refusedData?.length || 0;

    return 0;
  };

  const fetchData = async () => {
    if (searchMethod) {
      const response = await searchMethod();

      if (activeDeleteOrRelease === "newOrders") {
        setNewOrdersData(response);
      } else if (activeDeleteOrRelease === "inProduction") {
        setInProductionData(response);
      } else if (activeDeleteOrRelease === "prepared") {
        setPreparedData(response);
      } else if (activeDeleteOrRelease === "inProduction") {
        setInProductionData(response);
      } else if (activeDeleteOrRelease === "finished") {
        setFinishedData(response);
      } else if (activeDeleteOrRelease === "evaluated") {
        setEvaluatedData(response);
      } else if (activeDeleteOrRelease === "returned") {
        setReturnedData(response);
      } else if (activeDeleteOrRelease === "canceled") {
        setCanceledData(response);
      } else if (activeDeleteOrRelease === "refused") {
        setRefusedData(response);
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFetchData = async () => {
    setLoading(true);
    await fetchData();
    setLoading(false);
  };

  useEffect(() => {
    const timer = setInterval(handleFetchData, 30000);
    return () => clearInterval(timer);
  }, [ activeDeleteOrRelease, handleFetchData ]);

  useEffect(() => {
    handleFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ searchMethod ]);

  useEffect(() => {
    setNewOrdersData([]);
    setInProductionData([]);
    setPreparedData([]);
    setFinishedData([]);
    setEvaluatedData([]);
    setReturnedData([]);
    setCanceledData([]);
    setRefusedData([]);
  }, [ activeDeleteOrRelease ]);

  useEffect(() => {
    const getNumberOfItemsPerStatus = async () => {
      try {
        const response = await API.getNumberOfSalesOrdersPerStatus();
        const num = response.data.body.data;

        if (!num) throw new Error("Failed to get data from server");

        setNumOfOrdersPerStatus(num);
      } catch (e) {
        console.error(e);
      }
      setLoadingNumOfOrders(false);
    };

    getNumberOfItemsPerStatus();
  }, [
    newOrdersData,
    inProductionData,
    preparedData,
    finishedData,
    evaluatedData,
    returnedData,
    canceledData,
    refusedData,
  ]);

  const SearchFilter = searchFilter;
  const NewOrdersTable = newOrdersTable;
  const InProductionTable = inProductionTable;
  const PreparedTable = preparedTable;
  const FinishedTable = finishedTable;
  const EvaluatedTable = evaluatedTable;
  const ReturnedTable = returnedTable;
  const CanceledTable = canceledTable;
  const RefusedTable = refusedTable;

  const displayNumberOfItems = (statusName) => {
    const item = orderStatus.find((i) => i.name === statusName);
    if (!item) {
      return null;
    }
    if (!numOfOrdersPerStatus) return null;
    return numOfOrdersPerStatus[ item?.status ];
  };

  function Tab(props) {
    return (
      <div
        className={
          activeDeleteOrRelease === props.status
            ? "active-deleted-header-title active"
            : "active-deleted-header-title"
        }
        onClick={ () => {
          setActiveOrDelete(props.status);
          if (paginate) toggleTab(props.status);
        } }
      >
        <span style={ { fontSize: 11 } }>
          { props.name }{ " " }
          { numOfOrdersPerStatus &&
            !loadingNumOfOrders &&
            `(${displayNumberOfItems(props.name)})` }
        </span>
      </div>
    );
  }

  return (
    <ActiveDeleted>
      <div className="active-deleted-header">
        <Tab status="newOrders" name="Novos Pedidos" />
        <Tab status="inProduction" name="Em Produção" />
        <Tab status="prepared" name="Entrega / Retirada" />

        <Tab status="finished" name="Concluídos" />
        <Tab status="evaluated" name="Avaliados" />
        <Tab status="returned" name="Devolvidos" />

        <Tab status="canceled" name="Cancelados" />
        <Tab status="refused" name="Recusados" />
      </div>
      <div className="search--bar">
        { searchFilter && (
          <SearchFilter
            setSearchMethod={ setSearchMethod }
            activeDeleteOrRelease={ activeDeleteOrRelease }
            pagination={ {
              skip,
              limit,
            } }
          />
        ) }
      </div>

      { paginate && (
        <PaginationControls
          pagination={ {
            page,
            length: getCurrentTabLength(activeDeleteOrRelease),
            skip,
            limit,
            goForward,
            goBack,
            goFirstPage,
            setItemsPerPage,
          } }
          loading={ loading }
        />
      ) }

      <div className="table--area" style={ { marginTop: 10 } }>
        { loading ? (
          <div className="loading">
            <Loading />
          </div>
        ) : null }
        { activeDeleteOrRelease === "newOrders" && newOrdersTable && (
          <NewOrdersTable data={ newOrdersData } refreshTable={ fetchData } />
        ) }
        { activeDeleteOrRelease === "inProduction" && inProductionTable && (
          <InProductionTable data={ inProductionData } refreshTable={ fetchData } />
        ) }
        { activeDeleteOrRelease === "prepared" && preparedTable && (
          <PreparedTable data={ preparedData } refreshTable={ fetchData } />
        ) }

        { activeDeleteOrRelease === "finished" && finishedTable && (
          <FinishedTable data={ finishedData } refreshTable={ fetchData } />
        ) }
        { activeDeleteOrRelease === "evaluated" && evaluatedTable && (
          <EvaluatedTable data={ evaluatedData } refreshTable={ fetchData } />
        ) }
        { activeDeleteOrRelease === "returned" && returnedTable && (
          <ReturnedTable data={ returnedData } refreshTable={ fetchData } />
        ) }

        { activeDeleteOrRelease === "canceled" && canceledTable && (
          <CanceledTable data={ canceledData } refreshTable={ fetchData } />
        ) }
        { activeDeleteOrRelease === "refused" && refusedTable && (
          <RefusedTable data={ refusedData } refreshTable={ fetchData } />
        ) }
      </div>

      { paginate && getCurrentTabLength(activeDeleteOrRelease) > 5 && (
        <PaginationControls
          pagination={ {
            page,
            length: getCurrentTabLength(activeDeleteOrRelease),
            skip,
            limit,
            goForward,
            goBack,
            goFirstPage,
            setItemsPerPage,
          } }
          loading={ loading }
        />
      ) }
    </ActiveDeleted>
  );
};

export default Component;
