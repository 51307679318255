import React from "react";

import { PageArea } from "./styled";

import ActiveDeletedRelease from "./ActiveDeletedRelease";

import SearchFilter from "./SearchFilter";
import PageTable from "./menu";

const Page = () => {
  return (
    <PageArea>
      <div className="page--area">
        <ActiveDeletedRelease
          searchFilter={SearchFilter}
          onReleaseTable={PageTable}
          activesTable={PageTable}
          closedTable={PageTable}
          pausedTable={PageTable}
          removedTable={PageTable}
          route="/store-management"
          paginate={true}
        />
      </div>
    </PageArea>
  );
};

export default Page;
