import React, { useEffect, useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";

import { FormArea } from "./styled";
import Api from "../../utils/api";
import swal from "sweetalert";
import { isEmpty } from "lodash";

import backButton from "../../assets/arrow_back.svg";
import API from "../../utils/api";
import LengthCounter from "../components/LengthCounter";

const Edit = () => {
  const history = useHistory();
  const { action, wayId } = useParams();

  const [description, setDescription] = useState("");
  const [requiresDeliveryLocation, setRequiresDeliveryLocation] =
    useState(false);
  const [status, setStatus] = useState(1);
  const [errors, setErrors] = useState({
    description: "",
    status: "",
    requiresDeliveryLocation: "",
  });

  useEffect(() => {
    setErrors({ ...errors, name: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description]);

  useEffect(() => {
    setErrors({ ...errors, status: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    const availableActions = ["edit", "create"];

    if (
      !availableActions.includes(action) ||
      (action === "edit" && !wayId) ||
      (action === "create" && wayId)
    ) {
      history.push("/way-of-delivering");
      return null;
    }

    if (action === "edit") {
      getWayId(wayId);
    }
  }, [action, wayId, history]);

  const getWayId = async (id) => {
    try {
      const response = await Api.getWaysOfDeliveryById(id);

      if (response.status === 200) {
        const { description, status, requiresDeliveryLocation } =
          response.data.body.data;

        setDescription(description);
        setStatus(status);
        setRequiresDeliveryLocation(requiresDeliveryLocation);
      }
    } catch (error) {
      console.log(error);
      await swal({
        title: "Algo inesperado aconteceu!",
        text: "Por favor tente novamente mais tarde.",
        icon: "error",
      });
    }
  };

  const validateForm = () => {
    const err = {};

    if (description < 3)
      err.description = "O campo deve possuir pelo menos 3 dígitos";

    if (!isEmpty(err)) {
      console.log(err);
      setErrors({ ...errors, ...err });
      return false;
    }

    setErrors({
      description: "",
      status: "",
      requiresDeliveryLocation: "",
    });
    return true;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const requestBody = {
      status: Number.parseInt(status),
      description: description,
      requiresDeliveryLocation: requiresDeliveryLocation === "true",
    };

    try {
      if (action === "edit") {
        await API.updateWaysOfDelivery(wayId, requestBody);

        await swal({
          title: "Tudo certo!",
          text: "Forma de Entrega foi atualizado com sucesso!",
          icon: "success",
        });
      } else {
        await Api.createWaysOfDelivery(requestBody);

        await swal({
          title: "Tudo certo!",
          text: "Forma de Entrega foi criada com sucesso!",
          icon: "success",
        });
      }

      history.push("/way-of-delivering");
    } catch (error) {
      if (error.response.status === 406) {
        await swal({
          title: "Forma de Entrega já existe!",
          text: "Por favor tente outra forma de entrega.",
          icon: "error",
        });
      } else {
        await swal({
          title: "Algo inesperado aconteceu!",
          text: "Por favor tente novamente mais tarde.",
          icon: "error",
        });
      }
    }
  };

  return (
    <FormArea>
      <div className="form--area">
        <div className="form--header">
          <Link to="/way-of-delivering">
            <img src={backButton} alt="Voltar" />
          </Link>

          <h1>
            {action === "edit"
              ? "Editar Forma de Entrega"
              : "Adicionar Forma de Entrega"}
          </h1>
        </div>
        <form onSubmit={handleFormSubmit}>
          <label htmlFor="type">Status:</label>
          <select
            id="type"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value="1">Ativo</option>
            <option value="0">Inativo</option>
          </select>
          {errors.status !== "" && (
            <div className="form--error">{errors.status}</div>
          )}

          <label htmlFor="description">Descrição:</label>
          <input
            type="text"
            id="description"
            value={description}
            maxLength={300}
            required={true}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
          <LengthCounter
            props={{
              field: description,
              maxLength: 300,
            }}
          />
          {errors.description !== "" && (
            <div className="form--error">{errors.description}</div>
          )}

          <label htmlFor="type">Exige Local de Entrega:</label>
          <select
            id="type"
            value={requiresDeliveryLocation}
            onChange={(e) => setRequiresDeliveryLocation(e.target.value)}
          >
            <option value={true}>Sim</option>
            <option value={false}>Não</option>
          </select>
          {errors.requiresDeliveryLocation !== "" && (
            <div className="form--error">{errors.requiresDeliveryLocation}</div>
          )}

          <button type="submit">Salvar</button>
        </form>
      </div>
    </FormArea>
  );
};

export default Edit;
